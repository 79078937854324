import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import BootrsapModal from "react-bootstrap/Modal";
import TextInput from "components/form/TextInput";
import Button from "components/form/Button";
import { icons } from "utils/constants";
import CreatePassword from "./CreatePassword";
import TwoFADetails from "./TwoFADetails";
import { addBackupCode, getProfileData } from "store/globalSlice";
import "./Security.scss";

const Security = () => {
  const dispatch = useDispatch();
  const [isOption, setIsOption] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShowSecret, setIsShowSecret] = useState(false);
  const myRef = useRef();
  const { userData } = useSelector((state) => ({
    userData: state.global.userData,
  }));
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsOption(false);
    }
  };
  const handelEdit = () => {
    setIsEdit(true);
    setIsOption(false);
  };
  const handelSuccess = async () => {
    await dispatch(getProfileData());
    setIsEdit(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const { QrCode, secret, description, status, backup_code, updatedAt } =
    userData?.TwoFactors?.[0] || {};
  return (
    <div className="d-flex flex-wrap" id="security-container">
      {isEdit && (
        <BootrsapModal
          onHide={() => {
            setIsEdit(false);
          }}
          size="lg"
          centered
          show
        >
          <BootrsapModal.Body id="modal-container">
            <div
              className="close-block pointer d-flex"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <i className="bi bi-x-lg text-20-600" />
            </div>
            <div className="text-22-600 color-outer-space mt-4">
              Two-Factor Authentication Via Mobile App
            </div>
            <div className="text-16-400 cmb-25">Edit Code And Description</div>
            <TwoFADetails
              description={description}
              handelSuccess={handelSuccess}
              handelCancel={() => {
                setIsEdit(false);
              }}
              isEdit
            />
          </BootrsapModal.Body>
        </BootrsapModal>
      )}
      <div className="cmb-25 left-security-container">
        <CreatePassword />
      </div>
      <div className="border-end cms-50 cme-50 center-security" />
      <div className="flex-grow-1">
        {backup_code ? (
          <>
            <div className="text-22-600 color-outer-space d-flex align-items-center justify-content-between cmb-20">
              <span>Two-Factor Authentication</span>
              {status ? (
                <span className="text-14-600 color-green">Enabled</span>
              ) : (
                <span className="text-14-600 color-carnation">Disabled</span>
              )}
            </div>
            <div className="d-flex gap-2 cmb-25">
              <div>
                <img src={icons.iconMobile} alt="mob" />
              </div>
              <div>
                <div className="text-14-500">
                  <i className="bi bi-check-lg color-green me-2" />
                  MOBILE APLLICATION
                </div>
                <div className="text-15-400">
                  Authenticate using any free mobile app
                </div>
              </div>
            </div>
            <div className="description-block">
              <div className="text-13-600 color-outer-space des-block">
                Description
              </div>
              <div className="d-flex align-items-center justify-content-between detail-block">
                <div>
                  <div className="text-12-500 color-outer-space">
                    Google Authenticator
                  </div>
                  <div className="text-9-500 color-outer-space">
                    {updatedAt &&
                      `Last used: ${moment(updatedAt).format("DD MMMM YYYY")}`}
                  </div>
                </div>
                <div className="d-flex option-container">
                  <i
                    className="bi bi-three-dots pointer"
                    onClick={() => {
                      setIsOption(true);
                    }}
                  />
                  {isOption && (
                    <div className="option-block shadow rounded" ref={myRef}>
                      <div
                        className="pointer border-bottom cps-20 cpe-20 cpt-5 cpb-5"
                        onClick={handelEdit}
                      >
                        Edit
                      </div>
                      <div
                        className="pointer cps-20 cpe-20 cpt-5 cpb-5"
                        onClick={async () => {
                          setIsOption(false);
                          let payload = {
                            backupCode: "",
                            description: "",
                            status: 0,
                          };
                          const response = await dispatch(
                            addBackupCode(payload)
                          );
                          if (response?.status === 200) {
                            handelSuccess(0);
                          }
                        }}
                      >
                        Delete
                      </div>
                      <div
                        className="pointer cps-20 cpe-20 cpt-5 cpb-5"
                        onClick={async () => {
                          setIsOption(false);
                          let payload = {
                            status: status === 0 ? 1 : 0,
                          };
                          const response = await dispatch(
                            addBackupCode(payload)
                          );
                          if (response?.status === 200) {
                            handelSuccess(0);
                          }
                        }}
                      >
                        {status === 0 ? "Enable" : "Disable"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-22-600 color-outer-space d-flex align-items-center justify-content-between ">
              <span>Two-Factor Authentication Via Mobile App</span>
            </div>
            <div className="text-16-400 color-outer-space cmb-20">
              Add a mobile application
            </div>
            <div className="d-flex flex-wrap gap-3 cmb-25">
              <div className="text-center qr-scan-block">
                {QrCode && (
                  <>
                    <div className="text-14-500 mb-2">Scan the QR code</div>
                    <div className="mb-2">
                      <img src={QrCode} alt="qr" style={{ height: "185px" }} />
                    </div>
                  </>
                )}
                {secret && (
                  <div className="d-flex justify-content-center">
                    <Button
                      hideAnimation
                      btnStyle="btn-primary-yellow-outline"
                      value={isShowSecret ? "Hide Secret" : "Show Secret"}
                      className="cps-10 cpe-10 cpt-5 cpb-5 h-auto text-13-600"
                      onClick={() => {
                        setIsShowSecret(!isShowSecret);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex-grow-1">
                {isShowSecret && (
                  <div className="cmb-30">
                    <TextInput
                      label="SECRET CODE"
                      placeholder=""
                      value={secret || ""}
                      disabled
                    />
                  </div>
                )}
                <TwoFADetails
                  description={description}
                  handelSuccess={handelSuccess}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Security;
