import Button from "components/form/Button";
import Layout from "pages/Layouts/Layouts";
import React from "react";
import { useParams } from "react-router-dom";
import { icons } from "utils/constants";
import "./FlashCardDetails.scss";

const FlashCardDetails = () => {
  const params = useParams();
  const { type } = params;
  const britannicaURL = {
    1: "https://www.britannica.com/science/gravity-physics",
    2: "https://www.britannica.com/place/Earth",
    3: "https://www.britannica.com/dictionary/constrained",
    4: "https://www.britannica.com/science/Newtons-laws-of-motion",
    5: "https://www.britannica.com/science/Newtons-law-of-gravitation",
    6: "https://www.britannica.com/biography/Emanuel-Swedenborg",
    7: "https://www.britannica.com/place/Sun",
    8: "https://www.britannica.com/biography/Immanuel-Kant",
    9: "https://www.ibm.com/in-en/products/watson-assistant",
    10: "https://www.ibm.com/in-en/topics/natural-language-processing",
    11: "https://www.ibm.com/in-en/topics/artificial-intelligence",
  };
  const handelRedirect = (urlType) => {
    if (britannicaURL?.[urlType]) {
      window.open(britannicaURL?.[urlType], "_blank");
    }
  };

  const flashObject = {
    management: {
      title: "Management",
      data: [
        <div className="w-100 cmb-135">
          <img src={icons.management} alt="management" className="fill-image" />
        </div>,
      ],
    },
    chemistry: {
      title: "Chemistry",
      data: [
        <div className="text-33-500 color-outer-space mb-3 mt-5">
          Periodic Table of the Elements
        </div>,
        <div className="text-28-400 mb-3">
          The periodic table (also known as the periodic table of elements) is
          organized so scientists can quickly discern the properties of
          individual elements such as their mass, electron number, electron
          configuration and their unique chemical properties. Metals reside on
          the left side of the table, while non-metals reside on the right.
          Organizing the elements to help further our understanding was first
          provided by Dmitri Mendeleev. This periodic table of the elements with
          names, atomic number, symbol and mass is color-coded for easier
          reference by students and researchers.
        </div>,
        <div className="w-100 cmb-150">
          <img src={icons.chemistry} alt="chemistry" className="fill-image" />
        </div>,
      ],
    },
    mathematics: {
      title: "Mathematics",
      data: [
        <div className="text-33-500 color-outer-space mb-3 mt-5">Algebra</div>,
        <div className="text-28-400 mb-5">
          Algebra includes both numbers and letters. Numbers are fixed, i.e.
          their value is known. Letters or alphabets are used to represent the
          unknown quantities in the algebra formula. Now, a combination of
          numbers, letters, factorials, matrices etc. is used to form an
          equation or formula. This is essentially the methodology for algebra.
        </div>,
        <div className="d-flex justify-content-center cmb-150">
          <div className="mathematics-image ">
            <img
              src={icons.mathematics}
              alt="mathematics"
              className="fill-image"
            />
          </div>
        </div>,
      ],
    },
    technology: {
      title: "Technology",
      data: [
        <div className="d-flex justify-content-end cmt-50">
          <div className="w-50 text-35-700 color-primary">
            <div>We are changing the</div>
            <div>World with Technology</div>
          </div>
          <div className="w-50">
            <img
              src={icons.technology}
              alt="technology"
              className="fill-image"
            />
          </div>
        </div>,
        <div className="text-33-500 color-outer-space cmt-60 mb-2">
          Artificial Intelligence
        </div>,
        <div className="text-28-400 mb-5">
          AI is a collection of tools and technologies which enables us design
          computers with human-like abilities of hearing, seeing, reasoning, and
          learning. Artificial intelligence (AI) is the foundation technology
          and can unleash the next wave of digital disruption for any business
          to gain cost-effectiveness and service quality. Artificial
          intelligence (AI) is the biggest pillar which can help automate and
          allowed to take necessary decisions based on strategic and prioritized
          way. The key ingredient for this solution is accessibility to right
          data.
        </div>,
        <div className="text-33-500 color-outer-space mb-2">Blockchain</div>,
        <div className="text-28-400 mb-5">
          Blockchain is a developing technology and changing the way we are
          doing business. It has ability to make the organizations that use it
          transparent, democratic, decentralized, efficient, and secure. It is
          here that In2IT Technologies’ role come into play. Our services
          portfolio facilitates organizations to design & Develop Blockchain
          Applications to cater complete application value chain.
        </div>,
        <div className="text-33-500 color-outer-space mb-2">
          Machine Learning
        </div>,
        <div className="text-28-400 mb-5">
          Machine learning is a branch of{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("11");
            }}
          >
            artificial intelligence
          </span>{" "}
          (AI) and computer science which focuses on the use of data and
          algorithms to imitate the way that humans learn, gradually improving
          its accuracy. Machine learning is an important component of the
          growing field of data science. Through the use of statistical methods,
          algorithms are trained to make classifications or predictions, and to
          uncover key insights in data mining projects.
        </div>,
        <div className="text-33-500 color-outer-space mb-2">
          Robotic Process Automation
        </div>,
        <div className="text-28-400 mb-5">
          Robotic Process Automation (RPA) is a software that mimics human
          actions performed on desktop and web application. But more than just a
          software, it’s a practice that helps organisations to improve their
          operational productivity and unlock the ROI which is higher in
          comparison to ERP and offshore implementations. Industries such as
          Banking, FinServ, Insurance, Healthcare, Manufacturing and Telecom
          have signiﬁcantly high potential for automation. The best candidates
          to be automated are generally repetitive and are time consuming, for
          example, processes like card activation and fraud claims discovery can
          be automated.
        </div>,
        <div className="text-33-500 color-outer-space mb-2">Chatbot</div>,
        <div className="text-28-400 mb-5">
          A chatbot is a computer program that uses{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("11");
            }}
          >
            artificial intelligence
          </span>{" "}
          (AI) and{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("10");
            }}
          >
            natural language processing
          </span>{" "}
          (NLP) to understand customer questions and automate responses to them,
          simulating human conversation. Chatbot technology is almost everywhere
          these days, from the smart speakers at home to messaging applications
          in the workplace. The latest AI chatbots are often referred to as{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("9");
            }}
          >
            “virtual assistants” or “virtual agents.”
          </span>{" "}
          They can use audio input, such as Apple's Siri, Google Assistant and
          Amazon Alexa, or interact with you via SMS text messaging.
        </div>,
        <div className="text-33-500 color-outer-space mb-2">
          The Internet of Things (IOT)
        </div>,
        <div className="text-28-400 mb-5">
          The internet of things, or IoT, is a system of interrelated computing
          devices, mechanical and digital machines, objects, animals or people
          that are provided with unique identifiers (UIDs) and the ability to
          transfer data over a network without requiring human-to-human or
          human-to-computer interaction.
        </div>,
        <div className="text-28-400 color-primary d-flex justify-content-end pointer text-decoration-underline cmb-150">
          Know more about these using e-books…
        </div>,
      ],
    },
    engineering: {
      title: "Engineering",
      data: [
        <div className="d-flex justify-content-center cmt-50">
          <div className="engineering-image">
            <img
              src={icons.engineering}
              alt="engineering"
              className="fill-image"
            />
          </div>
        </div>,
        <div className="text-center text-40-600 mt-5">
          <div>
            <i className="bi bi-quote" />
            What I enjoy most is watching my ideas
          </div>
          <div>
            take shape, and eventually come to life.
            <i className="bi bi-quote" />
          </div>
        </div>,
        <div className="text-33-500 cmt-50 mb-3">Mechatronics</div>,
        <div className="text-28-400 mb-3">
          Mechatronics is a multidisciplinary field that refers to the skill
          sets needed in the contemporary, advanced automated manufacturing
          industry. At the intersection of mechanics, electronics, and
          computing, mechatronics specialists create simpler, smarter systems.
          Mechatronics is an essential foundation for the expected growth in
          automation and manufacturing.
        </div>,
        <div className="text-28-400">
          Mechatronics deals with robotics, control systems, and
          electro-mechanical systems. Mechatronics is a synergistic combination
          of precision engineering, electronic control and mechanic systems. It
          is the science, that exists at the interface among the other five
          disciplines:
        </div>,
        <ul className="text-28-400 ms-5">
          <li style={{ listStyleType: "disc" }}>mechanics,</li>
          <li style={{ listStyleType: "disc" }}>electronics,</li>
          <li style={{ listStyleType: "disc" }}>informatics,</li>
          <li style={{ listStyleType: "disc" }}>automation,</li>
          <li style={{ listStyleType: "disc" }}>robotics.</li>
        </ul>,
        <div className="text-28-400 mb-3">
          It is one of the most dynamically developing fields of technology and
          science. The word 'mechatronics' appeared for the first time in Japan
          in 1969. The term mechatronics was introduced to the technical
          terminology by the Japanese company Yaskawa Elektric Corporation (a
          company founded in 1915) and since 1971 it has been protected as a
          trade name.
        </div>,
        <div className="text-28-400 mb-3">
          Mechatronics specialists work with massive industrial robots, smaller
          robots in pick-and-place operations, control systems for bottling or
          packaging of food and drink products, drones, designing control
          systems for rides in amusement parks, prototype development.
        </div>,
        <div className="text-28-400 mb-3">
          There are several different careers that a mechatronics engineer can
          pursue. The following are a few of the most common jobs that a
          mechatronics engineer may hold:
        </div>,
        <ul className="text-28-400 ms-5">
          <li style={{ listStyleType: "disc" }}>Automotive engineer</li>
          <li style={{ listStyleType: "disc" }}>Project engineer</li>
          <li style={{ listStyleType: "disc" }}>Systems engineer</li>
          <li style={{ listStyleType: "disc" }}>Software engineer</li>
          <li style={{ listStyleType: "disc" }}>Instrumentation engineer</li>
        </ul>,
        <div className="text-28-400 color-primary d-flex justify-content-end pointer text-decoration-underline cmb-150">
          Know more about these using e-books…
        </div>,
      ],
    },
    "planetary-science": {
      title: "Planetary Science",
      data: [
        <div className="d-flex justify-content-center cmt-50">
          <div className="science-image">
            <img
              src={icons.planetaryScience}
              alt="planetaryScience"
              className="fill-image"
            />
          </div>
        </div>,
        <div className="text-33-500 mb-5 mt-5">Solar System</div>,
        <div className="text-33-500 mb-3">
          Why is it Called the Solar System?
        </div>,
        <div className="text-28-400 mb-5">
          There are many planetary systems like ours in the universe, with
          planets orbiting a host star. Our planetary system is called “the
          solar system” because we use the word “solar” to describe things
          related to our star, after the Latin word for Sun, "solis.“. Located
          at the centre of the solar system and influencing the motion of all
          the other bodies through its{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("1");
            }}
          >
            gravitational
          </span>{" "}
          force is the{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("7");
            }}
          >
            Sun,
          </span>{" "}
          which in itself contains more than 99 percent of the mass of the
          system.Our planetary system is located in an outer spiral arm of the
          Milky Way galaxy. Our solar system consists of our star, the Sun, and
          everything bound to it by gravity – the planets Mercury, Venus, Earth,
          Mars, Jupiter, Saturn, Uranus, and Neptune; dwarf planets such as
          Pluto; dozens of moons; and millions of asteroids, comets, and
          meteoroids.
        </div>,
        <div className="text-33-500 mb-3">Origin of the solar system</div>,
        <div className="text-28-400 cmb-150">
          As the amount of data on the planets, moons, comets, and asteroids has
          grown, so too have the problems faced by astronomers in forming
          theories of the origin of the solar system. In the ancient world,
          theories of the origin of{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("2");
            }}
          >
            Earth
          </span>{" "}
          and the objects seen in the sky were certainly much less{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("3");
            }}
          >
            constrained
          </span>{" "}
          by fact. Indeed, a scientific approach to the origin of the solar
          system became possible only after the publication of Isaac{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("4");
            }}
          >
            {"Newton’s laws of motion"}
          </span>{" "}
          and{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("5");
            }}
          >
            gravitation
          </span>{" "}
          in 1687. Even after this breakthrough, many years elapsed while
          scientists struggled with applications of Newton’s laws to explain the
          apparent motions of planets, moons, comets, and asteroids. In 1734
          Swedish philosopher{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("6");
            }}
          >
            Emanuel Swedenborg
          </span>{" "}
          proposed a model for the solar system’s origin in which a shell of
          material around the{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("7");
            }}
          >
            Sun
          </span>{" "}
          broke into small pieces that formed the planets. This idea of the
          solar system forming out of an original nebula was extended by the
          German philosopher{" "}
          <span
            className="color-primary text-decoration-underline pointer"
            onClick={() => {
              handelRedirect("8");
            }}
          >
            Immanuel Kant
          </span>{" "}
          in 1755.
        </div>,
      ],
    },
  };

  const details = flashObject?.[type] || {};
  const { title, data } = details;

  return (
    <Layout isLogin>
      <div id="flash-card-details-container">
        {title ? (
          <>
            <div className="text-38-600 color-outer-space cmb-20 cpt-40">
              {title}
            </div>
            {data.map((elem, index) => {
              return <React.Fragment key={index}>{elem}</React.Fragment>;
            })}
          </>
        ) : (
          <div
            className="get-in-touch-block mt-5"
            style={{
              background: "#EBF2F7",
              borderRadius: "6px",
              padding: "65px 20px 56px 20px",
            }}
          >
            <div className="text-35-700 color-haiti text-center cmb-60">
              No Results Found
            </div>
            <div className="text-29-700 text-center color-outer-space cmb-18">
              GET IN TOUCH
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
              <div className="text-19-500 d-flex align-items-center">
                <span className="me-2">
                  <img src={icons.emailContact} alt="whatsapp" />
                </span>
                <span>support@helpacourse.com</span>
              </div>
              <div className="text-19-500 d-flex align-items-center">
                <span className="me-2 d-flex">
                  <img src={icons.whatsappContact} alt="whatsapp" />
                </span>
                <span>+91 123-456-7890</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <div className="w-50">
                <Button
                  btnStyle="btn-primary"
                  value="Contact Us"
                  className="h-auto pt-2 pb-2"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default FlashCardDetails;
