import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/form/TextInput";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import { forgotPassword, setIsLoginSignup } from "store/globalSlice";

const ResetPasswordPage = ({ setPageType }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(forgotPassword(values));
    if (response?.status === 200) {
      setMsg(response?.message);
      setTimeout(() => {
        dispatch(setIsLoginSignup(false));
      }, 3000);
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
  });
  const initialValues = {
    email: "",
  };
  return (
    <div className="cmt-50">
      <div className="reset-heading cmb-60">
        <h2 className="text-22-600 color-outer-space cmb-4">
          Reset your password
        </h2>
        <span className="text-14-400 color-dove-gray">
          Enter your Help a Course email address you signed up with. We'll email
          you a link to log in and reset your password.
        </span>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSave}
      >
        {(props) => {
          const { values, errors, handleChange, handleSubmit } = props;
          return (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            >
              <div className="cmb-25">
                <TextInput
                  label="EMAIL ADDRESS"
                  placeholder="Email Address"
                  id="email"
                  error={errors?.email}
                  value={values?.email}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: e.target.value.toLowerCase(),
                      },
                    });
                  }}
                />
              </div>

              <div className="cmb-10">
                <Button
                  btnStyle="btn-primary-yellow"
                  value="Submit"
                  onClick={(e) => {
                    if (!btnLoading) {
                      handleSubmit(e);
                    }
                  }}
                  btnLoading={btnLoading}
                  hideAnimation
                />
              </div>
            </form>
          );
        }}
      </Formik>
      {msg && <div className="text-success text-15-500 mb-2">{msg}</div>}
      <div className="d-flex justify-content-end text-14-600 color-primary">
        <div
          className="pointer"
          onClick={() => {
            setPageType(0);
          }}
        >
          Back
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
