import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import * as Yup from "yup";
import Button from "components/form/Button";
import PhoneNumber from "components/form/PhoneNumber";
import { objectToFormData } from "utils/helpers";
import {
  getProfileData,
  updateProfileData,
  setIsMobileNumber,
  setIsSubscription,
  getAllCountires,
} from "store/globalSlice";
import { Formik } from "formik";
import Dropdown from "components/form/Dropdown/Dropdown";

const MobileNumberPopup = () => {
  const dispatch = useDispatch();
  const { isMobileNumber, countryList } = useSelector((state) => ({
    isMobileNumber: state.global.isMobileNumber,
    countryList: state.global.countryList,
  }));
  const [loader, setLoader] = useState({
    country: true,
  });
  const [btnLoading, setBtnLoading] = useState(false);
  // const [mobile, setMobile] = useState("");
  // const [country_code, setCountryCode] = useState("IN");
  // const [err, setErr] = useState("");
  const handelSave = async (values) => {
    setBtnLoading(true);
    let payload = objectToFormData(values);
    const response = await dispatch(updateProfileData(payload));
    if (response?.status === 200) {
      dispatch(setIsMobileNumber(false));
      dispatch(setIsSubscription(true));
      await dispatch(getProfileData());
    }
    setBtnLoading(false);
  };

  // const handelSave = async () => {
  //   if (mobile) {
  //     if (!err) {
  //       setBtnLoading(true);
  //       let payload = objectToFormData({ mobile_no: mobile });
  //       const response = await dispatch(updateProfileData(payload));
  //       if (response?.status === 200) {
  //         dispatch(setIsMobileNumber(false));
  //         setMobile("");
  //         setErr("");
  //         dispatch(setIsSubscription(true));
  //         await dispatch(getProfileData());
  //       }
  //       setBtnLoading(false);
  //     }
  //   } else {
  //     setErr("Mobile is required.");
  //   }
  // };

  const validationSchema = Yup.object().shape({
    country_id: Yup.string().required("Country is required."),
    mobile_no: Yup.string().required("Mobile is required."),
  });
  const initialValues = {
    country_id: "",
    mobile_no: "",
    country_code: "IN",
  };

  const initAPI = async () => {
    await dispatch(getAllCountires());
    setLoader((prev) => {
      return { ...prev, country: false };
    });
  };
  useEffect(() => {
    initAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isMobileNumber && (
        <Modal size="md">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handelSave}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                setFieldError,
                setFieldValue,
                handleSubmit,
              } = props;
              const { mobile_no, country_id, country_code } = values;
              const { mobile_no: errMobile, country_id: errCountry } = errors;
              return (
                <form>
                  <div className="cmt-30 cmb-22">
                    <Dropdown
                      label="COUNTRY"
                      placeholder="Enter Country"
                      options={countryList}
                      value={country_id}
                      error={errCountry}
                      optionValue="country"
                      id="country_id"
                      isLoading={loader.country}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <div className="cmt-30 cmb-22">
                    <PhoneNumber
                      label="MOBILE"
                      placeholder="Enter Mobile"
                      id="mobile_no"
                      value={mobile_no}
                      error={errMobile}
                      onChange={handleChange}
                      country_code={country_code}
                      onCodeChange={(e) => {
                        handleChange(e);
                        setFieldValue("mobile_no", "");
                      }}
                      onBlur={(isError) => {
                        let newerror = isError ? "Invalid mobile number" : "";
                        setFieldError("mobile_no", newerror);
                      }}
                    />
                  </div>
                  {/* <div className="cmt-30 cmb-22">
                    <PhoneNumber
                      placeholder="Enter Mobile"
                      id="mobile_no"
                      value={mobile}
                      error={err}
                      onChange={(e) => {
                        setMobile(e?.target?.value);
                      }}
                      country_code={country_code}
                      onCodeChange={(e) => {
                        setCountryCode(e?.target.value);
                        setMobile("");
                      }}
                      onBlur={(isError) => {
                        let newerror = mobile
                          ? isError
                            ? "Invalid mobile number"
                            : ""
                          : "Mobile is required.";
                        setErr(newerror);
                      }}
                    />
                  </div> */}
                  <div>
                    <Button
                      btnStyle="btn-primary-yellow"
                      value="Submit"
                      className="cps-30 cpe-20"
                      onClick={handleSubmit}
                      btnLoading={btnLoading}
                      hideAnimation
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default MobileNumberPopup;
