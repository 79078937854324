import Button from "components/form/Button";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  cancelSubscription,
  getProfileData,
  throwSuccess,
} from "store/globalSlice";

const CancelPopup = ({ handleClose, orderID }) => {
  const dispatch = useDispatch();
  const [cancelLoader, setCancelLoader] = useState(false);
  const handelCancel = async () => {
    setCancelLoader(true);
    const response = await dispatch(cancelSubscription({ order_id: orderID }));
    if (response?.status === 200) {
      await dispatch(getProfileData());
      dispatch(
        throwSuccess(
          "Your subscription will cancel at the end of billing period."
        )
      );
      handleClose();
    }
    setCancelLoader(false);
  };
  return (
    <Modal
      show
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-18-600">Cancel Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-15-600">
        Are you sure to cancel your subscription?
      </Modal.Body>
      <Modal.Footer className="d-flex gap-2">
        <Button
          className="cps-18 cpe-18"
          btnStyle="btn-primary-yellow"
          value="Cancle"
          onClick={handleClose}
          hideAnimation
        />
        <Button
          className="cps-18 cpe-18"
          btnStyle="btn-primary-yellow"
          value="Confirm"
          onClick={handelCancel}
          btnLoading={cancelLoader}
          hideAnimation
        />
      </Modal.Footer>
    </Modal>
  );
};
export default CancelPopup;
