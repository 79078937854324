import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, omit } from "lodash";
import { icons } from "utils/constants";
import {
  searchQuestion,
  setAnswerData,
  setQuestionData,
} from "store/globalSlice";
import Loader from "../Loader";
import "./GlobalSearch.scss";

const GlobalSearch = ({ handelWidth, isFull }) => {
  const myRef = useRef();
  const myRef2 = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { questionData, answerData } = useSelector((state) => ({
    questionData: state.global.questionData,
    answerData: state.global.answerData,
  }));

  const [qData, setQData] = useState({
    question: questionData?.question || "",
    type: questionData?.type || "flashCard",
  });
  const [isLoad, setIsLoad] = useState(false);
  const [isOption, setIsOption] = useState(false);

  const handelSave = async (e) => {
    if (e?.keyCode === 13) {
      dispatch(setQuestionData(qData));
      if (qData?.question) {
        setIsLoad(true);
        let oldData = cloneDeep(answerData);
        oldData = {
          ...oldData,
          type: qData?.type,
          search: qData?.question
            .toLowerCase()
            ?.split(" ")
            .join("-")
            .replace(/_/g, "")
            .replace(/-+/g, "-")
            .replace(/-$/, ""),
          offset: 0,
          total: 0,
        };
        //search: qData?.question.toLowerCase()?.split(" ").join("-"),
        dispatch(setAnswerData(oldData));
        const response = await dispatch(
          searchQuestion(omit(oldData, ["list", "total"]))
        );
        const string = qData?.question.toLowerCase()?.split(" ").join("+");
        navigate(`/quiz-solutions?q=${string}`);
        if (response?.status === 200) {
          localStorage.headerQuestion = qData?.question;
        }
        setIsLoad(false);
      }
    }
  };
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsOption(false);
    }
    if (myRef2.current && !myRef2.current.contains(e.target) && isFull) {
      handelWidth("BLUR");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  useEffect(() => {
    setQData({
      question: questionData?.question || "",
      type: questionData?.type || "flashCard",
    });
  }, [questionData]);

  const optionList = [
    {
      id: "flashCard",
      label: "Flash Cards",
    },
    {
      id: "askQuestion",
      label: "Ask A Question",
    },
    {
      id: "expertSolution",
      label: "Expert Solutions",
    },
  ];
  const newLabel = optionList?.find((o) => o.id === qData?.type)?.label;
  return (
    <div id="global-search-container">
      <div className="global-selection-container" ref={myRef2}>
        <div
          className="global-dropdown-selection pointer"
          onClick={() => {
            setIsOption(true);
          }}
        >
          <div className="text-nowrap">{newLabel}</div>
          <div>
            <i className="bi bi-chevron-down" />
          </div>
          <div className="global-saprator" />
        </div>
        <div className="global-input-search flex-grow-1">
          <input
            id="my-search-input"
            type="text"
            placeholder="Search..."
            value={qData?.question}
            onChange={(e) => {
              setQData((prev) => {
                return { ...prev, question: e?.target?.value };
              });
            }}
            // onBlur={() => {
            //   handelWidth("BLUR");
            // }}
            // onFocus={() => {
            //   handelWidth("FOCUS");
            // }}
            onClick={() => {
              handelWidth("FOCUS");
            }}
            onKeyUp={handelSave}
          />
          {isLoad ? (
            <div className="search-i">
              <Loader size="sm" />
            </div>
          ) : (
            <img
              src={icons.searchIcon}
              alt="search"
              className="search-i pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handelSave({
                  keyCode: 13,
                });
              }}
            />
          )}
        </div>
      </div>
      {isOption && (
        <div className="option-block shadow rounded" ref={myRef}>
          {optionList.map((elm, index) => {
            return (
              <div
                key={index}
                className="pointer border-bottom cps-20 cpe-20 cpt-5 cpb-5"
                onClick={() => {
                  setQData((prev) => {
                    return { ...prev, type: elm.id };
                  });
                  setIsOption(false);
                }}
              >
                {elm.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default GlobalSearch;
