import Button from "components/form/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { setIsLoginSignup, setIsSubscription } from "store/globalSlice";
import { icons } from "utils/constants";
import "./GetMoreAnswers.scss";

const GetMoreAnswers = ({ isAuth, isShowPayment, handelNavigate }) => {
  const dispatch = useDispatch();
  return (
    <div
      id="get-more-answer-container"
      className="bg-pattens-blue bdr-radius w-100 cmb-90 slideInLeft"
    >
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="cps-35 cpt-25 cpe-25 cpb-25">
            <div className="text-45-700 cmb-15">Get more Answers for FREE</div>
            <div className="cmb-25 text-20-500 color-outer-space">
              <li>
                <img
                  src={icons.iconCorrect}
                  alt="correctIcon"
                  className="cme-25"
                />
                Millions were Answered.. Get access to all Questions.
              </li>
              <li>
                <img
                  src={icons.iconCorrect}
                  alt="correctIcon"
                  className="cme-25"
                />
                Access to Expert written solutions
              </li>
              <li>
                <img
                  src={icons.iconCorrect}
                  alt="correctIcon"
                  className="cme-25"
                />
                Access global community for more help
              </li>
              <li>
                <img
                  src={icons.iconCorrect}
                  alt="correctIcon"
                  className="cme-25"
                />
                Access to call on Experts for 360 degree course support
              </li>
            </div>
            <div className="d-flex">
              <Button
                hideAnimation
                value={
                  isAuth
                    ? isShowPayment
                      ? "SUBSCRIBE TO SEE MORE ANSWER"
                      : "SUBSCRIBE NOW"
                    : "JOIN FOR FREE"
                }
                className="btn-primary h-auto cps-25 cpt-13 cpb-13 cpe-25"
                onClick={() => {
                  if (isAuth) {
                    if (isShowPayment) {
                      handelNavigate();
                    } else {
                      dispatch(setIsSubscription(true));
                    }
                  } else {
                    dispatch(setIsLoginSignup(true));
                    localStorage.isSignup = 1;
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 mx-auto mb-lg-0 mb-4 d-flex align-items-center">
          <img src={icons.imageBooks} alt="booksImage" className="book-image" />
        </div>
      </div>
    </div>
  );
};

export default GetMoreAnswers;
