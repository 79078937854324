import CommonHelmet from "components/layouts/CommonHelmet";
import Layout from "../Layouts";

const PrivacyPolicy = () => {
  return (
    <Layout id="privacy-policy" isLogin>
      <CommonHelmet pageTitle="Privacy Policy" />
      <div className="pt-5">
        <div className="text-23-700 color-black">USER PRIVACY POLICY</div>
        <div className="text-16-500">Last Updated: March 1, 2023</div>
        <div className="text-16-400 mt-4">
          We at HelpACourse, respect your privacy and want you to understand how
          we collect, use, and share data about you. This Privacy Policy covers
          our data collection practices and describes your rights to access,
          correct, or restrict our use of your personal data.
        </div>
        <div className="text-16-400 color-black mt-4">
          By using our services, you acknowledge that you have read and
          understand this Privacy Policy, and that we collect, use, and share
          your information as described below.
        </div>
        <div className="mt-3 mb-3 text-14-600">1. What Data We Collect</div>
        <div className="text-14-400">
          We may collect different data from or about you depending on how you
          use the Services while using our HelpACourse platform. Below are some
          examples to help you better understand the data we collect.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">
            Information you provide to us directly –
          </span>
          We may collect personal data, such as your name, address, email,
          telephone number, age, payment information, any communications between
          you and HelpACourse, and any other information you provide directly to
          HelpACourse while using our Platform.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">
            Information about how you connect with our Platform –
          </span>
          We may collect information about your networks and connections such as
          your IP address, location, internet connection type, information about
          the hardware and software you use to connect to and interact with our
          Platform, and other unique identifiers, including cookie data
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">
            Information collected from third parties –
          </span>
          We may collect information about your activities outside of our
          Platform provided to us by advertisers, third-party data providers, or
          other business partners. We require that a third-party has a lawful
          right to collect, use and share your data with us. If you log in to
          your HelpACourse user account with your Google, or Facebook account or
          accounts from other third-party services, we may also receive
          information from these accounts. The information we receive depends on
          your settings and the privacy policies of such third parties.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">Tests and research –</span>
          If you decide to take part in a test or research survey with our teams
          to provide feedback and help improve our Platform, we may collect your
          email, phone number, and any contact information needed for the test
          or research survey. We may also collect the recording of those
          interactions, if applicable. You will be required to provide specific
          consent to take part in tests and research surveys.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">Shared content -</span>
          Parts of the Services let you interact with other users or share
          content publicly, including by posting reviews on a course page,
          asking or answering questions, sending messages to students or
          instructors, or posting photos or other work you upload. Such shared
          content may be publicly viewable by others depending on where it is
          posted.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">Payment data/information -</span>
          You must provide certain payment and billing data directly to our
          payment processing partners, including your name, credit card
          information, billing address, and zip/postal code. For security
          reasons, HelpACourse does not collect or store sensitive cardholder
          data, such as full credit card numbers or card authentication data.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">
            Promotions/offers/free e-book -
          </span>
          We may invite you to complete a survey or participate in a promotion,
          offers, free E-book download, free courses offer etc, either through
          our services or a third-party platform. If you participate, we will
          collect and store the data you provide as part of participating, such
          as your name, email address. That data is subject to this Privacy
          Policy unless otherwise stated in the official rules of the promotion
          or in another privacy policy. The data collected will be used to
          administer the promotion or survey.
        </div>
        <div className="text-14-400 mt-2">
          <span className="text-14-600 me-2">Contact us/communication -</span>
          If you contact us for support or to report a problem or concern
          (regardless of whether you have created an account or not purchased
          any course), we collect and store your contact information, messages,
          and other data about you like your name and email addresses for future
          correspondence.
        </div>
        <div className="mt-3 text-14-600">2. How we use your data?</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use necessary information about you when you create a user
            account. Certain features and activities on our Platform can only be
            accessed if you are a registered user. We also use your information
            to verify your identity. Please think carefully about the username
            you choose when you create your user account, because it will be
            visible to the public when you post a question or answer on our
            Platform
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use your payment-related information when you pay for a service
            or a subscription on our Platform.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use your contact information (e.g. email address) to contact you
            with important information related to the use or operation of our
            Platform, or about your user account and User Content. We also
            notify you about, among other things, new questions or answers being
            added to topics of interest to you or about your achievements, if
            you’ve given us permission to do so
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use your information to comply with tax or other legal
            obligations on our part (e.g. when you make a purchase on our
            Platform, win a prize in a contest or when necessary for legal
            reasons). We may be required to process your data for the purposes
            of complying with any such obligations.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use your information when you initiate contact with us (e.g. via
            contact forms, email) to respond to a complaint or a request to
            exercise your rights. In these cases, we use your data for the
            purpose of processing the complaint or request and, if applicable,
            for its resolution.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We examine information about the operation and use of our Platform
            to verify users and accounts, as well as enhancing security and
            combating inappropriate behavior, spam, or fraud. In doing so, we
            ensure our Platform is used in accordance with our Terms and
            Conditions and remains free of harmful and inappropriate content
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We use your information (e.g. information collected from cookies) to
            show you marketing content that may offer services and products
            provided by us and/or our business partners.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Based on analytics data about how you use our Platform (e.g. when
            you use and when you last used our Platform; which questions,
            answers, and other content you view or respond to), we are able to
            compile statistics related to your use of our Platform, assess your
            satisfaction with our services, determine the quality of our
            services, and develop and improve our Platform. We also conduct
            research and surveys, issue questionnaires, test new products and
            features, and troubleshoot relevant issues
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Based on your user account details (e.g. your age, interests,
            topics) as well as your activity and use of our Platform, we try to
            tailor our Platform to your individual needs and preferences. We
            adjust our communication style to you and provide you with
            information about our Platform’s features that may be of most
            interest to you
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We may aggregate, de-identify, or anonymize any information
            collected through our Platform and our services so that this
            information is no longer linked to your personal data (e.g. your
            name or email address). We may use aggregated and anonymized
            information for any purpose, including for research and marketing
            purposes. We may also share such information with third parties at
            our discretion.
          </li>
        </ul>
        <div className="mt-3 text-14-600">3. Whom does we share your data?</div>
        <div className="mt-1 text-14-400">
          We may share your personal data with the following parties:
        </div>
        <ul>
          <li
            style={{ listStyle: "initial" }}
            className="text-14-400 mt-2 mb-2"
          >
            Most User Content on our Platform is public, including your profile
            information and your User Content. This means that any person
            visiting our Platform may have access to information you provide
            while using our Platform.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We work with other service providers to help process personal data.
            These may include hosting service providers, IT specialists and
            technicians managing our technical infrastructure, service providers
            that ensure the functionalities of our Platform, claims adjusters
            (including insurers), advertising and marketing companies,
            accounting and bookkeeping offices, and legal advisors. All
            subcontractors process data under contracts with HelpACourse and
            only in accordance with our instructions and their legal obligation.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Based on the information contained in cookies or other online
            identifiers (if they contain personal data), advertisers,
            advertising agencies, and intermediaries globally participate in the
            process of selecting the content of targeted advertisements that are
            displayed to you on our website. We have not provided, and do not
            currently provide, personal data to third parties for monetary
            payment
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Some of our third parties may use this information to create an
            online identification code for the purpose of recognizing you on
            your devices. This code may be stored in a first-party cookie for
            use in online, in-app, and cross-channel advertising. This may be
            shared with advertising companies to enable interest-based and
            targeted advertising.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            If you purchase a subscription on our Platform, we will provide your
            payment data to our payment intermediaries.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We may share data with our subsidiaries or parent companies included
            in the HelpACourse group to assist, enhance, and fulfill services on
            our behalf. These entities may be outside of your jurisdiction. We
            may also disclose your information if we undergo organizational
            restructuring (e.g. establish a subsidiary or merge with another
            entity), or an acquisition by another entity. In such transactions
            (including in contemplation of such transactions, e.g. due
            diligence) your information may be disclosed. If any of our assets
            are sold or transferred to a third party, your information would
            likely be one of the transferred business assets.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We may disclose your information if required to do so by law, or on
            a good faith belief that such disclosure is permitted by this
            Privacy Policy or reasonably necessary or appropriate for any of the
            following reasons: (i) to comply with legal process; (ii) to enforce
            this Privacy Policy or other contracts with you, including
            investigations of potential violations thereof; (iii) to respond to
            claims that any content on our Platform violates the rights of a
            third party; (iv) to respond to your requests for customer service;
            and/or (v) to protect the rights, property, or personal safety of
            HelpACourse, our agents and associates, and the public. This
            includes exchanging information with other companies and
            organizations for fraud prevention, spam/malware prevention, and
            other similar purposes.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Upon information and consent provided by you, if applicable, we may
            share your information elsewhere that is not legally prohibited
          </li>
        </ul>
        <div className="mt-3 text-14-600">
          4. For how long does HelpACourse store your personal data?
        </div>
        <div className="mt-1 text-14-400">
          We retain your personal data for different periods of time depending
          on the purpose for which it is processed:
        </div>
        <ul>
          <li
            style={{ listStyle: "initial" }}
            className="text-14-400 mt-2 mb-2"
          >
            We store your data for as long as you have a user account on our
            Platform. If you delete your user account or if we must delete your
            account (e.g., due to your violation of the Terms and Conditions of
            our Platform or violation of the law), we will delete or anonymize
            your personal data. However, we may retain some data for longer time
            to comply with legal, tax, and accounting requirements (e.g. until
            the expiration of statutory limitation periods for contractual
            claims or limitation periods for tax liabilities arising from the
            fact that you purchased our services) or if the data is necessary
            for ongoing or anticipated legal proceedings. We make every effort
            to limit the retained data to the necessary minimum, and to retain
            it for no longer than required.
          </li>
          <li
            style={{ listStyle: "initial" }}
            className="text-14-400 mt-2 mb-2"
          >
            We do not “sell” personal data to third parties. However, when you
            use our Platform, even if you’re not a registered user, we may
            collect and allow third-party advertising networks and other third
            party businesses to collect your data through cookies or similar
            technologies, such as your IP address, the type of device you are
            using, screen resolution, operating system, geographical data, and
            which pages of the site you’ve viewed.
          </li>
        </ul>
        <div className="mt-3 text-14-600">5. What are your rights?</div>
        <div className="mt-1 text-14-400">
          In accordance with all applicable law, we enable you to exercise some
          or all the following rights regarding our collection, use, and sharing
          of your data:
        </div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Accessing the data we maintain about you
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Updating or correct any inaccurate or incomplete data about you
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Requesting that we delete your data
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Objecting to or restricting the processing of your data
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Receiving the data you’ve previously provided, in a machine-readable
            format, allowing you to transfer that data to another company at
            your discretion
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Not be subject to a decision based solely on automated processing,
            including profiling, which produces legal effects concerning you or
            similarly significantly affects you; and
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Filing a complaint directly with the relevant Authority about how we
            process your data.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            If you would like to request access to your personal data, and/or
            request erasure/deletion of data previously provided write to us
            using contact page. We will respond to your request within a
            reasonable timeframe. We also may need to verify your identity.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We may deny a request as permitted by applicable law. We will notify
            you regarding any decision and reason(s) for denial within a
            reasonable timeframe.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You can designate an authorized agent to make a request on your
            behalf. To do so, you must provide us with written authorization for
            the agent to act on your behalf. We reserve the right to take steps
            to verify that the agent is authorized to make that request, which
            may include requiring you to provide us with written proof such as a
            notarized authentication letter or a power of attorney.
          </li>
        </ul>
        <div className="mt-3 text-14-600">
          6. Our policy concerning children
        </div>
        <div className="text-14-400">
          We recognize the privacy interests of children and encourage parents
          and guardians to take an active role in their children’s online
          activities and interests. Children under 13 (or under 16 in the
          European Economic Area) should not use the Services. If we learn that
          we’ve collected personal data from a child under those ages, we will
          take reasonable steps to delete it.
        </div>
        <div className="mt-2 text-14-400">
          Parents who believe that HelpACourse may have collected personal data
          from a child under those ages can submit a request that it be removed
          to helpdesk@helpacourse.com.
        </div>
        <div className="mt-3 text-14-600">7. Updating this policy</div>
        <div className="mt-3 text-14-400">
          We may update this Privacy Policy from time to time. We will post the
          changes or information about them on our Platform, and we will notify
          you of major modifications by email or through our Platform. The date
          at the top of this Privacy Policy tells you when it was last updated.
          If we make a material change to this Privacy Policy, you will be
          provided notice in accordance with legal requirements. Any changes to
          this Privacy Policy will become effective and will be applicable to
          you 30 days after the revised Privacy Policy is posted. By continuing
          to use our Platform, you are confirming that you have read and
          understood the latest version of this Privacy Policy.
        </div>
        <div className="mt-3 text-14-600">8. Contact us</div>
        <div className="text-14-400">
          If you have any requests, questions, comments, or concerns regarding
          this Privacy Policy or privacy practices, please use contact page and
          use mail or write to us part.
        </div>
        <div className="text-center mt-3 cmb-130">***</div>
      </div>
    </Layout>
  );
};
export default PrivacyPolicy;
