import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { omit } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/layouts/Loader";
import { getOrderDetails } from "store/globalSlice";
import { objectToQueryParams, saveData } from "utils/helpers";
import "./Orders.scss";
const paymentType = {
  0: "Unpaid",
  1: "Paid",
  2: "Cancel",
};
const Orders = () => {
  const myRef = useRef();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [orderData, setOrderData] = useState({
    list: [],
    offset: 0,
    limit: 10,
    total: 0,
    loader: true,
  });

  const getOrderData = async (obj) => {
    const queryParams = `?${objectToQueryParams(
      omit(obj, ["list", "loader", "total"])
    )}`;
    const response = await dispatch(getOrderDetails(queryParams));
    setOrderData((prev) => {
      return {
        ...prev,
        list: response?.data?.rows || [],
        total: response?.data?.count,
        loader: false,
      };
    });
  };

  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  useEffect(() => {
    getOrderData(orderData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loader, list, total, limit, offset } = orderData;
  const totalPage = Math.ceil(total / limit);
  const activePage = Math.floor(offset / limit + 1);
  const newArray = [];
  Array.from({ length: totalPage }).forEach((_, i) => {
    newArray.push(i + 1);
  });
  let fPage = totalPage === activePage ? totalPage - 2 : activePage;
  let sPage = totalPage === activePage ? totalPage - 1 : activePage + 1;
  return (
    <div id="orders-container">
      <div className="order-table-container">
        <table className="order-table table cmb-25 ">
          <thead className="text-14-500 color-mine-shaft text-center">
            <tr className="text-13-600 color-outer-space">
              <th>Sr. No.</th>
              <th>Order No.</th>
              <th>Order Date</th>
              <th>Subscription Plan</th>
              <th>Subscription Amount</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          {loader ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <div className="d-flex justify-content-center cpt-70 cpb-70">
                    <Loader size="sm" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {list.length === 0 ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex justify-content-center cpt-70 cpb-70">
                      No Data Found
                    </div>
                  </td>
                </tr>
              ) : (
                list.map((elem, index) => {
                  let { id, createdAt, status, Subscription_plan, invoice } =
                    elem;
                  return (
                    <tr
                      key={index}
                      className={`${
                        status === 0
                          ? "text-13-600 color-mine-shaft bg-white text-center align-middle"
                          : "text-13-400 color-mine-shaft bg-white text-center align-middle"
                      }`}
                    >
                      <td className="td-borders">{index + 1 + offset}</td>
                      <td>{id}</td>
                      <td>{moment(createdAt).format("D MMM YYYY")}</td>
                      <td>{Subscription_plan?.category}</td>
                      <td>
                        {Subscription_plan?.country_type === "INR" ? "₹" : "$"}
                        {Subscription_plan?.amount}
                      </td>
                      <td>
                        <div
                          className={`${
                            status === 1 ? "paid-text" : "unpaid-text"
                          } `}
                        >
                          {paymentType[status]}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`cmt-10 cmb-10 pointer ${
                            status === 1 ? "download-text" : "pay-text"
                          }`}
                          onClick={() => {
                            if (status === 0) {
                              history(
                                `/subscription-payment/${id}/${Subscription_plan.id}`
                              );
                            } else {
                              saveData(invoice);
                            }
                          }}
                        >
                          {`${status === 0 ? "Pay" : "Download"} `}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </table>
        {totalPage > 1 && (
          <div className="pages-number-container d-flex justify-content-end ">
            <div aria-label="Page navigation example">
              <ul className="pagination ">
                <li className="page-item position-relative">
                  <div
                    className="page-link border-style color-outer-space text-13-400"
                    onClick={() => {
                      setIsShow(!isShow);
                    }}
                  >
                    {activePage}
                    <i className="bi bi-chevron-down cms-12 pointer"></i>
                  </div>
                  {isShow && (
                    <div className="pagination-list shadow" ref={myRef}>
                      {newArray?.map((e, index) => {
                        return (
                          <div
                            key={index}
                            className={`border-bottom ${
                              e === activePage ? "d-none" : ""
                            }`}
                            onClick={() => {
                              let count = e * limit;
                              let remove = count - limit;
                              let paginate = e === 1 ? 0 : +remove;
                              let oldData = orderData;
                              oldData = {
                                ...oldData,
                                offset: paginate,
                                loader: true,
                              };
                              setOrderData(oldData);
                              getOrderData(oldData);
                              setIsShow(false);
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </li>
                <li className="page-itemt">
                  <div className="page-link text-border-style color-outer-space text-13-400">
                    of {totalPage} results
                  </div>
                </li>
                <li
                  className={`${activePage === 1 ? "dis" : ""}`}
                  onClick={() => {
                    let oldData = orderData;
                    oldData = {
                      ...oldData,
                      offset: offset - limit <= 0 ? 0 : offset - limit,
                      loader: true,
                    };
                    setOrderData(oldData);
                    getOrderData(oldData);
                  }}
                >
                  <div className="page-link border-style color-outer-space text-13-600">
                    <i className="bi bi-chevron-left pointer"></i>
                  </div>
                </li>
                <li
                  className="page-item"
                  onClick={() => {
                    if (activePage !== fPage) {
                      let count = fPage * limit;
                      let remove = count - limit;
                      let paginate = fPage === 1 ? 0 : +remove + 1;
                      let oldData = orderData;
                      oldData = {
                        ...oldData,
                        offset: paginate,
                        loader: true,
                      };
                      setOrderData(oldData);
                      getOrderData(oldData);
                    }
                  }}
                >
                  <div className="page-link border-style color-outer-space text-13-600">
                    {fPage}
                  </div>
                </li>
                <li
                  className="page-item"
                  onClick={() => {
                    if (activePage !== sPage) {
                      let count = sPage * limit;
                      let remove = count - limit;
                      let paginate = sPage === 1 ? 0 : +remove + 1;
                      let oldData = orderData;
                      oldData = {
                        ...oldData,
                        offset: paginate,
                        loader: true,
                      };
                      setOrderData(oldData);
                      getOrderData(oldData);
                    }
                  }}
                >
                  <div className="page-link border-style color-outer-space text-13-600">
                    {sPage}
                  </div>
                </li>
                <li
                  className={`page-item ${
                    activePage === totalPage ? "dis" : ""
                  }`}
                  onClick={() => {
                    let oldData = orderData;
                    oldData = {
                      ...oldData,
                      offset: offset + limit,
                      loader: true,
                    };
                    setOrderData(oldData);
                    getOrderData(oldData);
                  }}
                >
                  <div className="page-link border-style color-outer-space text-13-600">
                    <i className="bi bi-chevron-right pointer"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
