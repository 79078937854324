import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonRoute, icons } from "utils/constants";
import "./TopSidebar.scss";
import { useState } from "react";

const TopSidebar = () => {
  const history = useNavigate();
  const params = useParams();
  const [isImgError, setIsImgError] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.global.userData,
  }));
  const optionArray = [
    {
      id: "1",
      title: "Account Overview",
      url: "account-overview",
    },
    {
      id: "1",
      title: "Orders",
      url: "orders",
    },
    {
      id: "1",
      title: "Profile Info",
      url: "profile-info",
    },
    {
      id: "1",
      title: "Security",
      url: "security",
    },
    {
      id: "1",
      title: "Billing",
      url: "billing",
    },
    {
      id: "1",
      title: "Support",
      url: "support",
    },
  ];
  const { profile_Image } = userData || {};
  let displayImage = isImgError
    ? icons.defaultAvatar
    : profile_Image || icons.defaultAvatar;
  return (
    <div id="top-sidebar-container">
      <div className="top-content-body">
        <div className="icon-container">
          <img
            src={displayImage}
            alt="iconprofile"
            className="iconProfile"
            onError={() => {
              setIsImgError(true);
            }}
          />
        </div>
        <div className="content-container">
          <div className="content-options-list">
            {optionArray.map((elm, index) => {
              return (
                <div
                  key={index}
                  className={`text-18-600 color-outer-space ps-2 pe-2 text-nowrap ${
                    elm.url === params?.type ? "active-option" : "pointer"
                  }`}
                  onClick={() => {
                    if (elm.url === "support") {
                      history(commonRoute.contactUs);
                    } else if (elm.url !== params?.type) {
                      history(`/my-profile/${elm.url}`);
                    } else {
                      // notihng
                    }
                  }}
                >
                  {elm.title}
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="left-empty-block" />
        <div className="icon-container">
          <img
            src={icons.iconProfile}
            alt="iconprofile"
            className="iconProfile"
          />
        </div>
        <div className="content-container">
          <div className="content-options-list">
            {optionArray.map((elm, index) => {
              return (
                <div
                  key={index}
                  className={`text-18-600 color-outer-space ps-2 pe-2 ${
                    elm.url === params?.type ? "active-option" : "pointer"
                  }`}
                  onClick={() => {
                    if (elm.url !== params?.type) {
                      history(`/my-profile/${elm.url}`);
                    }
                  }}
                >
                  {elm.title}
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TopSidebar;
