// globalSlice
import { createSlice } from "@reduxjs/toolkit";
import { api } from "services";
import { storeLocalStorageData } from "utils/helpers";
import { encrypt } from "utils/helpers/encryptdecrypt";
const initialState = {
  apiError: {},
  userState: null,
  userData: null,
  isSessionExpired: false,
  isLoginSignup: false,
  isSubscription: false,
  isMobileNumber: false,
  countryList: [],
  collegeList: [],
  locationList: [],
  subscriptionList: [],

  questionData: {},
  answerList: [],
  answerData: {
    list: [],
    offset: 0,
    limit: 5,
    total: 0,
    Ip: "",
    type: "flashCard",
    search: "",
    serachCount: 0,
  },
  isNoAnswer: false,
  siteData: {},
};
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUserState(state, action) {
      state.userState = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setCountryList(state, action) {
      state.countryList = action.payload;
    },
    setCollegeList(state, action) {
      state.collegeList = action.payload;
    },
    setLocationList(state, action) {
      state.locationList = action.payload;
    },
    setIsMobileNumber(state, action) {
      state.isMobileNumber = action.payload;
    },
    setApiError(state, action) {
      state.apiError = action.payload;
    },
    setIsLoginSignup(state, action) {
      state.isLoginSignup = action.payload;
    },
    setIsSubscription(state, action) {
      state.isSubscription = action.payload;
    },
    setSessionExpired(state, action) {
      state.isSessionExpired = action.payload;
    },
    setSubscriptionList(state, action) {
      state.subscriptionList = action.payload;
    },
    setQuestionData(state, action) {
      state.questionData = action.payload;
    },
    setAnswerList(state, action) {
      state.answerList = action.payload;
    },
    setAnswerData(state, action) {
      state.answerData = { ...state.answerData, ...action.payload };
    },
    setIsNoAnswer(state, action) {
      state.isNoAnswer = action.payload;
    },
    setSiteData(state, action) {
      state.siteData = action.payload;
    },
  },
});

export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/register", formData);
    const response = await dispatch(handelResponse(res));
    if (response?.status === 201) {
      storeLocalStorageData({ token: res?.token });
      dispatch(setUserState(encrypt({ token: res?.token })));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const login = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/login", formData);
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      storeLocalStorageData({ token: res?.token });
      dispatch(setUserState(encrypt({ token: res?.token })));
      if (response?.twoFactorStatus === 1) {
        localStorage.isTwoFactorLogin = 1;
      }
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handelLogout = () => async (dispatch) => {
  localStorage.clear();
  dispatch(setSessionExpired(false));
  dispatch(setUserState(new Date().toLocaleString()));
};

export const getSubscriptionPlan = () => async (dispatch) => {
  try {
    const res = await api.get("/plans/getActivePlans");
    const response = await dispatch(handelResponse(res));
    dispatch(setSubscriptionList(response?.data || []));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const getOrderDetails =
  (queryParams = "") =>
  async (dispatch) => {
    try {
      const res = await api.get(`/orders/getMyOrdersDetails${queryParams}`);
      return await dispatch(handelResponse(res));
    } catch {}
  };

export const confirmPayment = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/orders/confirmPayment", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const unsuccessfullPayment = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/orders/unsuccessfullPayment", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const addClientSceret = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/orders/addClientSceret", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const addEditSubscriptionPlan = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/orders/addOrder", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const socialLogin = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/googleLogin", formData);
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200 || response?.status === 201) {
      storeLocalStorageData({ token: res?.token });
      dispatch(setUserState(encrypt({ token: res?.token })));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const getProfileData = () => async (dispatch) => {
  try {
    const res = await api.get("/users/getProfileData");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      storeLocalStorageData(response?.data);
      dispatch(setUserData(response?.data));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const updateProfileData = (formData) => async (dispatch) => {
  try {
    const res = await api.put("/users/updateProfile", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addContact = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/contactus/addContact", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// Change Password
export const updatePassword = (formData) => async (dispatch) => {
  try {
    // console.log("formData", formData);
    const res = await api.put("/users/updatePassword", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// Location
export const getAllCountires = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllCountires");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setCountryList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllStates = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/location/getAllStates${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllCity = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/location/getAllCity${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllColleges = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllColleges");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setCollegeList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllClgLocation = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllClgLocation");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setLocationList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const searchQuestion = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/questions/getAnswersVisitors", formData);
    const response = await dispatch(handelResponse(res));
    dispatch(
      setAnswerData({
        list: response?.data?.rows || [],
        total: response?.data?.count || 0,
        type: response?.type || "flashCard",
        serachCount: response?.serachCount || 0,
      })
    );
    dispatch(setIsNoAnswer(response?.data?.rows?.length === 0 || false));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const forgotPassword = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/forgotPassword", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const resetPassword = (token, formData) => async (dispatch) => {
  try {
    const res = await api.post(`/auth/resetPassword/${token}`, formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getSiteData = () => async (dispatch) => {
  try {
    const res = await api.get("/site/getSiteData");
    const response = await dispatch(handelResponse(res));
    dispatch(setSiteData(response?.data || {}));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addBackupCode = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users/addBackupCode", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const verifyCode = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users/verifyCode", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const sendVerfication = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users/sendVerfication", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const verification = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users/verification", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const cancelSubscription = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/orders/cancelSubscription", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getSitemap = () => async (dispatch) => {
  try {
    const res = await api.get("/site/sitemap");
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const throwSuccess = (msg) => async (dispatch) => {
  let message = msg || "Operation Done Successfully.";
  dispatch(
    setApiError({
      show: true,
      message: message,
      type: "success",
    })
  );
};
export const throwError = (msg) => async (dispatch) => {
  let message = msg || "Operation Done Successfully.";
  dispatch(
    setApiError({
      show: true,
      message: message,
      type: "danger",
    })
  );
};
export const handelError = (res) => async (dispatch) => {
  let message = res?.message;
  message = message || "Something went wrong!";
  dispatch(
    setApiError({
      show: true,
      message: message,
      type: "danger",
    })
  );
};
// export const twoHandelError = (res) => async (dispatch) => {
//   let message = res?.data?.message;
//   console.log(message);
//   message = message || "Something went wrong!";
//   dispatch(
//     setApiError({
//       show: true,
//       message: message,
//       type: "danger",
//     })
//   );
// };
export const handelResponse = (res) => async (dispatch) => {
  let returnValue = null;
  const status = res?.status;
  switch (status) {
    case 200:
      returnValue = res;
      break;
    case 201:
      returnValue = res;
      break;
    case 400:
      dispatch(handelError(res));
      returnValue = null;
      break;
    default:
      handelError({ message: "Something went wrong!" });
      returnValue = null;
      break;
  }
  return returnValue;
};
export const handelCatch = (error) => async (dispatch) => {
  let status = error?.response?.status;
  let messsage = error?.response?.data?.message;
  if (status === 401 || status === 403) {
    dispatch(setSessionExpired(true));
  } else {
    messsage = messsage || "Something went wrong!";
    dispatch(
      setApiError({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  }
  return null;
};

export const {
  setUserState,
  setApiError,
  setSessionExpired,
  setUserData,
  setIsLoginSignup,
  setCountryList,
  setCollegeList,
  setLocationList,
  setIsMobileNumber,
  setIsSubscription,
  setSubscriptionList,
  setQuestionData,
  setAnswerList,
  setIsNoAnswer,
  setSiteData,
  setAnswerData,
} = globalSlice.actions;
export default globalSlice.reducer;
