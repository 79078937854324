import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, omit } from "lodash";
import { icons } from "utils/constants";
import { searchQuestion, setAnswerData } from "store/globalSlice";
import SocialMediaSection from "./SocialMediaSection";
import Answer from "./Answer";
import "./QuestionAnswer.scss";
import { getDataFromLocalStorage } from "utils/helpers";

const QuestionAnswer = () => {
  const dispatch = useDispatch();
  const queAnsRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const { userState, answerData } = useSelector((state) => ({
    userState: state.global.userState,
    answerData: state.global.answerData,
  }));
  const { list, total, limit, offset, type } = answerData;
  const quetionType = type || "flashCard";
  const handelPagination = async (offset) => {
    let oldData = cloneDeep(answerData);
    oldData = {
      ...oldData,
      offset: offset,
    };
    dispatch(setAnswerData(oldData));
    await dispatch(searchQuestion(omit(oldData, ["list", "total"])));
  };
  // const access = {
  //   isSocialMedia: false,
  //   isHide: false,
  // };
  const displayList = cloneDeep(list);
  const totalPage = Math.ceil(total / limit);
  const activePage = Math.floor(offset / limit + 1);
  let page1 = 1;
  let page2 = 2;
  let page3 = 3;
  let page4 = 4;
  if (totalPage > 4) {
    page1 = totalPage === activePage ? totalPage - 4 : activePage;
    page2 = totalPage === activePage ? totalPage - 3 : activePage + 1;
    page3 = totalPage === activePage ? totalPage - 2 : activePage + 2;
    page4 = totalPage === activePage ? totalPage - 1 : activePage + 3;
    if (page4 >= totalPage || totalPage === activePage) {
      page1 = totalPage - 3;
      page2 = totalPage - 2;
      page3 = totalPage - 1;
      page4 = totalPage;
    }
  }

  let pageArray = [];
  if (totalPage > 1) {
    pageArray.push(page1);
    pageArray.push(page2);
  }
  if (totalPage > 2) {
    pageArray.push(page3);
  }
  if (totalPage > 3) {
    pageArray.push(page4);
  }
  const { Orders } = getDataFromLocalStorage() || {};
  const plan = Orders?.[0] || {};
  const { status } = plan;
  const isAnySub = status === 1;
  useEffect(() => {
    queAnsRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [displayList]);
  return (
    <>
      <div id="question_answer-container" ref={queAnsRef}>
        <div className="row cmb-65">
          <div className="col-md-12">
            {displayList?.map((elem, index) => {
              const {
                question,
                title,
                option1,
                option2,
                option3,
                option4,
                answer,
                description,
                externalFile,
                displayStatus,
              } = elem;
              let newAnswer = answer || description;
              if (quetionType === "askQuestion") {
                let opt = "";
                if (option1 === answer) {
                  opt = "a";
                } else if (option1 === answer) {
                  opt = "b";
                } else if (option1 === answer) {
                  opt = "c";
                } else {
                  opt = "d";
                }
                newAnswer = `${opt}. ${newAnswer}`;
              }
              return (
                <React.Fragment>
                  <div
                    className="qustion-answer-content-block bg-black-squeeze bdr-radius cpt-55 cps-55 cpb-55 cpe-55 cmb-30"
                    key={index}
                  >
                    <div className="question-block">
                      <div className="text-size-block text-32-400 color-haiti cmb-40">
                        {question || title}
                      </div>

                      {quetionType === "askQuestion" && (
                        <>
                          <div className="text-size-block text-32-400 color-haiti ">
                            Options:
                          </div>
                          <div className="text-size-block text-32-400 color-haiti">
                            <ol className="cps-94">
                              <li style={{ listStyleType: "lower-alpha" }}>
                                {option1}
                              </li>
                              <li style={{ listStyleType: "lower-alpha" }}>
                                {option2}
                              </li>
                              {option3 && (
                                <li style={{ listStyleType: "lower-alpha" }}>
                                  {option3}
                                </li>
                              )}
                              {option4 && (
                                <li style={{ listStyleType: "lower-alpha" }}>
                                  {option4}
                                </li>
                              )}
                            </ol>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="answer-block d-flex cmb-14">
                      <div className="text-size-block text-32-600 cme-17">
                        Answer
                      </div>
                      <div className="text-size-block text-verified text-17-600 color-chateau-green cps-9 cpe-10 d-flex align-items-center">
                        <img
                          src={icons.iconVerified}
                          alt="iconVerified"
                          className="cme-8"
                        />
                        Verified
                      </div>
                    </div>
                    {displayStatus || isAnySub ? (
                      <Answer
                        answer={newAnswer}
                        quetionType={quetionType}
                        externalFile={externalFile}
                        isHide={false}
                      />
                    ) : (
                      <SocialMediaSection />
                    )}
                  </div>
                </React.Fragment>
              );
            })}
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center gap-3">
                {activePage !== 1 && (
                  <div
                    className="q-pagination"
                    onClick={() => {
                      handelPagination(
                        offset - limit <= 0 ? 0 : offset - limit
                      );
                    }}
                  >
                    <i className="bi bi-caret-left-fill" />
                  </div>
                )}
                {pageArray.length > 0 &&
                  pageArray?.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className={`q-pagination ${
                          activePage === e ? "q-pagination-active" : ""
                        }`}
                        onClick={() => {
                          if (activePage !== e) {
                            let count = e * limit;
                            let remove = count - limit;
                            let paginate = e === 1 ? 0 : +remove + 1;
                            handelPagination(paginate);
                          }
                        }}
                      >
                        {e}
                      </div>
                    );
                  })}
                {totalPage !== activePage && (
                  <div
                    className="q-pagination"
                    onClick={() => {
                      handelPagination(offset + limit);
                    }}
                  >
                    <i className="bi bi-caret-right-fill" />
                  </div>
                )}
              </div>
            )}
          </div>
          {/* {access?.isSocialMedia && <SocialMediaSection />} */}
        </div>
      </div>
    </>
  );
};

export default QuestionAnswer;
