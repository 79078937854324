import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const CommonHelmet = ({ pageTitle }) => {
  const { siteData } = useSelector((state) => ({
    siteData: state.global.siteData,
  }));
  const { fav_icon, home_page_meta_title, meta_description, meta_keywords } =
    siteData || {};
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" href={fav_icon || ""} id="app-favicon" />
      <title>{pageTitle || home_page_meta_title || ""}</title>
      <meta name="keywords" content={meta_keywords} />
      <meta name="description" content={meta_description} />
    </Helmet>
  );
};
export default CommonHelmet;
