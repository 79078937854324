import Button from "components/form/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { commonRoute } from "utils/constants";
import "./NoResultFound.scss";

const NoResultFound = () => {
  const history = useNavigate();
  return (
    <div id="no-result-container" className="slideInLeft">
      <div className="row cmb-100">
        <div className="col-md-12">
          <div className="bg-black-squeeze h-100 bdr-radius cpt-64 cpb-56">
            <div className="no-result-footer-block">
              <div className="text-35-600 color-haiti cmb-60 d-flex justify-content-center">
                No Results Found
              </div>
              <div className="text-29-700 color-outer-space cmb-18 d-flex justify-content-center">
                OOPS! Something gone wrong...
              </div>
              <div className="get-in-touch-block">
                <div className="social-content gap-3 cmb-40">
                  No Results.. No Worry... HelpACourse expert has an answer..
                </div>
                <div className="buttton-contact-block">
                  <Button
                    value="Contact Us"
                    className="btn-primary cpt-13 cpb-13 bdr text-nowrap"
                    onClick={() => {
                      history(commonRoute.contactUs);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResultFound;
