import Modal from "components/layouts/Modal";
import * as Yup from "yup";
import { Formik } from "formik";
import { omit } from "lodash";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import { updatePassword } from "store/globalSlice";
import { useState } from "react";
import PasswordInput from "components/form/PasswordInput";
import { passwordError } from "utils/constants";

const CreatePassword = ({ handelSuccess }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(
      updatePassword(omit(values, ["confirm_password"]))
    );
    if (response?.status === 200) {
      handelSuccess();
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        passwordError
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .oneOf([Yup.ref("newPassword"), null], "Password not matched."),
  });
  const initialValues = {
    newPassword: "",
    confirm_password: "",
  };
  return (
    <Modal title="Create Password" size="md">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSave}
      >
        {(props) => {
          const { values, errors, handleChange, handleSubmit } = props;
          return (
            <form className="mt-3 cps-20 cpe-20">
              <div className="cmb-25">
                <PasswordInput
                  showInfo
                  label="Password"
                  placeholder="Enter Password"
                  id="newPassword"
                  value={values.newPassword}
                  error={errors.newPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="cmb-25">
                <PasswordInput
                  label="Confirm Password"
                  placeholder="Enter Confirm Password"
                  id="confirm_password"
                  value={values.confirm_password}
                  error={errors.confirm_password}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  hideAnimation
                  value="Change"
                  onClick={handleSubmit}
                  btnLoading={btnLoading}
                  className="cps-30 cpe-30"
                  btnStyle="btn-primary-yellow"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default CreatePassword;
