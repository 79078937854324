import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isEqual, map, unionBy, cloneDeep, lowerCase, omit } from "lodash";
import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import Dropdown from "components/form/Dropdown";
import PhoneNumber from "components/form/PhoneNumber";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import { objectToFormData, titleCaseString } from "utils/helpers";
import CreatePassword from "./CreatePassword";
import CreatableDropdown from "components/form/CreatableDropdown";
import ImageCropper from "components/layouts/ImageCropper";
import {
  getAllColleges,
  getAllCountires,
  getAllClgLocation,
  getAllStates,
  getAllCity,
  updateProfileData,
  getProfileData,
  throwSuccess,
  throwError,
} from "store/globalSlice";
import "./ProfileInfo.scss";

const ProfileInfo = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { userData, countryList, collegeList, locationList } = useSelector(
    (state) => ({
      userData: state.global.userData,
      countryList: state.global.countryList,
      collegeList: state.global.collegeList,
      locationList: state.global.locationList,
    })
  );
  const [tempData, setTempData] = useState(null);
  const [tempList, setTempList] = useState({
    collegeList: [],
    locationList: [],
  });
  const [timer, setTimer] = useState("");
  const [cropFile, setCropFile] = useState(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityData, setCityData] = useState({
    list: [],
    search: "",
    total: 0,
    offset: 0,
    limit: 100,
    state_id: "",
    isLoading: false,
  });
  const [loader, setLoader] = useState({
    college: true,
    location: true,
    country: true,
    state: false,
    city: false,
  });
  const {
    user_type,
    first_name,
    last_name,
    mobile_no,
    email,
    DOB,
    mailing_address,
    country_id,
    state_id,
    city_id,
    zip,
    basic_education,
    college_location,
    college,
    city,
    country_code,
    is_email_verify,
    is_mobile_verify,
  } = userData || {};
  const handelSave = async (values) => {
    setBtnLoading(true);
    let payload = objectToFormData(values);
    const response = await dispatch(updateProfileData(payload));
    if (response?.status === 200) {
      dispatch(throwSuccess("Profile Updated Successfully."));
      if (formRef.current) {
        formRef.current.resetForm();
      }
      await dispatch(getProfileData());
    }
    setBtnLoading(false);
  };
  const fetchStateFromCountry = async (countryID) => {
    setLoader((prev) => {
      return { ...prev, state: true };
    });
    const response = await dispatch(getAllStates(`?country_id=${countryID}`));
    setStateList(response?.data || []);
    setLoader((prev) => {
      return { ...prev, state: false };
    });
  };
  const handleSearchCity = (e) => {
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let oldData = cloneDeep({
        ...cityData,
        offset: 0,
        search: lowerCase(e),
        isLoading: true,
        list: [],
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }, 800);
    setTimer(time);
  };
  const handelScrollCity = () => {
    if (cityData.list.length < cityData.total) {
      let oldData = cloneDeep({
        ...cityData,
        offset: cityData.offset + cityData?.limit,
        isLoading: true,
      });
      setCityData(oldData);
      fetchCityFromState(oldData);
    }
  };
  const fetchCityFromState = async (obj, isReset) => {
    const queryString = new URLSearchParams(
      omit({ ...obj }, ["list", "total", "isLoading"])
    ).toString();
    const response = await dispatch(getAllCity(`?${queryString}`));
    setCityData((prev) => {
      let resData = response?.data?.rows || [];
      let listData = isReset ? resData : [...prev.list, ...resData];
      let existingList = city?.id ? [city] : [];
      return {
        ...prev,
        list: unionBy(listData, existingList, "id"),
        total: response?.data?.count || 0,
        isLoading: false,
      };
    });
  };
  const initAPI = async () => {
    await dispatch(getAllColleges());
    await dispatch(getAllClgLocation());
    await dispatch(getAllCountires());
    setLoader((prev) => {
      return { ...prev, college: false, location: false, country: false };
    });
  };
  useEffect(() => {
    if (state_id) {
      let oldData = cloneDeep({
        ...cityData,
        state_id: state_id,
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id]);

  useEffect(() => {
    if (country_id) {
      fetchStateFromCountry(country_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country_id]);

  useEffect(() => {
    initAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    last_name: Yup.string()
      .required("Last name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    mobile_no: Yup.string().required("Mobile is required."),
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    DOB: Yup.string().test(
      "DOB",
      "You must be atleast 18 years of age!",
      (value) => {
        if (value) {
          return moment().diff(moment(value), "years") >= 18;
        } else {
          return Yup.string();
        }
      }
    ),
  });

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    mobile_no: mobile_no || "",
    email: email || "",
    DOB: DOB || "",
    college_name: college?.college_name || "",
    college_location: college_location?.college_location || "",
    mailing_address: mailing_address || "",
    country_id: country_id || "",
    state_id: state_id || "",
    city_id: city_id || "",
    zip: zip || "",
    basic_education: basic_education || "",
    profile_Image: "",
    country_code: country_code || "IN",
  };

  return (
    <div id="profile-info-container">
      {isChangePassword && (
        <CreatePassword
          handelSuccess={() => {
            setIsChangePassword(false);
            handelSave(tempData);
          }}
        />
      )}
      <div className="profile-info-center-container row">
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(e) => {
            if (isEqual(email, e?.email)) {
              handelSave(e);
            } else {
              if (user_type === 0) {
                handelSave(e);
              } else {
                setTempData({ ...e, user_type: 0, is_email_verify: 0 });
                setIsChangePassword(true);
              }
            }
          }}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldError,
              setFieldValue,
            } = props;
            return (
              <form>
                {cropFile && (
                  <ImageCropper
                    onHide={() => {
                      setCropFile(null);
                    }}
                    fileURL={cropFile}
                    handelCropImage={(file) => {
                      setFieldValue("profile_Image", file || "");
                    }}
                  />
                )}
                <div className="file-upload-block cmb-25">
                  <span className="file-upload-input">
                    <div className="choose_file">
                      <span className="btn-block pointer text-14-600 bg-outer-space color-white text-nowrap">
                        Upload Photo
                      </span>
                      <input
                        name="Select File"
                        type="file"
                        onChange={(e) => {
                          var files = e?.target?.files;
                          var extension = files[0]?.type;

                          if (
                            ["image/png", "image/jpg", "image/jpeg"].includes(
                              extension
                            )
                          ) {
                            if (e?.target?.files[0]) {
                              setCropFile(
                                URL.createObjectURL(e?.target?.files[0])
                              );
                            }
                          } else {
                            dispatch(
                              throwError(
                                "Invalid file select. (PNG || JPG || JPEG)"
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-6 cmb-25">
                    <TextInput
                      required
                      label="FIRST NAME"
                      placeholder="First name"
                      id="first_name"
                      value={values.first_name}
                      error={errors.first_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 cmb-25">
                    <TextInput
                      required
                      label="LAST NAME"
                      placeholder="Last name"
                      id="last_name"
                      value={values.last_name}
                      error={errors.last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 cmb-25">
                    <PhoneNumber
                      required
                      label="MOBILE NUMBER"
                      placeholder="Mobile number"
                      id="mobile_no"
                      value={values?.mobile_no}
                      error={errors?.mobile_no}
                      country_code={values?.country_code}
                      onCodeChange={(e) => {
                        handleChange(e);
                        setFieldValue("mobile_no", "");
                      }}
                      onChange={handleChange}
                      onBlur={(isError) => {
                        let newerror = isError ? "Invalid mobile number" : "";
                        setFieldError("mobile_no", newerror);
                      }}
                      isVerify={is_mobile_verify === 1}
                    />
                  </div>
                  <div className="col-md-4 cmb-25">
                    <TextInput
                      required
                      label="EMAIl"
                      placeholder="user123@gmail.com"
                      id="email"
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                      isVerify={is_email_verify === 1}
                    />
                  </div>
                  <div className="col-md-4 cmb-25">
                    <DatePicker
                      label="DATE OF BIRTH"
                      placeholder="DOB"
                      id="DOB"
                      value={values.DOB}
                      error={errors.DOB}
                      onChange={handleChange}
                      maxDate={moment()}
                    />
                  </div>
                  <div className="col-md-8 cmb-25">
                    <CreatableDropdown
                      label="COLLEGE NAME"
                      placeholder="College name"
                      options={unionBy(
                        map(collegeList, (e) => {
                          return { ...e, label: e.college_name };
                        }),
                        tempList.collegeList,
                        "label"
                      )}
                      // optionValue="college_name"
                      optionKey="college_name"
                      id="college_name"
                      value={values.college_name || null}
                      onChange={handleChange}
                      isLoading={loader.college}
                      onCreateOption={(e) => {
                        let val = titleCaseString(e);
                        setTempList((prev) => {
                          let oldList = prev.collegeList;
                          oldList.push({ label: val, college_name: val });
                          return { ...prev, collegeList: oldList };
                        });
                        setFieldValue("college_name", val);
                      }}
                    />
                  </div>
                  <div className="col-md-4 cmb-25">
                    <CreatableDropdown
                      label="COLLEGE LOCATION"
                      placeholder="College location"
                      options={unionBy(
                        map(locationList, (e) => {
                          return { ...e, label: e.college_location };
                        }),
                        tempList.locationList,
                        "label"
                      )}
                      // optionValue="college_location"
                      optionKey="college_location"
                      id="college_location"
                      value={values.college_location}
                      onChange={handleChange}
                      isLoading={loader.location}
                      onCreateOption={(e) => {
                        let val = titleCaseString(e);
                        setTempList((prev) => {
                          let oldList = prev.locationList;
                          oldList.push({ label: val, college_location: val });
                          return { ...prev, locationList: oldList };
                        });
                        setFieldValue("college_location", val);
                      }}
                    />
                  </div>
                  <div className="col-md-12 cmb-25">
                    <TextArea
                      label="BILLING ADDRESS"
                      placeholder="Billing address"
                      id="mailing_address"
                      value={values.mailing_address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 cmb-25">
                    <Dropdown
                      label="COUNTRY"
                      placeholder="Select country"
                      options={countryList}
                      optionValue="country"
                      id="country_id"
                      value={values.country_id}
                      isLoading={loader.country}
                      onChange={(e) => {
                        setFieldValue("state_id", "");
                        setFieldValue("city_id", "");
                        setCityData((prev) => {
                          return {
                            ...prev,
                            list: [],
                          };
                        });
                        if (e?.target?.value) {
                          fetchStateFromCountry(e?.target?.value);
                        }
                        handleChange(e);
                      }}
                      disabled
                    />
                  </div>
                  <div className="col-md-6 cmb-25">
                    <Dropdown
                      label="STATE"
                      placeholder="Select state"
                      options={stateList}
                      id="state_id"
                      value={values.state_id}
                      optionValue="state"
                      isLoading={loader.state}
                      onChange={(e) => {
                        if (e?.target?.value) {
                          setFieldValue("city_id", "");

                          let oldData = cloneDeep({
                            ...cityData,
                            list: [],
                            search: "",
                            isLoading: true,
                            state_id: e?.target?.value,
                          });
                          setCityData(oldData);
                          fetchCityFromState(oldData, true);
                        }
                        handleChange(e);
                      }}
                      disabled={!values.country_id}
                    />
                  </div>
                  <div className="col-md-6 cmb-25">
                    <Dropdown
                      label="CITY"
                      placeholder="Select city"
                      options={cityData?.list}
                      optionValue="city"
                      id="city_id"
                      value={values.city_id}
                      onChange={handleChange}
                      isLoading={cityData.isLoading}
                      disabled={!values.state_id}
                      onInputChange={handleSearchCity}
                      onMenuScrollToBottom={handelScrollCity}
                    />
                  </div>

                  <div className="col-md-6 cmb-25">
                    <TextInput
                      label="ZIP"
                      placeholder="Zip code"
                      id="zip"
                      value={values.zip}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-12 cmb-25">
                    <TextArea
                      label="BASIC EDUCATION & AREAS OF INTEREST"
                      placeholder="Enter your basic education & areas of interest separated by commas"
                      id="basic_education"
                      value={values.basic_education}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="button-container d-flex">
                    <Button
                      hideAnimation
                      value="Upload Profile"
                      btnStyle="btn-primary-yellow"
                      className="text-14-600 cpt-13 cpb-13 cps-25 cpe-25"
                      onClick={handleSubmit}
                      btnLoading={btnLoading}
                      disabled={isEqual(values, initialValues)}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileInfo;
