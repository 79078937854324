import React from "react";
import { useParams } from "react-router-dom";
import Layout from "pages/Layouts";
import Sidebar from "./Sidebar";
import AccountOverview from "./AccountOverview/AccountOverview";
import ProfileInfo from "./ProfileInfo";
import Billing from "./Billing";
import Security from "./Security";
import Orders from "./Orders";
import "./Profile.scss";
import TopSidebar from "./TopSidebar";

const Dashboard = () => {
  const params = useParams();
  const componentObject = {
    "account-overview": <AccountOverview />,
    "profile-info": <ProfileInfo />,
    billing: <Billing />,
    security: <Security />,
    orders: <Orders />,
    support: <div className="container">No Data Found</div>,
  };

  return (
    <Layout hideContainer>
      <div id="profile-main-container">
        <TopSidebar />
        <Sidebar />
        <div className="right-content">{componentObject?.[params.type]}</div>
      </div>
    </Layout>
  );
};

export default Dashboard;
