import { useState } from "react";
import { orderBy } from "lodash";
import BootrsapModal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/form/Button";
import { commonRoute, icons } from "utils/constants";
import {
  addEditSubscriptionPlan,
  getProfileData,
  setIsSubscription,
} from "store/globalSlice";
import "./SubscriptionPopup.scss";
import { getDataFromLocalStorage } from "utils/helpers";

const SubscriptionPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscriptionList, userData } = useSelector((state) => ({
    subscriptionList: state.global.subscriptionList,
    userData: state.global.userData,
  }));
  // console.log("subscriptionList", subscriptionList);
  // console.log("userData", userData);

  const [btnLoading, setBtnLoading] = useState(null);
  const handelSelectPlan = async (plan) => {
    setBtnLoading(plan.id);
    const response = await dispatch(
      addEditSubscriptionPlan({
        subscriptionPlan_id: plan.id,
        subscription_amount: plan.amount,
      })
    );
    if (response?.status === 201) {
      dispatch(setIsSubscription(false));
      navigate(`/subscription-payment/${response?.data?.id}/${plan.id}`);
      await dispatch(getProfileData());
    }
    setBtnLoading(null);
  };
  const isCountryId = userData?.country_id === 101;
  const filterPlanList = isCountryId
    ? subscriptionList?.filter((o) => o.country_type === "INR")
    : subscriptionList?.filter((o) => o.country_type === "usd");
  // console.log(filterPlanList);
  const displayList = orderBy(filterPlanList, "category");
  const { Orders } = getDataFromLocalStorage() || {};
  const plan = Orders?.[0] || {};
  const { subscriptionPlan_id, status } = plan;
  return (
    <BootrsapModal
      onHide={() => {
        dispatch(setIsSubscription(false));
      }}
      fullscreen
      centered
      show
    >
      <BootrsapModal.Body
        id="subscription-popup"
        className="pt-5 position-relative"
      >
        <div className="header-sub-block">
          {subscriptionPlan_id ? "Change" : "Choose"} Your Subscription
        </div>

        <div
          className="close-block subscription-close pointer d-flex h-auto"
          onClick={() => {
            dispatch(setIsSubscription(false));
          }}
        >
          <i
            className="bi bi-x-circle-fill text-28-400 text-danger d-flex"
            onClick={() => {
              dispatch(setIsSubscription(false));
            }}
          />
        </div>

        <div className="center-container slideInUp">
          <div className="plan-list-container">
            {displayList.map((elem, index) => {
              let { id, category, description, amount } = elem;
              let isSelected =
                subscriptionPlan_id === id && [0, 1]?.includes(status);
              const bgColor = index % 2 === 0 ? "#2176FF" : "#FDCA40";
              const color = index % 2 === 0 ? "#FFFFFF" : "#31393C";
              const btnStyle =
                index % 2 === 0 ? "btn-primary" : "btn-primary-yellow";
              const icon =
                category === "Yearly"
                  ? icons.yearlySubscription
                  : category === "Monthly"
                  ? icons.monthlySubscription
                  : icons.quaterlySubscription;
              return (
                <div className="plan-block" key={index}>
                  <div
                    className={`text-center cpt-14 cpb-14 head-block ${
                      isSelected ? "opacity-50" : ""
                    }`}
                    style={{
                      backgroundColor: bgColor,
                      color: color,
                    }}
                  >
                    {category}
                  </div>
                  <div className="plan-bottom">
                    <div className="center-icon-block">
                      <img
                        src={icons.subscriptionMasGroup}
                        className="plan-icon-bg-block"
                        alt="mask"
                      />
                      <img
                        src={icon}
                        className="plan-icon-block"
                        alt="monthly"
                      />
                    </div>
                    <div
                      className="desc-block text-center cps-18 cpe-12 cmt-50"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    <div className="mt-auto">
                      <div className="text-center cpt-10 mt-3">
                        <span className="text-55-700 amt-block">
                          {isCountryId ? `₹${amount}` : `$${amount}`}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="cps-30 cpe-30"
                          value="Subscribe Now"
                          btnStyle={btnStyle}
                          btnLoading={elem.id === btnLoading}
                          disabled={isSelected}
                          hideAnimation={isSelected}
                          onClick={() => {
                            if (!btnLoading) {
                              handelSelectPlan(elem);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {!subscriptionPlan_id && (
            <div
              className="skip-block pointer button-animation"
              onClick={() => {
                dispatch(setIsSubscription(false));
                navigate(commonRoute.myProfile);
              }}
            >
              I’ll Subscribe later
            </div>
          )}
        </div>

        <div className="color-dove-gray position-absolute bottom-0 end-0">
          *Prices inclusive of taxes as applicable
        </div>
      </BootrsapModal.Body>
    </BootrsapModal>
  );
};
export default SubscriptionPopup;
