import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import TextInput from "../TextInput/TextInput";
// import PhoneNumber from "../PhoneNumber/PhoneNumber";
import * as Yup from "yup";
import { Formik } from "formik";
import { unionBy, cloneDeep, lowerCase, omit } from "lodash";
import { getAllCountires, getAllStates, getAllCity } from "store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const BillingInformation = ({ getBillingDetails, billingDetails }) => {
  const dispatch = useDispatch();

  const { userData, countryList } = useSelector((state) => ({
    userData: state.global.userData,
    countryList: state.global.countryList,
  }));

  const [cityData, setCityData] = useState({
    list: [],
    search: "",
    total: 0,
    offset: 0,
    limit: 100,
    state_id: "",
    isLoading: false,
  });
  const [stateList, setStateList] = useState([]);
  const [timer, setTimer] = useState("");
  const [loader, setLoader] = useState({
    country: true,
    state: false,
    city: false,
  });

  const { country_id, state_id, city } = userData || {};

  const initialValues = billingDetails;
  const validationSchema = Yup.object().shape({
    // email: Yup.string()
    //   .required("Email is required.")
    //   .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    // mobile_no: Yup.string().required("Mobile is required."),
    state_id: Yup.string().required("State is required."),
    city_id: Yup.string().required("City is required."),
    zip: Yup.string().required("Pincode is required."),
    mailing_address: Yup.string().required("Address is required."),
  });

  // const handelBlur = () => {
  //   // let newBillingData = billingDetails;
  //   // const { email } = newBillingData;
  // };

  const fetchStateFromCountry = async (countryID) => {
    setLoader((prev) => {
      return { ...prev, state: true };
    });
    const response = await dispatch(getAllStates(`?country_id=${countryID}`));
    setStateList(response?.data || []);
    setLoader((prev) => {
      return { ...prev, state: false };
    });
  };
  const handleSearchCity = (e) => {
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let oldData = cloneDeep({
        ...cityData,
        offset: 0,
        search: lowerCase(e),
        isLoading: true,
        list: [],
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }, 800);
    setTimer(time);
  };
  const handelScrollCity = () => {
    if (cityData.list.length < cityData.total) {
      let oldData = cloneDeep({
        ...cityData,
        offset: cityData.offset + cityData?.limit,
        isLoading: true,
      });
      setCityData(oldData);
      fetchCityFromState(oldData);
    }
  };
  const fetchCityFromState = async (obj, isReset) => {
    const queryString = new URLSearchParams(
      omit({ ...obj }, ["list", "total", "isLoading"])
    ).toString();
    const response = await dispatch(getAllCity(`?${queryString}`));
    setCityData((prev) => {
      let resData = response?.data?.rows || [];
      let listData = isReset ? resData : [...prev.list, ...resData];
      let existingList = city?.id ? [city] : [];
      return {
        ...prev,
        list: unionBy(listData, existingList, "id"),
        total: response?.data?.count || 0,
        isLoading: false,
      };
    });
  };
  const initAPI = async () => {
    await dispatch(getAllCountires());
    setLoader((prev) => {
      return { ...prev, country: false };
    });
  };
  useEffect(() => {
    if (state_id) {
      let oldData = cloneDeep({
        ...cityData,
        state_id: state_id,
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id]);

  useEffect(() => {
    if (country_id) {
      fetchStateFromCountry(country_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country_id]);

  useEffect(() => {
    initAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // let error = billingDetails?.isMobError ? "Invalid mobile number" : "";
  return (
    <div id="billing-information-container">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, errors, handleChange, setFieldValue } = props;
          return (
            <form className="row">
              {/* <div className="cmb-20">
                <TextInput
                  label="EMAIL"
                  placeholder="Email"
                  id="email"
                  value={values?.email}
                  error={errors.email}
                  onChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </div> */}
              {/* <div className="cmb-20">
                <PhoneNumber
                  label="MOBILE NUMBER"
                  placeholder="Mobile number"
                  id="mobile_no"
                  value={values?.mobile_no}
                  error={error}
                  country_code={values?.country_code}
                  onCodeChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        country_code: e.target.value,
                      };
                    });

                    setFieldValue("mobile_no", "");
                  }}
                  onChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        mobile_no: e.target.value,
                      };
                    });
                  }}
                  onBlur={(isError) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        isMobError: isError,
                      };
                    });
                    // let newerror = isError ? "Invalid mobile number" : "";
                    // setFieldError("mobile_no", newerror);
                  }}
                />
              </div> */}
              <div className="cmb-20 col-md-6">
                <Dropdown
                  isRequired
                  label="COUNTRY"
                  placeholder="Select country"
                  options={countryList}
                  optionValue="country"
                  id="country_id"
                  value={values?.country_id}
                  isLoading={loader.country}
                  onChange={(e) => {
                    let countryVal = e.target.value;
                    let codeVal = e.target.data.code;
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        country_id: countryVal,
                        code: codeVal,
                      };
                    });
                    setFieldValue("state_id", "");
                    setFieldValue("city_id", "");
                    if (e?.target?.value) {
                      fetchStateFromCountry(e?.target?.value);
                    }
                    handleChange(e);
                  }}
                  disabled
                />
              </div>
              <div className="cmb-20 col-md-6">
                <Dropdown
                  isRequired
                  label="STATE"
                  placeholder="Select state"
                  error={errors?.state_id}
                  options={stateList}
                  id="state_id"
                  value={values?.state_id}
                  optionValue="state"
                  isLoading={loader.state}
                  onChange={(e) => {
                    let stateVal = e.target.data.state;
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        state_id: e.target.value,
                        state: stateVal,
                      };
                    });
                    if (e?.target?.value) {
                      let oldData = cloneDeep({
                        ...cityData,
                        list: [],
                        search: "",
                        isLoading: true,
                        state_id: e?.target?.value,
                      });
                      setCityData(oldData);
                      fetchCityFromState(oldData, true);
                    }
                    handleChange(e);
                  }}
                  disabled={!values?.country_id}
                />
              </div>
              <div className="cmb-20 col-md-6">
                <Dropdown
                  isRequired
                  label="CITY"
                  placeholder="Select city"
                  error={errors?.city_id}
                  options={cityData?.list}
                  optionValue="city"
                  id="city_id"
                  value={values?.city_id}
                  onChange={(e) => {
                    let cityVal = e.target.data.city;
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        city_id: e.target.value,
                        city: cityVal,
                      };
                    });
                  }}
                  isLoading={cityData.isLoading}
                  disabled={!values?.state_id}
                  onInputChange={handleSearchCity}
                  onMenuScrollToBottom={handelScrollCity}
                />
              </div>
              <div className="cmb-20 col-md-6">
                <TextInput
                  required
                  label="PINCODE"
                  placeholder="PIN"
                  error={errors?.zip}
                  id="zip"
                  value={values?.zip}
                  onChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        zip: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="cmb-20">
                <TextInput
                  required
                  label="ADDRESS LINE 1"
                  placeholder="Address Line 1"
                  id="mailing_address"
                  error={errors?.mailing_address}
                  value={values?.mailing_address}
                  onChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        mailing_address: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="cmb-20">
                <TextInput
                  placeholder="Address Line 2"
                  label="ADDRESS LINE 2"
                  id="address_line_two"
                  value={values?.address_line_two}
                  onChange={(e) => {
                    getBillingDetails((prev) => {
                      return {
                        ...prev,
                        address_line_two: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BillingInformation;
