import {
  stripePostWithPK,
  stripePost,
  stripeGet,
  stripeDelete,
} from "services";

export const serialize = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? serialize(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};
export const createCardToken = async (cardData, StripePublishKey) => {
  const card_details = serialize(cardData);
  let response = null;
  await stripePostWithPK(
    "https://api.stripe.com/v1/tokens",
    card_details,
    StripePublishKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const createPaymentToken = async (cardToken, StripePublishKey) => {
  var card = {
    card: cardToken,
  };
  const card_details = serialize({ ...card, type: "card" });
  let response = null;
  await stripePostWithPK(
    "https://api.stripe.com/v1/payment_methods",
    card_details,
    StripePublishKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const linkCardWithAccount = async (
  card_id,
  customer_id,
  StripeSecretKey
) => {
  var customer_data = {
    source: card_id,
  };
  const customer_details = serialize(customer_data);
  let response = null;

  await stripePost(
    "https://api.stripe.com/v1/customers/" + customer_id + "/sources",
    customer_details,
    StripeSecretKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const setAsPrimaryCard = async (
  card_id,
  customer_id,
  StripeSecretKey
) => {
  var customer_data = {
    default_source: card_id,
  };
  const customer_details = serialize(customer_data);
  let response = null;
  await stripePost(
    "https://api.stripe.com/v1/customers/" + customer_id,
    customer_details,
    StripeSecretKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const getAllCard = async (customer_id, StripeSecretKey) => {
  let response = null;
  await stripeGet(
    `https://api.stripe.com/v1/customers/${customer_id}/sources`,
    StripeSecretKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const getCustomerDetails = async (customer_id, StripeSecretKey) => {
  let response = null;
  await stripeGet(
    `https://api.stripe.com/v1/customers/${customer_id}`,
    StripeSecretKey
  ).then((res) => {
    response = res;
  });
  return response;
};
export const deleteCard = async (card_id, customer_id, StripeSecretKey) => {
  let response = null;
  await stripeDelete(
    `https://api.stripe.com/v1/customers/${customer_id}/sources/${card_id}`,
    StripeSecretKey
  ).then((res) => {
    response = res;
  });
  return response;
};
