import APlus from "assets/images/Aplus.svg";
import RightArrow from "assets/images/rightArrow.svg";
import iconSingup from "assets/images/iconSingup.png";
import iconGradeA from "assets/images/iconGradeA.svg";
import iconGoogle from "assets/images/iconGoogle.svg";
import iconFacebook from "assets/images/iconFacebook.svg";
import iconWhatsApp from "assets/images/iconWhatsApp.svg";
import iconLinkedIn from "assets/images/iconLinkedIn.svg";
import iconLocation from "assets/images/iconLocation.svg";
import iconMobile from "assets/images/iconMobile.svg";
import iconEnvelope from "assets/images/iconEnvelope.svg";
import iconShowpassword from "assets/images/iconShowpassword.svg";
import iconHidepassword from "assets/images/iconHidepassword.svg";
import WhatsApp from "assets/images/WhatsApp.png";
import LinkedIn from "assets/images/LinkedIn.png";
import Facebook from "assets/images/Facebook.png";
import Location from "assets/images/location.webp";
import Message from "assets/images/message.webp";
import iconEllipse from "assets/images/iconEllipse.svg";
import monthlySubscription from "assets/images/monthlySubscription.svg";
import quaterlySubscription from "assets/images/quaterlySubscription.svg";
import yearlySubscription from "assets/images/yearlySubscription.svg";
import subscriptionMasGroup from "assets/images/subscriptionMasGroup.svg";
import iconProfile from "assets/images/iconProfile.webp";
import visa from "assets/images/visa.svg";
import cvv from "assets/images/cvv.svg";
import qrCode from "assets/images/qrCode.svg";
import contactUsBg from "assets/images/contactUsBg.svg";
import whatsappContact from "assets/images/whatsappContact.svg";
import emailContact from "assets/images/emailContact.svg";
import management from "assets/images/management.svg";
import chemistry from "assets/images/chemistry.webp";
import mathematics from "assets/images/mathematics.svg";
import technology from "assets/images/technology.svg";
import engineering from "assets/images/engineering.svg";
import planetaryScience from "assets/images/planetaryScience.svg";
import Logo from "assets/images/logo.webp";
import Banner from "assets/images/banner.webp";
import Section3 from "assets/images/section3.png";
import Section1 from "assets/images/section1.png";
import Section2 from "assets/images/section2.png";

import Section2Banner1 from "assets/images/Home/sec-2-1.webp";
import Section2Banner2 from "assets/images/Home/sec-2-2.webp";
import FC1 from "assets/images/Home/FC1.webp";
import FC2 from "assets/images/Home/FC2.webp";
import FC3 from "assets/images/Home/FC3.webp";
import FC4 from "assets/images/Home/FC4.webp";
import FC5 from "assets/images/Home/FC5.webp";
import FC6 from "assets/images/Home/FC6.webp";
import SEC5 from "assets/images/Home/sec5.webp";
import Card1 from "assets/images/Home/card1.webp";
import Card2 from "assets/images/Home/card2.webp";
import Card3 from "assets/images/Home/card3.webp";
import Student from "assets/images/imageStudent.webp";
import iconEmailWhite from "assets/images/iconEmailWhite.svg";
import iconWhatsappLogo from "assets/images/iconWhatsappLogo.svg";
import iconVerified from "assets/images/iconVerified.svg";
import imageBooks from "assets/images/imageBooks.svg";
import iconCorrect from "assets/images/iconCorrect.svg";
import iconI from "assets/images/iconI.svg";
import imageMathsLogic from "assets/images/imageMathsLogic.webp";
import iconEmailE from "assets/images/iconEmailE.svg";
import iconeRightArrow from "assets/images/iconeRightArrow.svg";
import searchIcon from "assets/images/searchIcon.png";
import fullWidthStudent from "assets/images/fullWidthStudent.png";
import socialSection from "assets/images/socialSection.webp";
import iconLogout from "assets/images/iconLogout.svg";
import iconSingup2 from "assets/images/iconSingup2.webp";
import pageNotFound from "assets/images/pageNotFound.png";
import subscribtionLabel from "assets/images/subscribtionLabel.png";
import underLineBorder from "assets/images/underLineBorder.png";
import chevronDown from "assets/images/chevronDown.svg";
import profileIcon from "assets/images/profileIcon.svg";
import unauthorized from "assets/images/unauthorized.jpg";
import defaultAvatar from "assets/images/defaultAvatar.png";
import stp_card_amex_C from "assets/images/Card/stp_card_amex_C.png";
import stp_card_diners_C from "assets/images/Card/stp_card_diners_C.png";
import stp_card_discover_C from "assets/images/Card/stp_card_discover_C.png";
import stp_card_error_C from "assets/images/Card/stp_card_error_C.png";
import stp_card_jcb_C from "assets/images/Card/stp_card_jcb_C.png";
import stp_card_maestro_card_C from "assets/images/Card/stp_card_maestro_card_C.png";
import stp_card_master_card_C from "assets/images/Card/stp_card_master_card_C.png";
import stp_card_visa_C from "assets/images/Card/stp_card_visa_C.png";
import stp_card_union_pay_C from "assets/images/Card/stp_card_union_pay_C.png";
import session from "assets/images/session.png";

export const icons = {
  APlus,
  iconSingup,
  iconGradeA,
  iconGoogle,
  iconFacebook,
  iconWhatsApp,
  iconLinkedIn,
  iconLocation,
  iconMobile,
  iconEnvelope,
  iconShowpassword,
  iconHidepassword,
  WhatsApp,
  LinkedIn,
  Facebook,
  Location,
  Message,
  iconEllipse,
  monthlySubscription,
  quaterlySubscription,
  yearlySubscription,
  subscriptionMasGroup,
  iconProfile,
  visa,
  cvv,
  qrCode,
  contactUsBg,
  whatsappContact,
  emailContact,
  management,
  chemistry,
  mathematics,
  technology,
  engineering,
  planetaryScience,
  Logo,
  Banner,
  Section3,
  Section1,
  Section2,
  RightArrow,
  Section2Banner1,
  Section2Banner2,
  FC1,
  FC2,
  FC3,
  FC4,
  FC5,
  FC6,
  SEC5,
  Card1,
  Card2,
  Card3,
  Student,
  iconEmailWhite,
  iconWhatsappLogo,
  iconVerified,
  imageBooks,
  iconCorrect,
  iconI,
  imageMathsLogic,
  iconEmailE,
  iconeRightArrow,
  searchIcon,
  fullWidthStudent,
  socialSection,
  iconLogout,
  iconSingup2,
  pageNotFound,
  subscribtionLabel,
  underLineBorder,
  chevronDown,
  profileIcon,
  unauthorized,
  defaultAvatar,
  stp_card_amex_C,
  stp_card_diners_C,
  stp_card_discover_C,
  stp_card_error_C,
  stp_card_jcb_C,
  stp_card_maestro_card_C,
  stp_card_master_card_C,
  stp_card_visa_C,
  stp_card_union_pay_C,
  session,
};
export default icons;
