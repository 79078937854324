import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import PasswordInput from "components/form/PasswordInput";
import PhoneNumber from "components/form/PhoneNumber";
import TextInput from "components/form/TextInput";
import { get2FAData, titleCaseString, trimLeftSpace } from "utils/helpers";
import { getAllCountires, register, throwError } from "store/globalSlice";
import { passwordError } from "utils/constants";
import Dropdown from "components/form/Dropdown/Dropdown";
import { useEffect } from "react";

const SignupPage = ({ handelSubscription }) => {
  const dispatch = useDispatch();
  const { countryList } = useSelector((state) => ({
    countryList: state.global.countryList,
  }));
  const [btnLoading, setBtnLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [loader, setLoader] = useState({
    country: true,
  });

  const handelSave = async (values) => {
    setBtnLoading(true);
    const res = await get2FAData({
      email: values.email,
      name: "HelpACourse",
    });
    let qrCodeData = {};
    if (res?.status === 200) {
      qrCodeData = {
        QrCode: res?.data?.imageData || "",
        secret: res?.data?.secretCode || "",
      };
      // console.log(res);
    } else {
      dispatch(throwError(res.message));
    }
    const response = await dispatch(register({ ...values, ...qrCodeData }));
    if (response?.status === 201) {
      if (res) {
        localStorage.QrCode = res?.data?.imageData || "";
        localStorage.secret = res?.data?.secretCode || "";
      }
      handelSubscription();
    }
    setBtnLoading(false);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    last_name: Yup.string()
      .required("Last name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    mobile_no: Yup.string().required("Mobile is required."),
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    password: Yup.string()
      .required("Password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        passwordError
      ),
    country_id: Yup.string().required("Country is required."),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    country_code: "IN",
    email: "",
    password: "",
    country_id: "",
  };

  const initAPI = async () => {
    await dispatch(getAllCountires());
    setLoader((prev) => {
      return { ...prev, country: false };
    });
  };
  useEffect(() => {
    initAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldError,
          // setErrors,
        } = props;

        const {
          first_name,
          last_name,
          mobile_no,
          email,
          password,
          country_code,
          country_id,
        } = values;
        const {
          first_name: errFirstName,
          last_name: errLastName,
          mobile_no: errMobile,
          email: errEmail,
          password: errPassword,
          country_id: errCountry,
        } = errors;
        return (
          <form>
            <div className="row">
              <div className="col-md-6 cmb-25">
                <TextInput
                  label="FIRST NAME"
                  placeholder="Enter First Name"
                  id="first_name"
                  value={first_name}
                  error={errFirstName}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: trimLeftSpace(titleCaseString(e.target.value)),
                      },
                    });
                  }}
                />
              </div>

              <div className="col-md-6 cmb-25">
                <TextInput
                  label="LAST NAME"
                  placeholder="Enter Last Name"
                  id="last_name"
                  value={last_name}
                  error={errLastName}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: trimLeftSpace(titleCaseString(e.target.value)),
                      },
                    });
                  }}
                />
              </div>

              <div className="cmb-25">
                <Dropdown
                  label="COUNTRY"
                  placeholder="Enter Country"
                  id="country_id"
                  options={countryList}
                  value={country_id}
                  error={errCountry}
                  optionValue="country"
                  isLoading={loader.country}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>

              <div className="cmb-25">
                <PhoneNumber
                  label="MOBILE"
                  placeholder="Enter Mobile"
                  id="mobile_no"
                  value={mobile_no}
                  error={errMobile}
                  country_code={country_code}
                  onCodeChange={(e) => {
                    handleChange(e);
                    setFieldValue("mobile_no", "");
                  }}
                  onChange={handleChange}
                  onBlur={(isError) => {
                    let newerror = isError ? "Invalid mobile number" : "";
                    setFieldError("mobile_no", newerror);
                  }}
                />
              </div>

              <div className="cmb-25">
                <TextInput
                  label="EMAIL"
                  placeholder="Enter Email"
                  id="email"
                  value={email}
                  error={errEmail}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: e.target.value.toLowerCase(),
                      },
                    });
                  }}
                />
              </div>

              <div>
                <PasswordInput
                  showInfo
                  label="PASSWORD"
                  placeholder="Enter Password"
                  id="password"
                  value={password}
                  error={errPassword}
                  onChange={handleChange}
                  isPasswordShow={0}
                />
              </div>

              <div>
                <CheckBox
                  onClick={() => {
                    setIsCheck(!isCheck);
                  }}
                  checked={isCheck}
                  label={
                    <div className="text-12-400 color-outer-space">
                      <span>{"I accept Help a Course "}</span>
                      <span
                        className="color-primary pointer"
                        onClick={() => {
                          window.open("/terms-of-services");
                        }}
                      >
                        Terms of Services
                      </span>
                      <span>{" and "}</span>
                      <span
                        className="color-primary pointer"
                        onClick={() => {
                          window.open("/privacy-policy");
                        }}
                      >
                        Privacy Policy
                      </span>
                    </div>
                  }
                />
              </div>

              <div className="cmb-25">
                <Button
                  btnStyle="btn-primary-yellow"
                  value="Sign Up"
                  onClick={(e) => {
                    if (!btnLoading) {
                      handleSubmit(e);
                    }
                  }}
                  btnLoading={btnLoading}
                  disabled={!isCheck}
                  hideAnimation
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
export default SignupPage;
