import { useState, useEffect } from "react";
import BootrsapModal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/form/Button/Button";
import TextInput from "components/form/TextInput";
import Loader from "components/layouts/Loader";
import { getDialCode, trimLeftSpace } from "utils/helpers";
import {
  getProfileData,
  sendVerfication,
  throwSuccess,
  verification,
} from "store/globalSlice";

const Verify = ({ type, setIsVerify }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [otp, setotp] = useState("");
  const { userData } = useSelector((state) => ({
    userData: state.global.userData,
  }));
  const { mobile_no, email, country_code } = userData || {};
  const sendOTP = async () => {
    setIsLoading(true);
    const response = await dispatch(
      sendVerfication({
        type: type === 1 ? "email" : "mobile",
      })
    );
    if (response?.status === 200) {
      dispatch(throwSuccess("Verification code sent successfully."));
    }
    setIsLoading(false);
  };
  useEffect(() => {
    sendOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BootrsapModal
      onHide={() => {
        setIsVerify(false);
      }}
      size="lg"
      centered
      show
    >
      <BootrsapModal.Body id="modal-container" className="cps-50 cpe-50 cpb-40">
        {isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center cpt-30"
            style={{
              height: "250px",
            }}
          >
            <Loader size="md" />
          </div>
        ) : (
          <>
            <div
              className="close-block pointer d-flex"
              onClick={() => {
                setIsVerify(false);
              }}
            >
              <i className="bi bi-x-lg text-20-600" />
            </div>
            <div className="text-22-600 color-outer-space mt-4">
              Verify your {type === 1 ? "email" : "mobile number"}
            </div>
            <div className="text-16-400 cmb-25">
              A verification code has been sent to{" "}
              <b>
                {type === 1
                  ? email
                  : `${
                      country_code ? `(${getDialCode(country_code)})` : ""
                    } ${mobile_no}`}
              </b>
            </div>
            <div className="text-16-400 cmb-25">
              Please check your {type === 1 ? "inbox" : "message"} and enter the
              verification code below to verify{" "}
              {type === 1 ? "email" : "mobile number"}.
            </div>
            <div className="cmb-22">
              <TextInput
                id="otp"
                placeholder="Enter code here"
                value={otp}
                onChange={(e) => {
                  setotp(trimLeftSpace(e.target.value));
                }}
              />
            </div>
            <Button
              hideAnimation
              btnStyle="btn-primary-yellow"
              value="Verify"
              className="cps-30 cpe-30"
              onClick={async () => {
                setBtnLoading(true);
                let payload = {
                  mobileVerifyToken: otp,
                };
                if (type === 1) {
                  payload = {
                    emailVerifyToken: otp,
                  };
                }
                const response = await dispatch(verification(payload));
                if (response?.status === 200) {
                  dispatch(
                    throwSuccess(
                      `${
                        type === 1 ? "Email" : "Mobile Number"
                      } Verified Successfully.`
                    )
                  );
                  dispatch(getProfileData());
                  setIsVerify(false);
                }
                setBtnLoading(false);
              }}
              disabled={!otp}
              btnLoading={btnLoading}
            />
          </>
        )}
      </BootrsapModal.Body>
    </BootrsapModal>
  );
};
export default Verify;
