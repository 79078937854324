import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { icons } from "utils/constants/icons";
import GlobalSearch from "components/layouts/GlobalSearch";
import AuthPopup from "components/layouts/AuthPopup";
import Button from "components/form/Button";
import SubscriptionPopup from "components/layouts/SubscriptionPopup";
import { getDataFromLocalStorage } from "utils/helpers";
import { commonRoute } from "utils/constants";
import {
  handelLogout,
  setIsLoginSignup,
  setIsSubscription,
} from "store/globalSlice";
import "./Header.scss";

const Header = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const {
    userState,
    isLoginSignup,
    isSubscription,
    userData,
    subscriptionList,
    siteData,
  } = useSelector((state) => ({
    userState: state.global.userState,
    isLoginSignup: state.global.isLoginSignup,
    isSubscription: state.global.isSubscription,
    userData: state.global.userData,
    subscriptionList: state.global.subscriptionList,
    siteData: state.global.siteData,
  }));
  const [isLogoError, setIsLogoError] = useState(false);
  const [isImgError, setIsImgError] = useState(false);
  const [pageType, setPageType] = useState(0);
  const [colClass, setColClass] = useState({
    search: window.innerWidth > 990 ? "col-md-5" : "",
    option: window.innerWidth > 990 ? "col-md-7" : "text-end",
  });
  const handelRedirect = (url) => {
    history(url);
  };
  const handelWidth = (type) => {
    if (window.innerWidth > 990) {
      if (type === "FOCUS") {
        setColClass({
          search: "col-md-12 tran",
          option: "d-none",
        });
      } else {
        setColClass({
          search: "col-md-5 tran",
          option: "d-none",
        });
        setTimeout(() => {
          setColClass({
            search: "col-md-5 tran",
            option: "col-md-7",
          });
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (!getDataFromLocalStorage("token")) {
      if (window.location.href.includes("my-profile")) {
        history(commonRoute.home);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);
  useEffect(() => {
    if (localStorage.isTwoFactorLogin) {
      setPageType(3);
      dispatch(setIsLoginSignup(true));
    }
    if (localStorage.isSignup) {
      setPageType(1);
      dispatch(setIsLoginSignup(true));
      localStorage.removeItem("isSignup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSignup]);
  const isLogin = getDataFromLocalStorage("token") ? false : true;
  const { profile_Image } = userData || {};
  let displayImage = isImgError
    ? icons.defaultAvatar
    : profile_Image || icons.defaultAvatar;

  return (
    <div id="header-container">
      {isLoginSignup && (
        <AuthPopup
          parentPageType={pageType}
          handelSubscription={() => {
            dispatch(setIsSubscription(true));
            dispatch(setIsLoginSignup(false));
          }}
        />
      )}

      {isSubscription && subscriptionList?.length > 0 && <SubscriptionPopup />}
      <Navbar
        expand="lg"
        className={window.innerWidth < 992 ? "cps-15 cpe-15" : "cps-30 cpe-30"}
      >
        <Navbar.Brand
          href=""
          onClick={(e) => {
            e.preventDefault();
            handelRedirect("/");
          }}
        >
          {!isLogoError && siteData?.app_logo && (
            <img
              alt="logo"
              src={siteData?.app_logo}
              className="header-logo pointer"
              onError={() => {
                setIsLogoError(true);
              }}
            />
          )}
        </Navbar.Brand>
        {window.innerWidth < 992 && (
          <div className="flex-grow-1 pe-3">
            <GlobalSearch
              handelWidth={handelWidth}
              isFull={colClass?.search.includes("col-md-12")}
            />
          </div>
        )}

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <div className="w-100">
            <div className="row align-items-center">
              {window.innerWidth > 991 && (
                <div className={colClass?.search}>
                  <GlobalSearch
                    handelWidth={handelWidth}
                    isFull={colClass?.search.includes("col-md-12")}
                  />
                </div>
              )}
              <div
                className={`d-flex ${colClass?.option} ${
                  window.innerWidth < 992
                    ? "flex-column align-items-end justify-content-end cps-30"
                    : "align-items-center justify-content-between"
                }`}
              >
                <Nav className="my-2 my-lg-0 headerMenu gap-3" navbarScroll>
                  <Nav.Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      handelRedirect("/quiz-solutions");
                    }}
                  >
                    Quiz Solutions
                  </Nav.Link>

                  <Nav.Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      history("/book");
                    }}
                  >
                    Books
                    <div className="text-danger text-11-400 mt-2">
                      (Coming Soon)
                    </div>
                  </Nav.Link>

                  <NavDropdown
                    // title={`Tutor Support ${(
                    //   <img src={icons.chevronDown} alt="down-arrow" />
                    // )}`}
                    title={
                      <>
                        <div className="d-flex gap-2">
                          <span>Tutor Support</span>
                          <span>
                            <img
                              src={icons.chevronDown}
                              alt="down-arrow"
                              style={{ height: "10px" }}
                            />
                          </span>
                        </div>
                        <span className="text-danger text-11-400">
                          (Coming Soon)
                        </span>
                      </>
                    }
                    id="navbarScrollingDropdown"
                    renderMenuOnMount={true}
                  >
                    <div className="cps-10 cpe-10 cpt-20 cpb-15 d-flex">
                      <div>
                        <div className="text-18-600 color-outer-space ms-3 mb-3">
                          Task{" "}
                          <span className="text-danger text-11-400">
                            (Coming Soon)
                          </span>
                        </div>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/home-work-help");
                          }}
                        >
                          Home Work Help
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/citations");
                          }}
                        >
                          Citations
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/references");
                          }}
                        >
                          References
                        </NavDropdown.Item>
                      </div>
                      <div className="border-end cms-10 cme-10" />
                      <div>
                        <div className="text-18-600 color-outer-space ms-3 mb-3">
                          Tool’s{" "}
                          <span className="text-danger text-11-400">
                            (Coming Soon)
                          </span>
                        </div>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/grammar-checker");
                          }}
                        >
                          Grammar Checker
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/proof-reading");
                          }}
                        >
                          Proof Reading
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history("/tutor-support/expert-Q&A");
                          }}
                        >
                          Expert Q&A
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>

                  <NavDropdown
                    title={
                      <>
                        <div className="d-flex gap-2">
                          <span>Career</span>
                          <span>
                            <img
                              src={icons.chevronDown}
                              alt="down-arrow"
                              style={{ height: "10px" }}
                            />
                          </span>
                        </div>
                        <span className="text-danger text-11-400">
                          (Coming Soon)
                        </span>
                      </>
                    }
                    id="navbarScrollingDropdown"
                    className={
                      window.innerWidth < 992
                        ? "d-flex justify-content-end"
                        : ""
                    }
                    renderMenuOnMount={true}
                  >
                    <NavDropdown.Item
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        history("/career/internship");
                      }}
                      className="pt-2"
                    >
                      Internship
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        history("/career/career-advice");
                      }}
                    >
                      Career Advice
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <div>
                  {isLogin ? (
                    <div className="bg-primary-yellow color-black rounded text-nowrap text-14-600 cps-20 cpe-20 pt-2 pb-2">
                      <span
                        className="pointer"
                        onClick={() => {
                          setPageType(0);
                          dispatch(setIsLoginSignup(true));
                        }}
                      >
                        Login / Sign Up
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center gap-2">
                      {!isLogin && (
                        <div
                          className="header-profile"
                          onClick={() => {
                            history("/my-profile/account-overview");
                          }}
                        >
                          <img
                            src={displayImage}
                            alt="profile-icon"
                            className="fill-image"
                            onError={() => {
                              setIsImgError(true);
                            }}
                          />
                        </div>
                      )}

                      <Button
                        hideAnimation
                        className="btn-primary-yellow h-auto pt-2 pb-2 cps-20 cpe-20 d-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(handelLogout());
                          history("/");
                        }}
                        value={
                          <>
                            <span className="me-2">
                              <img src={icons.iconLogout} alt="logout-logo" />
                            </span>
                            <span className="text-14-600">Logout</span>
                          </>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default Header;
