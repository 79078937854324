import { icons } from "utils/constants/icons";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./Footer.scss";

const Footer = () => {
  const history = useNavigate();
  const { siteData } = useSelector((state) => ({
    siteData: state.global.siteData,
  }));
  const { facebook_link, linkedln_link, whatsUp_mobile } = siteData;
  return (
    <section>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 footerDiv">
              <div className="text-30-700 color-white cmb-18">HelpACourse</div>
              <p className="text-left text-16-400-24 cmb-50">
                HelpACourse is an organization which is well aware of your
                deadlines and requirement, and provides your assignments in
                time.
              </p>
            </div>
            <div className="col-md-2 footerDiv ml-5 text-16-400-17">
              <div className="footerLink text-20-700 mb-4">Quick Links</div>

              <li
                className="pointer"
                onClick={() => {
                  history("/book");
                }}
              >
                Books
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/quiz-solutions");
                }}
              >
                Quiz Solutions
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/career/internship");
                }}
              >
                Internship
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/career/career-advice");
                }}
              >
                Career Advice
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/contact-us");
                }}
              >
                Contact Us
              </li>
            </div>
            <div className="col-md-3 footerDiv text-16-400-17">
              <div className="footerLink text-20-700 mb-4">Useful Links</div>
              <li
                className="pointer"
                onClick={() => {
                  history("/cancel-refund-policy");
                }}
              >
                Cancel & Refund Policy
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/terms-of-services");
                }}
              >
                Terms and Conditions
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/privacy-policy");
                }}
              >
                Privacy Policy
              </li>
              <li
                className="pointer"
                onClick={() => {
                  history("/sitemap");
                }}
              >
                Sitemap
              </li>
            </div>
            <div className="col-md-3 footerDiv text-16-400-26">
              <div className="footerIcon">
                <span>
                  <Nav.Link
                    className="text-16-400-26"
                    href={facebook_link}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(facebook_link);
                    }}
                  >
                    <img
                      className="pointer"
                      src={icons.Facebook}
                      alt="facebook"
                    />
                  </Nav.Link>
                </span>
                <span>
                  <Nav.Link
                    className="text-16-400-26"
                    href={linkedln_link}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(linkedln_link);
                    }}
                  >
                    <img
                      className="pointer"
                      src={icons.LinkedIn}
                      alt="linkedin"
                    />
                  </Nav.Link>
                </span>
                <span>
                  <Nav.Link
                    className="text-16-400-26"
                    href={`https://wa.me/${whatsUp_mobile}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://wa.me/${whatsUp_mobile}`);
                    }}
                  >
                    <img
                      className="pointer"
                      src={icons.WhatsApp}
                      alt="whatsapp"
                    />
                  </Nav.Link>
                </span>
              </div>
              {/* <div className="footerLink text-20-700 mb-4">Contact Us</div> */}
              {/* <li className="mt-2 d-flex align-items-start">
                <img
                  className="location-icon mt-1 me-2"
                  alt="location"
                  src={icons.Location}
                />
                <p className="text-justify">
                  123 Fifth Floor East 26th Street, New York, NY 10011
                </p>
              </li>
              <li>
                <img
                  src={icons.Message}
                  alt="message"
                  className="message-icon me-2"
                />
                harsha@helpadmin.dev
              </li> */}
            </div>
          </div>
        </div>

        <div className="footer-saprator" />
        <div className="footer-bootom cpt-20 cpb-20 container w-100">
          <div className="text-16-400">
            Copyright © 2023 HelpACourse. All Rights Reserved.
          </div>
          <Nav.Link
            className="text-16-400-26"
            href="https://ofinit.com"
            title="Web and Mobile App Development Company"
            alt="Web and Mobile App Development Company"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://ofinit.com");
            }}
          >
            <span>
              Powered by <span className="text-17-600">OfinIT</span>
            </span>
          </Nav.Link>
        </div>
      </div>
    </section>
  );
};
export default Footer;
