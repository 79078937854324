import CommonHelmet from "components/layouts/CommonHelmet";
import Layout from "../Layouts";

const CancelRefundPolicy = () => {
  return (
    <Layout id="cancel-refund-policy" isLogin>
      <CommonHelmet pageTitle="Cancellation and Refund Policy" />
      <div className="pt-5">
        <div className="text-23-700 color-black">
          Subscription Fees, Cancellation and Refund Policy
        </div>
        <div className="mt-3 mb-3 text-14-600">Subscription Fees</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Subscriptions are on a fixed period basis i.e. Monthly, Quarterly &
            Annualy basis and provide the User Access to the entire Library.
            Your fixed period subscription renews automatically each period end
            date and your credit card will be charged the rate stated on the
            HelpACourse website.{" "}
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Credit card holder's account is automatically charged on the same
            date as the original transaction date on each corresponding month.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Discounts, rebates or other special offers only valid for respective
            term of subscription type.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            HelpACourse may terminate the subscription and these terms if it is
            unable to renew the subscription based on inaccurate or outdated
            credit card information.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Right of access granted under these Terms is effective only upon
            payment of the subscription fees.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We reserve the right to change our Subscription Plans or adjust
            pricing for our Services at our sole discretion. Any price changes
            or changes to your subscription will take effect following notice to
            you, except as otherwise required by applicable law.
          </li>
        </ul>
        <div className="mt-3 mb-3 text-14-600">Cancellation</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You can cancel your subscription from your dashboard billing page.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            The only valid method for canceling your Plan is via the
            cancellation link provided on your user "dashboard" page, accessible
            after logging in to the HelpACourse Website. Requests to cancel by
            email or phone are not considered, and do not accomplish,
            cancellation.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            If your services are being billed as a monthly, Quarterly or annual
            subscription, your subscription will be terminated upon account
            cancellation and you will not be charged for any renewal terms.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Once you cancel your membership subscription, you will not lose
            access immediately. Your membership will continue through the end of
            your current charge cycle. For ex: If your credit card is charged
            for monthly subscription on the 10th of January month and you cancel
            on January 25th, you will not lose access until the February 9th.
          </li>
        </ul>
        <div className="mt-3 mb-3 text-14-600">Refunds</div>
        <ul className="cmb-130">
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Whether you subscribe to a fixed term, a monthly, Quarterly or
            annual payment option, subscription fees are billed in advance and
            are non-refundable. There will be no refunds or credits for partial
            months of service, or refunds for the remaining term of a paid
            membership.{" "}
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            HelpACourse subscription is non-refundable and will continue to
            renew automatically until cancelled. In case you want to cancel your
            subscription, kindly do the same at least 2 days prior to the
            renewal date.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Given the digital nature of our products, we offer no refunds. In
            case the HelpACourse website is permanently closed down due to
            closure of the business, then future subscription payments shall not
            be charged.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            In case you would like to discuss alternate products/services,
            please do contact us using mail or write to us part in website.{" "}
          </li>
        </ul>
      </div>
    </Layout>
  );
};
export default CancelRefundPolicy;
