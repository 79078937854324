import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";
import { Dropdown } from "react-bootstrap";
import Button from "components/form/Button";
import CardInformation from "components/form/CardInformation";
import CancelPopup from "components/layouts/CancelPopup";
import { setIsSubscription, throwError } from "store/globalSlice";
import {
  createCardToken,
  deleteCard,
  getAllCard,
  getCardTypeImage,
  getCustomerDetails,
  getDataFromLocalStorage,
  linkCardWithAccount,
  setAsPrimaryCard,
} from "utils/helpers";
import "./Billing.scss";
import { stripeError } from "utils/constants";
import Loader from "components/layouts/Loader/Loader";
import { useNavigate } from "react-router-dom";
// import ConfirmPopup from "components/layouts/ConfirmPopup/ConfirmPopup";

const Billing = () => {
  const { siteData, userData } = useSelector((state) => ({
    siteData: state.global.siteData,
    userData: state.global.userData,
  }));
  const dispatch = useDispatch();
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [defaultCard, setDefaultCard] = useState("");
  const [isForm, setIsForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(true);
  // const [confirmPopup, setConfirmPopup] = useState(false);
  const [cardData, setCardData] = useState({});
  const { customer_id, Orders } = getDataFromLocalStorage() || {};
  const isPayment = Orders?.[0]?.status === 1;
  const isCancel = Orders?.[0]?.status === 2;
  // const isCancel = Orders?.some((o) => o.status === 1);
  const handelSetAsPrimaryCard = async (cardID) => {
    if (!siteData?.secret_key) {
      dispatch(throwError(stripeError));
      setBtnLoading(false);
      return;
    }
    const response = await setAsPrimaryCard(
      cardID,
      customer_id,
      siteData?.secret_key
    );
    if (response?.id) {
      setDefaultCard(cardID);
    }
  };
  const handelDelete = async (cardID) => {
    if (!siteData?.secret_key) {
      dispatch(throwError(stripeError));
      setBtnLoading(false);
      return;
    }
    const response = await deleteCard(
      cardID,
      customer_id,
      siteData?.secret_key
    );
    if (response?.id) {
      let oldData = cardList;
      oldData = oldData.filter((o) => o.id !== cardID);
      setCardList(oldData);
    }
  };

  const handelCreateNew = async () => {
    setBtnLoading(true);
    const { name, card_number, month, year, cvv } = cardData;
    const cardObject = {
      card: {
        number: card_number,
        exp_month: month,
        exp_year: year,
        cvc: cvv,
        name: name,
        address_country: "US",
        address_line1: "Test",
      },
    };
    if (!siteData?.publishable_key) {
      dispatch(throwError(stripeError));
      return;
    }
    let cardResponse = await createCardToken(
      cardObject,
      siteData?.publishable_key
    );
    if (cardResponse?.id) {
      if (!siteData?.secret_key) {
        dispatch(throwError(stripeError));
        setBtnLoading(false);
        return;
      }
      await linkCardWithAccount(
        cardResponse?.id,
        customer_id,
        siteData?.secret_key
      );
      setCardList((prev) => {
        return [...prev, cardResponse?.card];
      });
      setIsForm(false);
    } else {
      dispatch(throwError(cardResponse?.error?.message));
    }
    setBtnLoading(false);
  };
  const getCustomerData = async () => {
    const response = await getCustomerDetails(
      customer_id,
      siteData?.secret_key
    );
    // console.log("getCustomerData", response);
    setDefaultCard(response?.default_source);
  };
  const getCardList = async () => {
    const response = await getAllCard(customer_id, siteData?.secret_key);
    // console.log("getCardList response", response);
    setCardList(response?.data || []);
    // console.log("setCardList response", response?.data);

    setIsCardLoading(false);
  };

  useEffect(() => {
    // console.log("siteData", siteData);
    if (siteData?.secret_key) {
      getCustomerData();
      getCardList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isBtnDisabled =
    !cardData?.name ||
    !cardData?.card_number ||
    !cardData?.cvv ||
    !cardData?.idValidDate ||
    !cardData?.isValidNumber;

  // console.log("-----", cardData);
  // console.log("-----", isBtnDisabled);
  const isCountryId = userData?.country_id === 101;
  const plan = Orders?.[0] || {};
  const {
    subscriptionPlan,
    subscription_amount,
    id: planID,
    status,
    id: orderID,
  } = plan;
  // console.log("cardList", cardList);
  const displayCardList = orderBy(cardList, "id");
  let amount1 = "0";
  let amount2 = "00";

  // console.log("newAmount", subscription_amount);
  if (subscription_amount) {
    if (`${subscription_amount}`.includes(".")) {
      let newAmount = `${subscription_amount}`?.split(".");
      amount1 = newAmount[0];
      amount2 = newAmount[1];
    } else {
      amount1 = subscription_amount;
    }
  }
  const statusObject = {
    0: "Selected",
    1: "Active",
    2: "Canceled",
  };

  const buttonTextObject = {
    0: "Pay",
    1: "Change",
    2: "Canceled",
  };

  return (
    <div className="d-flex flex-wrap" id="billing-container">
      {isLoader ? (
        <div className="d-flex justify-content-center align-items-center cpt-150 cpb-150 w-100">
          <Loader size="md" />
        </div>
      ) : (
        <>
          {show && (
            <CancelPopup
              handleClose={() => {
                setShow(false);
                // setConfirmPopup(true);
              }}
              orderID={orderID}
            />
          )}
          {/* {confirmPopup && (
        <ConfirmPopup
          handleClose={() => {
            setConfirmPopup(false);
          }}
        />
      )} */}
          <div className="left-billing-container slideInDown">
            <div className="border rounded text-center cpb-30 cpt-26 left-plan-container">
              <div className="text-13-600 bg-primary-yellow color-outer-space active-label-text rounded">
                {statusObject[status]} Subscription
              </div>
              <div className="text-23-700 color-outer-space mt-3">
                <span className="myBox cps-20 cpe-20">
                  {planID ? subscriptionPlan : "No Active Plans"}
                </span>
              </div>
              <div className="color-outer-space cmt-30">
                <span className="text-40-700">
                  {isCountryId ? `₹` : `$`}
                  {amount1}
                </span>
                {amount2 && (
                  <span className="text-22-700">
                    {!isCountryId && `.${amount2}`}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-center cpt-30">
                <Button
                  hideAnimation
                  btnStyle="btn-primary-yellow"
                  value={`${
                    !planID ? "Select" : `${buttonTextObject[status]}`
                  } Subscription`}
                  // value={`${
                  //   !planID ? "Select" : status === 2 ? "Choose" : "Change"
                  // } Subscription`}
                  className="cps-30 cpe-30"
                  onClick={() => {
                    if (planID) {
                      if (isCancel) {
                        dispatch(setIsSubscription(true));
                      } else if (isPayment) {
                        setShow(true);
                      } else {
                        history(`/subscription-payment/${orderID}/${planID}`);
                      }
                    } else {
                      dispatch(setIsSubscription(true));
                    }
                  }}
                  // onClick={() => {
                  //   dispatch(setIsSubscription(true));
                  // }}
                />
              </div>
            </div>
            {isCancel && (
              <div
                className="color-primary text-14-500 mt-3 pointer d-flex justify-content-center"
                data-bs-toggle="modal"
                onClick={() => {
                  setShow(true);
                }}
              >
                Cancel Subscription
              </div>
            )}
          </div>

          {!isCardLoading && (
            <div className="border-end cms-50 cme-50 center-billing" />
          )}
          {!isCardLoading && (
            <div className="flex-grow-1 slideInDown">
              <div className="text-22-600 color-outer-space mb-2">
                Card On File
              </div>
              {displayCardList.length === 0 ? (
                <div className="border rounded p-4 text-15-400 mb-3">
                  No card found
                </div>
              ) : (
                displayCardList?.map((elem, index) => {
                  const { id, brand, last4, exp_month, exp_year } = elem;
                  let date = `${
                    exp_month < 10 ? `0${exp_month}` : exp_month
                  }/${exp_year}`;

                  return (
                    <div
                      className={`border rounded cps-14 cpe-14 cpt-14 cpb-14 d-flex align-items-center justify-content-between cmb-20 ${
                        id === defaultCard ? "border-primary" : "pointer"
                      }`}
                      key={index}
                    >
                      <div className="d-flex gap-3">
                        <div
                          style={{
                            height: "53px",
                            width: "77px",
                          }}
                          className="border rounded pt-1 pb-1 ps-2 pe-2"
                        >
                          <img
                            src={getCardTypeImage(brand)}
                            alt="visa"
                            className="fill-image"
                            style={{
                              objectFit: brand === "Visa" ? "contain" : "cover",
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="text-13-600">{brand}</span>
                          <span className="text-12-500">
                            XXXX-XXXX-XXXX-{last4}
                          </span>
                          <span className="text-9-500">Expiry: {date}</span>
                        </div>
                      </div>
                      <div className={id === defaultCard ? "d-none" : ""}>
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="bi bi-list" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/* <Dropdown.Item
                      className="text-14-500"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-pencil-square me-2" />
                      Edit
                    </Dropdown.Item> */}
                            <Dropdown.Item
                              className="text-15-500 mb-2 mt-2"
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                handelDelete(id);
                              }}
                            >
                              <i className="bi bi-trash me-2" />
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-15-500 mb-1"
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                handelSetAsPrimaryCard(id);
                              }}
                            >
                              <i className="bi bi-credit-card me-2" />
                              Make Primary
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  );
                })
              )}

              {isForm ? (
                <>
                  <div className="text-22-600 color-outer-space cmb-20">
                    {editData ? "Update" : "Add"} Card Details
                  </div>

                  <div className="cmb-25">
                    <CardInformation
                      label="CARD INFORMATION"
                      getCardDetails={(data) => {
                        setCardData(data);
                      }}
                    />
                  </div>
                  <div className="d-flex gap-3 justify-content-between">
                    <div className="w-50">
                      <Button
                        hideAnimation
                        btnStyle="btn-primary-yellow"
                        value={editData ? "Update" : "Add Card"}
                        className="cps-30 cpe-30"
                        disabled={isBtnDisabled}
                        btnLoading={btnLoading}
                        onClick={() => {
                          if (editData) {
                            // console.log("EDIT");
                          } else {
                            handelCreateNew();
                          }
                        }}
                      />
                    </div>
                    <div className="w-50">
                      <Button
                        hideAnimation
                        btnStyle="btn-primary-yellow-outline"
                        value="Cancel"
                        className="cps-30 cpe-30"
                        onClick={() => {
                          setIsForm(false);
                          setEditData(null);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="cpt-10">
                  <Button
                    hideAnimation
                    btnStyle="btn-primary-yellow"
                    value="Add New Card"
                    className="cps-30 cpe-30"
                    onClick={() => {
                      setIsForm(true);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Billing;
