import axios from "axios";
import { decrypt, encrypt } from "./encryptdecrypt";
import { dialCode, icons } from "utils/constants";
import moment from "moment";

export function getHeaderData() {
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (getDataFromLocalStorage("token")) {
    header = {
      ...header,
      ...{ Authorization: `Bearer ${getDataFromLocalStorage("token")}` },
    };
  }
  return header;
}

export const storeLocalStorageData = (newData) => {
  const decryptData = decrypt(localStorage.userData || {});
  localStorage.userData = encrypt({ ...decryptData, ...newData });
};

export const getDataFromLocalStorage = (key = "") => {
  let returnValue = "";
  if (localStorage?.userData) {
    const localObjectData = decrypt(localStorage?.userData);
    if (key) {
      returnValue = localObjectData[key] ? localObjectData[key] : "";
    } else {
      returnValue = localObjectData;
    }
  }
  return returnValue;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

// Trim all left space
export const trimLeftSpace = (value) => value.replace(/^\s+/g, ""); // Remove white space from left side

export const objectToQueryParams = (object) => {
  return new URLSearchParams(object).toString();
};

// Format Expire Data
export const formatExpireDate = (value) => {
  return value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      "0$1/" // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      "$1/" // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      "0$1/$2" // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      "$1/$2" // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      "0" // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d/]|^[/]*$/g,
      "" // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      "/" // Prevent entering more than 1 `/`
    );
};

// formatPhoneNumber
export const formatPhoneNumber = (phoneNumberString) => {
  // let output = "(";
  // phoneNumberString?.replace(
  //   /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
  //   function (match, g1, g2, g3) {
  //     if (g1.length) {
  //       output += g1;
  //       if (g1.length === 3) {
  //         output += ")";
  //         if (g2.length) {
  //           output += " " + g2;
  //           if (g2.length === 3) {
  //             output += " - ";
  //             if (g3.length) {
  //               output += g3;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // );
  var output = phoneNumberString
    .replace(/\s+/g, "")
    .replace(/^(\+\d{2})(\d{3})(\d{3})(\d{3})$/, "$1 $2 $3 $4");
  return output;
};

export const get2FAData = async ({ email, name }) => {
  const options = {
    method: "POST",
    url: "https://easy-authenticator.p.rapidapi.com/newAuthKey",
    params: { account: email, issuer: name },
    headers: {
      "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      "X-RapidAPI-Host": "easy-authenticator.p.rapidapi.com",
    },
  };
  let res = null;
  try {
    const response = await axios.request(options);
    res = {
      status: response?.status,
      data: response?.data || {},
    };
  } catch (error) {
    return { status: 403, message: error?.response?.data?.message };
  }
  return res;
};
export const verify2FACode = async ({ secretCode, otp }) => {
  const options = {
    method: "POST",
    url: "https://easy-authenticator.p.rapidapi.com/verify",
    params: { secretCode: secretCode, token: otp },
    headers: {
      "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      "X-RapidAPI-Host": "easy-authenticator.p.rapidapi.com",
    },
  };
  let res = null;
  await axios
    .request(options)
    .then((response) => {
      res = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};

export const getCardTypeImage = (cardType) => {
  let newType = cardType.toLowerCase();
  switch (newType) {
    case "visa":
      return icons.stp_card_visa_C;
    case "american-express":
    case "american express":
      return icons.stp_card_amex_C;
    case "mastercard":
      return icons.stp_card_master_card_C;
    case "discover":
      return icons.stp_card_discover_C;
    case "diners-club":
      return icons.stp_card_diners_C;
    case "maestro":
      return icons.stp_card_maestro_card_C;
    case "jcb":
      return icons.stp_card_jcb_C;
    case "unionpay":
      return icons.stp_card_union_pay_C;
    default:
      return icons.stp_card_error_C;
  }
};

export const saveData = (url) => {
  var link = document.createElement("a");
  link.download = `subscription_order_${moment().format("dd_mm_yyyy")}.pdf`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDialCode = (code) => {
  const returnValue = dialCode?.find((o) => o.code === code)?.dial_code || "";
  return returnValue;
};
