import React from "react";
import { useState } from "react";
import LabelText from "../LabelText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./PasswordInput.scss";

const PasswordInput = ({
  placeholder,
  label,
  id,
  onChange,
  error,
  value,
  isPasswordShow,
  showInfo,
}) => {
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div id="password-input-container">
      {label && <LabelText label={label} />}
      <div className="password-input-block">
        <input
          id={id}
          type={passwordType}
          className="password-input"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          autoComplete="new-password"
        />
        {showInfo && (
          <div className="info-block">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2" className="my-in">
                  <ul className="text-14-500 color-black text-start">
                    <li style={{ listStyleType: "disc" }}>One Uppercase</li>
                    <li style={{ listStyleType: "disc" }}>One Lowercase</li>
                    <li style={{ listStyleType: "disc" }}>
                      One Speacial Character
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Minimum 6 Characters
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Maximum 16 Characters
                    </li>
                  </ul>
                </Tooltip>
              }
            >
              <i className="bi bi-info-circle" />
            </OverlayTrigger>
          </div>
        )}
        {isPasswordShow === 1 ? (
          <span className="eye-container" onClick={togglePassword}>
            {passwordType === "password" ? (
              <i className="hide-eye bi bi-eye-slash" />
            ) : (
              <i className="visible-eye bi bi-eye" />
            )}
          </span>
        ) : (
          ""
        )}
      </div>
      {error && <div className="text-12-400 color-carnation">{error}</div>}
    </div>
  );
};

export default PasswordInput;
