import Button from "components/form/Button";
import PasswordInput from "components/form/PasswordInput";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoginSignup, verifyCode } from "store/globalSlice";
import { icons } from "utils/constants";

const VerifyTwoFactor = ({ setPageType }) => {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handelSave = async () => {
    setIsLoading(true);
    const response = await dispatch(
      verifyCode({
        backupCode: otp,
      })
    );
    if (response?.status === 200) {
      localStorage.removeItem("isTwoFactorLogin");
      dispatch(setIsLoginSignup(false));
    }
    setIsLoading(false);
  };
  return (
    <div className="cmt-50">
      <div className="authen1-heading cmb-30 text-center">
        <div className="authen1-icon-container cmb-25">
          <img src={icons.iconMobile} alt="mobileicon" className="mobileicon" />
        </div>

        <h2 className="text-22-600 color-black cmb-5">
          Two-Factor Authentication
        </h2>
        <span className="text-14-400 color-dove-gray">Backup code</span>
      </div>
      <div className="cmb-25">
        <PasswordInput
          label={
            <div className="text-center mb-2">
              PLEASE ENTER ONE OF YOUR BACKUP CODES:
            </div>
          }
          placeholder="Code"
          value={otp}
          onChange={(e) => {
            setOTP(e.target.value.replace(/\D/g, ""));
          }}
        />
      </div>
      <div className="mb-2">
        <Button
          btnStyle="btn-primary-yellow"
          value="Confirm"
          onClick={handelSave}
          disabled={!otp || otp.length < 6 || isLoading}
          btnLoading={isLoading}
          hideAnimation
        />
      </div>
      <div
        className="text-14-600 color-dodger-blue pointer"
        onClick={() => {
          setPageType(3);
        }}
      >
        Back
      </div>
    </div>
  );
};
export default VerifyTwoFactor;
