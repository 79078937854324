import { useNavigate } from "react-router-dom";
import { icons } from "utils/constants";
import "./FlashCard.scss";

const FlashCard = () => {
  const history = useNavigate();
  const handelNavigate = (url) => {
    history(`/flash-card/${url}`);
  };

  const flashCard = [
    {
      title: "PRINCIPAL FUNCTIONS OF MANAGEMENT",
      subTitle: "MANAGEMENT",
      image: icons.FC1,
      url: "management",
    },
    {
      title: "PERIODIC TABLE OF THE ELEMENTS",
      subTitle: "CHEMISTRY",
      image: icons.FC2,
      url: "chemistry",
    },
    {
      title: "MATHEMATICAL FORMULAS",
      subTitle: "MATHEMATICS",
      image: icons.FC3,
      url: "mathematics",
    },
    {
      title: "NEW AGE TECHNOLOGIES",
      subTitle: "TECHNOLOGY",
      image: icons.FC4,
      url: "technology",
    },
    {
      title: "MECHATRONICS",
      subTitle: "ENGINEERING",
      image: icons.FC5,
      url: "engineering",
    },
    {
      title: "SOLAR SYSTEM",
      subTitle: "PLANETARY SCIENCE",
      image: icons.FC6,
      url: "planetary-science",
    },
  ];
  return (
    <div className="row" id="flash-card-container">
      {flashCard.map((elem, index) => {
        const { title, subTitle, image, url } = elem;
        return (
          <div className="col-xl-4 col-md-6 mb-4" key={index}>
            <div className="sec-4-box">
              <div className="card">
                <div className="card-img-top">
                  <img src={image} className="fill-image" alt="..." />
                </div>
                <div className="card-body">
                  <div className="">{title}</div>
                  <div
                    className="color-primary pointer"
                    onClick={() => {
                      handelNavigate(url);
                    }}
                  >
                    {subTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default FlashCard;
