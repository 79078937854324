import React from "react";
import { icons } from "utils/constants";
import "./LeftImage.scss";

const LeftImage = () => {
  return (
    <div className="left-col">
      <img src={icons.iconSingup} alt="signupImage" className="signupImage" />
      <span className="aboutImg1">Sign up to get more</span>
      <p className="aboutImg2">Answers for FREE!</p>
      <img src={icons.iconGradeA} alt="gradeImage" className="gradeImage" />
    </div>
  );
};

export default LeftImage;
