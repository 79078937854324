import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { omit } from "lodash";
import LeftImage from "pages/Frontend/LeftImage";
import PasswordInput from "components/form/PasswordInput";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/form/Button";
import { resetPassword, throwSuccess } from "store/globalSlice";
import "./ResetPassword.scss";
import { passwordError } from "utils/constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { token } = params;
  const formRef = useRef();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(
      resetPassword(token, omit(values, ["confirm_password"]))
    );
    if (response?.status === 200) {
      dispatch(
        throwSuccess("Your new password has been updated successfully.")
      );
      navigate("/");
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        passwordError
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .oneOf([Yup.ref("password"), null], "Password not matched."),
  });
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  return (
    <div className="reset-main-container">
      <LeftImage />
      <div className="reset-right-col">
        <div className="reset-right-content">
          <div className="reset-right-section cmt-25">
            <div className="reset-heading cmb-60">
              <h2 className="text-22-600 color-outer-space cmb-4">
                Create your password
              </h2>
              <span className="text-14-400 color-dove-gray">
                Enter your Help a Course email address you signed up with. We'll
                email you a link to log in and reset your password.
              </span>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handelSave}
              innerRef={formRef}
            >
              {(props) => {
                const { values, errors, handleChange, handleSubmit } = props;
                return (
                  <form>
                    <div className="cmb-25">
                      <PasswordInput
                        showInfo
                        label="Password"
                        placeholder="Enter Password"
                        id="password"
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="cmb-25">
                      <PasswordInput
                        label="Confirm Password"
                        placeholder="Enter Confirm Password"
                        id="confirm_password"
                        value={values.confirm_password}
                        error={errors.confirm_password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="">
                      <Button
                        hideAnimation
                        value="Create Password"
                        onClick={handleSubmit}
                        btnLoading={btnLoading}
                        className="cps-30 cpe-30"
                        btnStyle="btn-primary-yellow"
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
