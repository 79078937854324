import React from "react";
import { icons } from "utils/constants/icons";
import Slider from "react-slick";
import "./CarouselCard.scss";

const CarouselCard = () => {
  var settings = {
    className: "row",
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const flagArray = [
    {
      userImage: icons.Card1,
      description:
        "HelpACourse has been supporting my success since high school. flashcards available on the go are single-handedly getting me through university.”",
      subTitle: "Hamza, Senior, Medicine",
    },
    {
      userImage: icons.Card2,
      description:
        "“Learn mode is the best thing that’s ever come out of HelpACourse. it shows you terms in a way that makes it easiest to remember.”",
      subTitle: "Sydney, sophomore, biology",
    },
    {
      userImage: icons.Card3,
      description:
        "“All our friends use HelpACourse. it’s a fun way to study and we feel more confident preparing for our midterms and finals.”",
      subTitle: "Owen & Oscar, High School Sophomores",
    },
    {
      userImage: icons.Card1,
      description:
        "HelpACourse has been supporting my success since high school. flashcards available on the go are single-handedly getting me through university.”",
      subTitle: "Hamza, Senior, Medicine",
    },
    {
      userImage: icons.Card2,
      description:
        "“Learn mode is the best thing that’s ever come out of HelpACourse. it shows you terms in a way that makes it easiest to remember.”",
      subTitle: "Sydney, sophomore, biology",
    },
  ];
  return (
    <Slider {...settings}>
      {flagArray.map((elem, index) => {
        return (
          <div className="col-md-4 image-div-card p-3 h-100" key={index}>
            <div className="card card-v m-unset">
              <img
                src={elem.userImage}
                className="card-img-top card-slider-img"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title text-16-500 color-light-gray">
                  {elem.description}
                </h5>
                <p className="card-text text-16-500">
                  <b>{elem.subTitle}</b>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default CarouselCard;
