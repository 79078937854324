import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";
import moment from "moment";
import Button from "components/form/Button";
import Loader from "components/layouts/Loader";
import { icons } from "utils/constants";
import { getDialCode, objectToQueryParams, saveData } from "utils/helpers";
import {
  addBackupCode,
  getOrderDetails,
  getProfileData,
  setIsSubscription,
} from "store/globalSlice";
import Verify from "./Verify";
import CancelPopup from "components/layouts/CancelPopup";
import "./AccountOverview.scss";
const paymentType = {
  0: "Unpaid",
  1: "Paid",
  2: "Cancel",
};

const AccountOverview = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => ({
    userData: state.global.userData,
  }));
  const [show, setShow] = useState(false);
  const [isVerify, setIsVerify] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const {
    first_name,
    last_name,
    mobile_no,
    email,
    is_email_verify,
    is_mobile_verify,
    TwoFactors,
    country_code,
    Orders,
  } = userData || {};
  const isPayment = Orders?.[0]?.status === 1;
  const isCancel = Orders?.[0]?.status === 2;
  const { status, backup_code } = TwoFactors?.[0] || {};
  const [orderData, setOrderData] = useState({
    list: [],
    offset: 0,
    limit: 1,
    total: 0,
    loader: true,
  });
  const getOrderData = async (obj) => {
    const queryParams = `?${objectToQueryParams(
      omit(obj, ["list", "loader", "total"])
    )}`;
    const response = await dispatch(getOrderDetails(queryParams));
    setOrderData((prev) => {
      return {
        ...prev,
        list: response?.data?.rows || [],
        total: response?.data?.count,
        loader: false,
      };
    });
  };

  useEffect(() => {
    getOrderData(orderData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { list } = orderData;
  const plan = Orders?.[0] || {};
  const {
    id: orderID,
    subscriptionPlan,
    subscription_amount,
    createdAt,
    end_date,
    subscriptionPlan_id: planID,
  } = plan;

  const isCountryId = userData?.country_id === 101;

  return (
    <div id="profile-content-container">
      {isVerify && <Verify type={isVerify} setIsVerify={setIsVerify} />}
      {show && (
        <CancelPopup
          handleClose={() => {
            setShow(false);
          }}
          orderID={orderID}
        />
      )}
      <div className="profile-center-container row">
        <div className="userinfo-container cmb-25 cpt-22 cpb-22 cps-20">
          <p className="text-21-600 color-white  cmb-15">Hi, {first_name}</p>

          <span className="text-21-400 color-white">
            Welcome... Start your journey with us... HAC Offering Unbeatable
            Services..
          </span>
        </div>
        {is_email_verify === 0 && (
          <div className="email-verify-container cmb-15 cpt-17 cpb-16 cps-20">
            <span className="text-15-500 color-outer-space">
              <u
                className="pointer"
                onClick={() => {
                  setIsVerify(1);
                }}
              >
                Click here
              </u>{" "}
              to verify your email id
            </span>
          </div>
        )}
        {is_mobile_verify === 0 && (
          <div className="mobile-verify-container cmb-50 cpt-17 cpb-16 cps-20">
            <span className="text-15-500 color-outer-space">
              <u
                className="pointer"
                onClick={() => {
                  setIsVerify(2);
                }}
              >
                Click here
              </u>{" "}
              to verify your mobile number
            </span>
          </div>
        )}

        <div className="order-detail cmb-30">
          <span className="text-16-600 cpb-9">Orders</span>
          <table className="table border  cmt-9">
            <thead className="text-14-500 color-mine-shaft bg-dodger-gray text-center">
              <tr>
                <th>Sr. No.</th>
                <th>Order No.</th>
                <th>Order Date</th>
                <th>Subscription Plan</th>
                <th>Subscription Amount</th>
                <th>Status</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((elem, key) => {
                let { start_date, Subscription_plan, status, id, invoice } =
                  elem;
                return (
                  <tr
                    className="text-13-600 color-mine-shaft bg-white text-center"
                    key={key}
                  >
                    <td>1</td>
                    <td>{id}</td>
                    <td>
                      {start_date
                        ? moment(start_date).format("D MMM YYYY")
                        : ""}
                    </td>
                    <td>{Subscription_plan?.category}</td>
                    <td>
                      {isCountryId ? "₹" : "$"}
                      {Subscription_plan?.amount}
                    </td>
                    <td>
                      <div
                        className={`${
                          status === 1 ? "paid-text" : "unpaid-text"
                        } `}
                      >
                        {paymentType[status]}
                      </div>
                    </td>
                    <td>
                      <div
                        className={`pointer ${
                          status === 1 ? "download-text" : "pay-text"
                        }`}
                        onClick={() => {
                          if (status === 0) {
                            history(
                              `/subscription-payment/${id}/${Subscription_plan.id}`
                            );
                          } else {
                            saveData(invoice);
                          }
                        }}
                      >
                        {`${status === 0 ? "Pay" : "Download"} `}
                      </div>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td
                  className="text-14-500 bg-white cpt-11 cpb-12 cps-20"
                  colSpan="7"
                >
                  {list.length === 0 ? (
                    <div
                      className="pointer text-center pt-3 pb-3"
                      onClick={() => {
                        history("/my-profile/orders");
                      }}
                    >
                      No Data Found
                    </div>
                  ) : (
                    <div
                      className="pointer button-animation w-fit color-dodger-blue"
                      onClick={() => {
                        history("/my-profile/orders");
                      }}
                    >
                      View All
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-6 d-flex flex-column cmb-30">
          <div className="text-16-600">Security</div>
          <div className="security-inner-container cmt-9 border rounded cps-20 cpe-23 cpt-10 cpb-16 flex-grow-1">
            <div>
              <div
                className="text-13-600 color-dodger-blue cmb-26 pointer button-animation w-fit"
                onClick={() => {
                  history("/my-profile/security");
                }}
              >
                Change Password
              </div>
              {backup_code ? (
                <>
                  {isLoader ? (
                    <div className="w-fit cps-30 cpe-30">
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="text-13-600 color-dodger-blue pointer button-animation w-fit"
                      onClick={async () => {
                        setIsLoader(true);
                        let payload = {
                          status: status === 0 ? 1 : 0,
                        };
                        const response = await dispatch(addBackupCode(payload));
                        if (response?.status === 200) {
                          await dispatch(getProfileData());
                        }
                        setIsLoader(false);
                      }}
                    >
                      {`${!status ? "Enable" : "Disable"} 2FA`}
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="text-13-600 color-dodger-blue pointer button-animation w-fit"
                  onClick={() => {
                    history("/my-profile/security");
                  }}
                >
                  Setup 2FA
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column cmb-30">
          <div className="text-16-600">Billing</div>
          <div className="security-inner-container cmt-9 border rounded cps-20 cpe-23 cpt-10 cpb-16 flex-grow-1">
            <div className="text-12-400 color-dove-gray cmb-5">
              {planID
                ? isCancel
                  ? `Cancels on ${moment(end_date).format("DD MMM YYYY")}`
                  : isPayment
                  ? `Subscription will end on ${moment(end_date).format(
                      "DD MMM YYYY"
                    )}`
                  : `Plan selected on ${moment(createdAt).format(
                      "DD MMM YYYY"
                    )}`
                : "Please subscribe fot batter experience."}
            </div>
            <div className="d-flex">
              <div className="text-32-700 color-haiti">
                {planID && `${isCountryId ? "₹" : "$"}${subscription_amount}`}
              </div>
              <div className="cps-10 cmb-6">
                <div className="text-12-500 color-mine-light-shaft">
                  {subscriptionPlan}
                </div>
                {planID && (
                  <div className="text-9-500 color-dove-gray">Subscription</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5"
                btnStyle="btn-primary-yellow"
                value={
                  planID
                    ? isCancel
                      ? "Subscribe"
                      : isPayment
                      ? "Cancel"
                      : "Pay Subscription"
                    : "Subscribe"
                }
                onClick={() => {
                  if (planID) {
                    if (isCancel) {
                      dispatch(setIsSubscription(true));
                    } else if (isPayment) {
                      setShow(true);
                    } else {
                      history(`/subscription-payment/${orderID}/${planID}`);
                    }
                  } else {
                    dispatch(setIsSubscription(true));
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column cmb-30">
          <div className="text-16-600">Profile Info</div>
          <div className="security-inner-container cmt-9 border rounded cps-20 cpe-23 cpt-10 cpb-16 flex-grow-1">
            <div className="text-14-500 color-mine-shaft cmb-9">{`${first_name} ${last_name}`}</div>

            <div className="text-12-400 color-mine-shaft d-flex cmb-3">
              {`${
                country_code ? `(${getDialCode(country_code)})` : ""
              } ${mobile_no}`}
            </div>
            <div className="text-12-400 color-mine-shaft d-flex">{email}</div>

            <div
              className="text-13-600 color-dodger-blue d-flex justify-content-end pointer"
              onClick={() => {
                history("/my-profile/profile-info");
              }}
            >
              <div className="w-fit button-animation">
                Update Profile
                <img
                  className="cms-5"
                  src={icons.iconeRightArrow}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column cmb-30">
          <div className="text-16-600">Support</div>
          <div className="security-inner-container cmt-9 border rounded cps-20 cpe-23 cpt-10 cpb-16 flex-grow-1">
            <div className="text-14-500 color-mine-shaft cmb-50">
              How can we help you?
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5"
                value="Contact Us"
                btnStyle="btn-primary-yellow"
                onClick={() => {
                  history("/contact-us");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountOverview;
