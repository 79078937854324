import Footer from "./Footer";
import Header from "./Header";
import "./Layouts.scss";

const Layout = ({ children, id, hideContainer }) => {
  return (
    <div id="Layout-container">
      <Header />
      <main className="main-body-content">
        <div
          id={id}
          className={`body-padding body-height ${
            hideContainer ? "" : "container"
          }`}
        >
          {children}
        </div>
        <Footer />
      </main>
    </div>
  );
};
export default Layout;
