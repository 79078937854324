import Button from "react-bootstrap/Button";
import FlashCard from "components/layouts/FlashCard";
import Layout from "pages/Layouts";
import { icons } from "utils/constants/icons";
import CarouselCard from "./CarouselCard";
import FlagImage from "./FlagImage";
import CommonHelmet from "components/layouts/CommonHelmet";
import { getDataFromLocalStorage } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoginSignup } from "store/globalSlice";
import "./Home.scss";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { userState } = useSelector((state) => ({
    userState: state.global.userState,
  }));

  const isLogin = getDataFromLocalStorage("token") ? false : true;
  return (
    <Layout id="home-container" hideContainer isLogin>
      <CommonHelmet />
      <div className="banner slideInUp">
        <div className="banner-text">
          <p className="text-20-500 slideInRight banner-text-color">
            Welcome to HelpACourse
          </p>
          <p className="text-60-700 slideInRight w-50">
            We open the door, but you must enter by yourself.
          </p>
          <p className="text-18-500 slideInRight w-50 banner-text-color">
            HelpACourse is well aware of your requirements and deadlines. Our
            community of experts worldwide just waiting to provide homework
            help, exam prep and writing support for your full classload on time.
          </p>
          {isLogin ? (
            <div className="signUpBtn slideInLeft">
              <Button
                variant="primary"
                className="text-20-700"
                size="lg"
                onClick={() => {
                  localStorage.isSignup = 1;
                  dispatch(setIsLoginSignup(true));
                }}
              >
                Sign Up
                <img
                  className="ms-3"
                  src={icons.RightArrow}
                  alt="right-arrow"
                />
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="section-2">
        <div className="title text-35-700">Life is easier with e-Books</div>
        <div className="d-flex justify-content-center">
          <img src={icons.underLineBorder} alt="underline-border" />
        </div>
        <div className="container pt-5">
          <div className="row section-2-banner">
            <div className="col-md-6 border-right-div image-div">
              <div className="banner-image-1"></div>

              <div className="section-1-text">
                <p className="text-28-700">Access to over 400,000 titles</p>
                <p className="color-light-gray">
                  Find and access your eBook easily on your device. Our eBooks
                  are compatible with Android, iPhone, PC, iPad, and others.
                </p>
              </div>
            </div>

            <div className="col-md-6 image-div">
              <div className="banner-image-2"></div>
              <div className="section-1-text">
                <p className="text-28-700">Ebook rentals for as low as $4.99</p>
                <p className="color-light-gray">
                  Rent your eBook for as low as $4.99. Plus, enjoy a 10-day
                  refund guarantee.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 button">
        <div className="container pt-2">
          <div className="row ">
            <div className="col-md-6 d-flex justify-content-end pt-3 sec-2-buttons">
              {isLogin && (
                <Button
                  variant="primary"
                  className="text-20-700"
                  size="lg"
                  onClick={() => {
                    dispatch(setIsLoginSignup(true));
                  }}
                >
                  Join for Free{" "}
                </Button>
              )}
            </div>

            <div className="col-md-6 sec-2-buttons sec-2-buttons pt-3">
              <Button
                variant=""
                className="text-20-600"
                size="lg"
                onClick={() => {
                  history("/book");
                }}
              >
                Find more books
                <b>
                  <i className="ms-2 bi bi-arrow-right"></i>
                </b>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-3 section-2">
        <div className="title text-35-700">
          Tap into the brainpower of <br /> thousands of experts worldwide
        </div>
        <div className="d-flex justify-content-center">
          <img src={icons.underLineBorder} alt="underline-border" />
        </div>
        <div className="container pt-5">
          <FlagImage />
        </div>
      </div>
      <div className="sec-4 section-2">
        <div className="title text-32-700-48">
          Versatile Learning Strategy through visual, <br /> tactile or
          kinaesthetic means.
        </div>
        <div className="d-flex justify-content-center">
          <img src={icons.underLineBorder} alt="underline-border" />
        </div>
        <div className="container mt-5">
          <div className="text-28-700 color-outer-space mb-2">Flash Cards</div>
          <FlashCard />
          <div className="text-center">
            <div className="color-black-shade text-20-700 cmb-20">
              Search our archive of millions of flashcard decks
            </div>
            {isLogin ? (
              <div>
                <Button
                  variant="primary"
                  className="text-20-700"
                  size="lg"
                  onClick={() => {
                    dispatch(setIsLoginSignup(true));
                  }}
                >
                  Sign Up
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="sec-5 section-2">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-md-6">
              <img src={icons.SEC5} className="image-div-sec-5" alt="sec-5" />
            </div>

            <div className="col-md-6">
              <div className="section-1-text">
                <p className="text-15-500 color-light-gray">
                  40 Years Glory of Success
                </p>
                <p className="text-32-700 color-black-shade">
                  From School to University, Smash on Subject.
                </p>
                <p className="color-light-gray text-20-400">
                  Journey from school to university is a unique experience for
                  every student because of varying personal interests and access
                  to resources. Learning happens beyond the books, perform
                  Independent Research & complete the assignments. HelpACourse
                  is here to provide single source when you need tips in
                  navigating transition successfully.
                </p>

                {isLogin ? (
                  <>
                    <p className="color-black-shade text-20-700">
                      Ready to start getting better results ??
                    </p>
                    <div className="signUpBtn">
                      <Button
                        variant="primary"
                        className="text-20-700"
                        size="lg"
                        onClick={() => {
                          dispatch(setIsLoginSignup(true));
                        }}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-6 section-2 cmb-130">
        <div className="title text-35-700">
          What our Students says about HAC...
        </div>
        <div className="d-flex justify-content-center">
          <img src={icons.underLineBorder} alt="underline-border" />
        </div>

        <div className="container pt-5 pb-5">
          <CarouselCard />
        </div>
      </div>
    </Layout>
  );
};
export default Home;
