import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export const encrypt = (data) => {
  const phrase = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(phrase, ENCRYPTION_KEY);
  const returnData = encrypted.toString();
  return returnData;
};

export const decrypt = (data) => {
  const decrypted = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
  const deCpmoressed = decrypted.toString(CryptoJS.enc.Utf8);
  let returnData = {};
  if (deCpmoressed) {
    returnData = JSON.parse(deCpmoressed);
  }
  return returnData;
};

export function convertEncryptResponse(data) {
  let returnResponse = {};
  if (data) {
    returnResponse = data;
  }
  return { data: encrypt(returnResponse) };
}
