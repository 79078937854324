import Layout from "pages/Layouts/Layouts";
import { useNavigate } from "react-router-dom/dist";
import "./PageNotFound.scss";

const PageNotFound = () => {
  const history = useNavigate();
  return (
    <Layout id="page-not-found-container">
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="main">
        <h1>404</h1>
        <p>
          It looks like you're lost...
          <br /> That's a trouble?
        </p>
        <button
          type="button"
          onClick={() => {
            history("/");
          }}
        >
          Go back to homepage
        </button>
      </div>
    </Layout>
  );
};
export default PageNotFound;
