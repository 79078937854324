import React from "react";
import "./CheckBox.scss";

const CheckBox = ({ label, onClick, checked }) => {
  return (
    <div id="checkbox-container">
      <input
        type="checkbox"
        className="input-checkbox"
        onClick={onClick}
        checked={checked}
        onChange={() => {}}
      />
      <span className="text-14-400">{label}</span>
    </div>
  );
};

export default CheckBox;
