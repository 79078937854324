import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginSocialFacebook } from "reactjs-social-login";
import { socialLogin, throwError } from "store/globalSlice";
import { icons } from "utils/constants";
import { get2FAData, objectToFormData } from "utils/helpers";
import Loader from "../Loader";

const FacebookButton = ({ handelSuccess, className }) => {
  const dispatch = useDispatch();

  const [isLoad, setIsLoad] = useState(false);
  const handelSave = async ({ data }) => {
    setIsLoad(true);
    const { first_name, last_name, email, id, picture } = data;
    const payload = {
      email: email || "",
      first_name: first_name || "",
      last_name: last_name || "",
      facebookId: id || "",
      profile_Image: picture?.data?.url || "",
      user_type: 2,
      is_email_verify: 1,
    };
    const res = await get2FAData({
      email: email,
      name: "HelpACourse",
    });
    let qrCodeData = {};
    if (res?.status === 200) {
      qrCodeData = {
        QrCode: res?.data?.imageData || "",
        secret: res?.data?.secretCode || "",
      };
    } else {
      dispatch(throwError(res.message));
    }
    const response = await dispatch(
      socialLogin(objectToFormData({ ...payload, ...qrCodeData }))
    );
    if (response?.status === 200 || response?.status === 201) {
      if (handelSuccess) {
        handelSuccess();
      }
    }
    setIsLoad(false);
  };
  return (
    <LoginSocialFacebook
      appId={process.env.REACT_APP_FACEBOOK_KEY}
      onResolve={handelSave}
      onReject={(error) => {
        setIsLoad(false);
        console.log(error);
      }}
    >
      <div className={`s-button ${className || ""}`}>
        <span>
          <img
            className="img-icon me-2"
            src={icons.iconFacebook}
            alt="iconFacebook"
          />
        </span>
        <span className="pt-1">Continue with Facebook</span>
        <span className="ms-3">{isLoad && <Loader size="sm" />}</span>
      </div>
    </LoginSocialFacebook>
  );
};
export default FacebookButton;
