import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import Button from "components/form/Button";
import CardInformation from "components/form/CardInformation";
import Layout from "pages/Layouts";
import {
  throwError,
  confirmPayment,
  unsuccessfullPayment,
  addClientSceret,
  getProfileData,
  throwSuccess,
} from "store/globalSlice";
import {
  createCardToken,
  // deleteCard,
  // getAllCard,
  getDataFromLocalStorage,
  linkCardWithAccount,
  setAsPrimaryCard,
} from "utils/helpers";
import { commonRoute, stripeError } from "utils/constants";
import BillingInformation from "components/form/BillingInformation/BillingInformation";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

const Payment = () => {
  const stripe = useStripe();
  const { siteData, subscriptionList, userData } = useSelector((state) => ({
    siteData: state.global.siteData,
    subscriptionList: state.global.subscriptionList,
    userData: state.global.userData,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id, orderID } = params;
  const { customer_id } = getDataFromLocalStorage() || {};

  let selectedPlan = subscriptionList?.find((o) => o.id === +id) || {};
  const { amount } = selectedPlan;
  const [btnLoading, setBtnLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState({});
  const [billingDetails, setBillingDetails] = useState({});
  // const [isDataLoader, setIsDataLoader] = useState(true);
  const [alertPopup, setAlertPopup] = useState(true);

  useEffect(() => {
    setBillingDetails({
      email: userData?.email || "",
      mobile_no: userData?.mobile_no || "",
      isMobError: userData?.isMobError || "",
      country_code: userData?.country_code || "IN",
      country_id: userData?.country_id || "",
      country: userData?.country?.country || "",
      code: userData?.country?.code || "",
      state_id: userData?.state_id || "",
      state: userData?.state?.state || "",
      city_id: userData?.city_id || "",
      city: userData?.city?.city || "",
      zip: userData?.zip || "",
      mailing_address: userData?.mailing_address || "",
      address_line_two: userData?.address_line_two || "",
    });
  }, [userData]);

  // const state_name = userData?.state?.state;
  // const country_name = userData?.country?.country;

  const handelPay = async () => {
    if (!siteData?.secret_key) {
      dispatch(throwError(stripeError));
      return;
    }

    setBtnLoading(true);
    const {
      // email,
      // mobile_no,
      // isMobError,
      // country_code,
      // country_id,
      country,
      code,
      // state_id,
      state,
      // city_id,
      city,
      zip,
      mailing_address,
      address_line_two,
    } = billingDetails;
    // const response = await getAllCard(customer_id, siteData?.secret_key);
    // response?.data?.forEach(async (elm) => {
    //   console.log("elm", elm);
    //   await deleteCard(elm.id, customer_id, siteData?.secret_key);
    // });
    const clientResponse = await dispatch(
      addClientSceret({
        order_id: orderID,
        state: state,
        country: country,
      })
    );
    let clientSecret = clientResponse?.client_secret || "";
    const { name, card_number, month, year, cvv } = cardData;

    const cardObject = {
      card: {
        number: card_number,
        exp_month: month,
        exp_year: year,
        cvc: cvv,
        name: name,
        address_country: code,
        address_state: state,
        address_city: city,
        address_zip: zip,
        address_line1: mailing_address,
        address_line2: address_line_two,
      },
    };

    if (!siteData?.publishable_key) {
      dispatch(throwError(stripeError));
      return;
    }
    let cardResponse = await createCardToken(
      cardObject,
      siteData?.publishable_key
    );
    if (cardResponse?.id) {
      if (!siteData?.secret_key) {
        dispatch(throwError(stripeError));
        setBtnLoading(false);
        return;
      }
      const linkCardResponse = await linkCardWithAccount(
        cardResponse?.id,
        customer_id,
        siteData?.secret_key
      );
      if (linkCardResponse?.id) {
        await setAsPrimaryCard(
          linkCardResponse?.id,
          customer_id,
          siteData?.secret_key
        );
      }
    } else {
      dispatch(throwError(cardResponse?.error?.message));
      setBtnLoading(false);
      return;
    }
    cardResponse = await createCardToken(cardObject, siteData?.publishable_key);
    const billings_values = {
      address: {
        city: city,
        country: code,
        line1: mailing_address,
        line2: address_line_two,
        postal_code: zip,
        state: state,
      },
      // email: email,
      // name: `${first_name} ${last_name}`,
      name: "Qwerty",
      email: "test@mailinator.com",
      // phone: mobile_no,
    };
    const payRes = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: { token: cardResponse?.id },
        // billing_details: {
        //   name: `${first_name} ${last_name}`,
        // },
        billing_details: billings_values,
      },
    });

    if (payRes?.error) {
      dispatch(throwError(payRes?.error?.message));
      await dispatch(
        unsuccessfullPayment({
          client_secret: clientSecret,
          status: 3,
          errorMessage: payRes?.error?.message || "Something went wrong!",
        })
      );
    } else {
      const sResponse = await dispatch(
        confirmPayment({
          client_secret: clientSecret,
          status: 1,
        })
      );
      if (sResponse?.status === 200) {
        dispatch(
          throwSuccess("Payment for this subscription done successfully.")
        );
        setTimeout(async () => {
          navigate(commonRoute.myProfile);
          await dispatch(getProfileData());
        }, 2000);
      }
    }
    setBtnLoading(false);
  };

  let isBtnDisabled =
    !cardData?.name ||
    !cardData?.card_number ||
    !cardData?.cvv ||
    !cardData?.idValidDate ||
    !cardData?.isValidNumber ||
    !billingDetails?.state_id ||
    !billingDetails?.city_id ||
    !billingDetails?.zip ||
    !billingDetails?.mailing_address;
  // billingDetails?.isMobError;

  const isBillingContent = userData?.country_id === 101;
  // if (isBillingContent) {
  //   isBtnDisabled =
  //     !cardData?.name ||
  //     !cardData?.card_number ||
  //     !cardData?.cvv ||
  //     !cardData?.idValidDate ||
  //     !cardData?.isValidNumber ||
  //     !billingDetails?.state_id ||
  //     !billingDetails?.city_id ||
  //     !billingDetails?.zip ||
  //     !billingDetails?.mailing_address;
  // }
  useEffect(() => {
    setAlertPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("billingDetails", billingDetails);
  return (
    <Layout>
      {alertPopup && (
        <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <Modal.Title className="text-17-700 d-flex justify-content-center cmb-22">
              Alert
            </Modal.Title>

            <div className="d-flex gap-2 cmb-10">
              <span className="color-dashboard-primary text-16-600">
                To complete your transaction, kindly utilize a payment card that
                has been issued by the country you have selected.
              </span>
            </div>

            <div className="d-flex justify-content-center gap-2">
              <Button
                className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5 color-dahsboard-primary text-16-600"
                btnStyle="btn-primary-yellow"
                value="Ok"
                onClick={() => {
                  setAlertPopup(false);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="d-flex justify-content-center">
        <div
          className={`cpt-130 cpb-130 d-flex gap-5 ${
            window.innerWidth > 900 && "w-100 ps-3 pe-3"
          }`}
        >
          <div className="col-md-6">
            <div className="text-22-600 color-outer-space cmb-20">
              Billing Address
            </div>

            <div className="cmb-20">
              <BillingInformation
                billingDetails={billingDetails}
                getBillingDetails={(data) => {
                  setBillingDetails(data);
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="text-22-600 color-outer-space cmb-20">
              Card Details
            </div>

            <div className="cmb-25">
              <CardInformation
                label="CARD INFORMATION"
                getCardDetails={(data) => {
                  setCardData(data);
                }}
              />
            </div>
            <Button
              hideAnimation
              btnStyle="btn-primary-yellow"
              value={`Pay ${
                amount ? `(${isBillingContent ? "₹" : "$"}${amount})` : ""
              }`}
              className="cps-30 cpe-30"
              onClick={handelPay}
              disabled={isBtnDisabled}
              btnLoading={btnLoading}
            />
          </div>
        </div>
        {/* <div
            className={
              isBillingContent
                ? `cpt-130 cpb-130 d-flex gap-5 ${
                    window.innerWidth > 900 && "w-100 ps-3 pe-3"
                  }`
                : `cpt-130 cpb-130 ${
                    window.innerWidth > 900 ? "w-50" : "w-100 ps-3 pe-3"
                  }`
            }
          >
            {isBillingContent && (
              <div className="col-md-6">
                <div className="text-22-600 color-outer-space cmb-20">
                  Billing Address
                </div>

                <div className="cmb-20">
                  <BillingInformation
                    billingDetails={billingDetails}
                    getBillingDetails={(data) => {
                      setBillingDetails(data);
                    }}
                  />
                </div>
              </div>
            )}

            <div className={isBillingContent && "col-md-6"}>
              <div className="text-22-600 color-outer-space cmb-20">
                Card Details
              </div>

              <div className="cmb-25">
                <CardInformation
                  label="CARD INFORMATION"
                  getCardDetails={(data) => {
                    setCardData(data);
                  }}
                />
              </div>
              <Button
                hideAnimation
                btnStyle="btn-primary-yellow"
                value={`Pay ${
                  amount ? `(${isBillingContent ? "₹" : "$"}${amount})` : ""
                }`}
                className="cps-30 cpe-30"
                onClick={handelPay}
                disabled={isBtnDisabled}
                btnLoading={btnLoading}
              />
            </div>
          </div> */}
      </div>
    </Layout>
  );
};
export default Payment;
