import LabelText from "../LabelText";
import "./TextInput.scss";

const TextInput = ({
  placeholder,
  label,
  id,
  onChange,
  onBlur,
  error,
  value,
  required,
  disabled,
  isVerify,
}) => {
  return (
    <div id="text-input-container">
      {label && <LabelText label={label} required={required} />}
      <div className="position-relative">
        <input
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          type="text"
          placeholder={placeholder}
          className="text-input text-14-500 color-nobel"
          value={value}
          disabled={disabled}
        />
        {isVerify && (
          <div className="verify-icon">
            <i className="bi bi-check-circle-fill text-success d-flex" />
          </div>
        )}
      </div>
      {error && <div className="text-12-400 color-carnation">{error}</div>}
    </div>
  );
};

export default TextInput;
