export const countriesMasks = {
  af: "..-...-....",
  al: "(...)...-...",
  dz: "..-...-....",
  ad: "...-...",
  ao: "(...)...-...",
  ag: "...-....",
  am: "..-...-...",
  aw: "...-....",
  at: "(...)...-....",
  bs: "...-....",
  bh: "....-....",
  bd: "..-...-...",
  bb: "...-....",
  bz: "...-....",
  bj: "..-..-....",
  bt: ".-...-...",
  bo: ".-...-....",
  ba: "..-.....",
  bw: "..-...-...",
  io: "...-....",
  bn: "...-....",
  bg: "(...)...-...",
  bf: "..-..-....",
  bi: "..-..-....",
  kh: "..-...-...",
  cm: "....-....",
  cv: "(...)..-..",
  bq: "...-....-.",
  cf: "..-..-....",
  td: "..-..-..-..",
  cl: ".-....-....",
  km: "..-.....",
  cd: "(...)...-...",
  cg: "..-...-....",
  hr: "..-...-...",
  cu: ".-...-....",
  cw: "...-....",
  cz: "(...)...-...",
  dj: "..-..-..-..",
  dm: "...-....",
  do: "...-....",
  ec: "..-...-....",
  eg: "(...)...-....",
  gq: "..-...-....",
  er: ".-...-...",
  et: "..-...-....",
  fj: "..-.....",
  gf: ".....-....",
  pf: "..-..-..",
  ga: ".-..-..-..",
  gm: "(...)..-..",
  ge: "(...)...-...",
  gh: "(...)...-...",
  gr: "(...)...-....",
  gd: "...-....",
  gp: "....-...-...",
  gu: "...-....",
  gn: "..-...-...",
  gw: ".-......",
  gy: "...-....",
  hn: "....-....",
  hu: "(...)...-...",
  id: "..-...-...",
  iq: "(...)...-....",
  jm: "...-....",
  jo: ".-....-....",
  ke: "...-......",
  ki: "..-...",
  xk: "..-...-...",
  kw: "....-....",
  kg: "(...)...-...",
  la: "..-...-...",
  lv: "..-...-...",
  lb: "..-...-...",
  ls: ".-...-....",
  lr: "..-...-...",
  ly: "..-...-...",
  li: "(...)...-....",
  lt: "(...)..-...",
  lu: "(...)...-...",
  mo: "....-....",
  mk: "..-...-...",
  mg: "..-..-.....",
  mw: ".-....-....",
  mv: "...-....",
  ml: "..-..-....",
  mt: "....-....",
  mh: "...-....",
  mq: "(...)..-..-..",
  mr: "..-..-....",
  mu: "...-....",
  fm: "...-....",
  mc: "(...)...-...",
  mn: "..-..-....",
  me: "..-...-...",
  ma: "..-....-...",
  mz: "..-...-...",
  mm: "..-...-...",
  na: "..-...-....",
  nr: "...-....",
  np: "..-...-...",
  nc: "..-....",
  ni: "....-....",
  ne: "..-..-....",
  ng: "(...)...-....",
  kp: "....-....",
  om: "..-...-...",
  pw: "...-....",
  ps: "..-...-....",
  pa: "...-....",
  pg: "(...)..-...",
  py: "(...)...-...",
  pe: "(...)...-...",
  pt: "..-...-....",
  pr: ".-(...)...-....",
  qa: "....-....",
  re: ".....-....",
  ro: "..-...-....",
  rw: "(...)...-...",
  kn: "...-....",
  lc: "...-....",
  vc: "...-....",
  ws: "..-....",
  sm: "....-......",
  st: "..-.....",
  sa: ".-...-....",
  sn: "..-...-....",
  rs: "..-...-....",
  sc: ".-...-...",
  sl: "..-......",
  sk: "(...)...-...",
  si: "..-...-...",
  sb: "...-....",
  so: "..-...-...",
  za: "..-...-....",
  ss: "..-...-....",
  lk: "..-...-....",
  sd: "..-...-....",
  sr: "...-....",
  sz: "..-..-....",
  sy: "..-....-...",
  tw: "....-....",
  tj: "..-...-....",
  tz: "..-...-....",
  th: "..-...-....",
  tl: "...-....",
  tg: "..-...-...",
  to: ".....",
  tt: "...-....",
  tn: "..-...-...",
  tm: ".-...-....",
  tv: "....",
  ug: "(...)...-...",
  ae: ".-...-....",
  uy: ".-...-..-..",
  uz: "..-...-....",
  vu: "..-.....",
  ve: "(...)...-....",
  vn: "(...)....-...",
  ye: "...-...-...",
  zm: "..-...-....",
  zw: ".-......",
  as: "...-....",
  ai: "...-....",
  bm: "...-....",
  vg: "...-....",
  ky: "...-....",
  ck: "..-...",
  fk: ".....",
  fo: "...-...",
  gi: "...-.....",
  gl: "..-..-..",
  ms: "...-....",
  nu: "....",
  nf: "..-...",
  mp: "...-....",
  bl: "..-..-..-..",
  sh: "....",
  mf: "...-...-...",
  pm: "...-...",
  sx: "...-....",
  tk: "....",
  tc: "...-....",
  vi: "...-....",
  wf: "..-....",
};

export const passwordError =
  "Please set a strong password with (One Uppercase, One Lowercase, One Special Character, Minimum 6 Character Maximum 16 Character)";
export const stripeError = "Payment setup not complete yet.";
