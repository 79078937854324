import { configureStore } from "@reduxjs/toolkit";
// import authSlice from "./slices/authSlice";
import globalSlice from "./globalSlice";
// import adminSlice from "./slices/adminSlice";
// import studentSlice from "./slices/studentSlice";
// import chatSlice from "./slices/chatSlice";

const store = configureStore({
  reducer: {
    global: globalSlice,
  },
});

export default store;
