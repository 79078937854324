import "./LabelText.scss";

const LabelText = ({ label, required }) => {
  return (
    <div id="label-text-container">
      <label className="label-text text-14-500 color-outer-space w-100">
        {label}
        {required && <span className="color-cherry-red">*</span>}
      </label>
    </div>
  );
};

export default LabelText;
