import "./Button.scss";

const Button = ({
  value,
  onClick,
  type,
  className,
  btnStyle,
  hideAnimation,
  btnLoading,
  loaderClass,
  disabled,
}) => {
  return (
    <div id="button-container">
      <button
        type={type || "button"}
        disabled={disabled}
        className={`button-submit ${className || ""} ${btnStyle} ${
          hideAnimation ? "" : "button-animation"
        }`}
        onClick={onClick}
      >
        {value}
        {btnLoading && (
          <div
            className={`spinner-border spinner-border-sm ms-3 ${
              loaderClass ? loaderClass : ""
            }`}
            role="status"
          />
        )}
      </button>
    </div>
  );
};

export default Button;
