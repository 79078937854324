import { useState } from "react";
import creditCardType from "credit-card-type";
import LabelText from "../LabelText";
import TextInput from "../TextInput";
import { icons } from "utils/constants";
import { formatExpireDate, trimLeftSpace } from "utils/helpers";
import "./CardInformation.scss";

const CardInformation = ({ label, getCardDetails }) => {
  const [cardData, setCardData] = useState({
    name: "",
    card_number: "",
    card_type: "",
    card_type_image: "",
    isValidNumber: false,
    keyCode: "",
    date: "",
    cvv: "",
  });
  const getCardType = (number) => {
    let card_type_data = creditCardType(number);
    let card_type = card_type_data[0] ? card_type_data[0].type : "";
    return card_type;
  };
  const getCardTypeImage = (cardType) => {
    switch (cardType) {
      case "visa":
        return icons.stp_card_visa_C;
      case "american-express":
        return icons.stp_card_amex_C;
      case "mastercard":
        return icons.stp_card_master_card_C;
      case "discover":
        return icons.stp_card_discover_C;
      case "diners-club":
        return icons.stp_card_diners_C;
      case "maestro":
        return icons.stp_card_maestro_card_C;
      case "jcb":
        return icons.stp_card_jcb_C;
      case "unionpay":
        return icons.stp_card_union_pay_C;
      default:
        return icons.stp_card_error_C;
    }
  };
  const validateCardnumber = (inputNum) => {
    var digit, digits, flag, sum, _i, _len;
    flag = true;
    sum = 0;
    digits = (inputNum + "").split("").reverse();
    for (_i = 0, _len = digits.length; _i < _len; _i++) {
      digit = digits[_i];
      digit = parseInt(digit, 10);
      if ((flag = !flag)) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  };
  const handelCardChange = (e) => {
    let value = e.target.value;
    let keyCode = cardData.keyCode;
    let cardType = "";
    let isValidNumber = false;
    let length = value.length;
    if (keyCode === 46 || length > 19) {
      return false;
    }
    cardType = getCardType(value.replace(/\s/g, ""));
    let cardTypeImage = getCardTypeImage(cardType);
    isValidNumber = validateCardnumber(value.replace(/\s/g, ""));
    setCardData((prev) => {
      return {
        ...prev,
        card_type: cardType,
        card_type_image: cardTypeImage,
        isValidNumber: isValidNumber,
      };
    });
    if (keyCode !== 8) {
      let re = /^\d+$/;
      let space = /\s/g;

      if (!value.charAt(value.length - 1).match(re)) {
        return false;
      }
      if (keyCode === 86) {
        value =
          value.slice(0, 4) +
          " " +
          value.slice(4, 8) +
          " " +
          value.slice(8, 12) +
          " " +
          value.slice(12);
      } else {
        if (value.length >= 15) {
          if (!value.charAt(14).match(space)) {
            value = value.slice(0, 14) + " " + value.slice(14);
          }
          cardData.card_number = value;
        }
        if (value.length >= 10) {
          if (!value.charAt(9).match(space)) {
            value = value.slice(0, 9) + " " + value.slice(9);
          }
          cardData.card_number = value;
        }
        if (value.length >= 5) {
          if (!value.charAt(4).match(space)) {
            value = value.slice(0, 4) + " " + value.slice(4);
          }
          cardData.card_number = value;
        }
      }
    }
    setCardData((prev) => {
      return {
        ...prev,
        card_number: value,
      };
    });
  };
  const onKeyDown = (e) => {
    setCardData((prev) => {
      return { ...prev, keyCode: e.keyCode };
    });
  };
  const handelBlur = () => {
    let newData = cardData;
    const { card_number, date } = newData;
    let month = "";
    let year = "";
    if (date && date.includes("/")) {
      month = date?.split("/")[0];
      year = `20${date?.split("/")[1]}`;
    }
    let idValidDate = date.length < 5 ? false : true;
    getCardDetails({
      ...newData,
      month: month,
      year: year,
      idValidDate: idValidDate,
      card_number: card_number.replace(/\s/g, ""),
    });
  };

  return (
    <div id="card-information-container">
      <div className="cmb-25">
        <TextInput
          required
          placeholder="Name On Card"
          label="NAME ON CARD"
          value={cardData.name}
          id="name"
          onBlur={handelBlur}
          onChange={(e) => {
            setCardData((prev) => {
              return {
                ...prev,
                name: trimLeftSpace(e.target.value.toUpperCase()),
              };
            });
          }}
        />
      </div>
      {label && <LabelText label={label} required />}
      <div className="card-input-block">
        <div className="card-number-block">
          <input
            type="text"
            placeholder="xxxx xxxx xxxx xxxx"
            onChange={handelCardChange}
            onKeyDown={onKeyDown}
            value={cardData.card_number}
            onBlur={handelBlur}
          />
          {cardData.card_type_image && (
            <div className="card-type-block">
              <img
                src={cardData.card_type_image}
                alt={cardData.card_type || "card"}
                style={{
                  height: cardData.card_type === "visa" ? "16px" : "24px",
                }}
              />
            </div>
          )}
        </div>
        <div className="d-flex w-100">
          <div className="card-date-block">
            <input
              type="text"
              maxLength="5"
              placeholder="MM/YY"
              value={cardData.date}
              onBlur={handelBlur}
              onChange={(e) => {
                let val = formatExpireDate(e.target.value);
                setCardData((prev) => {
                  return { ...prev, date: val };
                });
              }}
            />
          </div>
          <div className="cvv-block">
            <input
              type="password"
              placeholder="CVV"
              maxLength="4"
              value={cardData.cvv}
              onBlur={handelBlur}
              autoComplete="new-password"
              onChange={(e) => {
                let val = e.target.value;
                setCardData((prev) => {
                  return { ...prev, cvv: val };
                });
              }}
            />

            <div
              className="cvv-type-block"
              style={{
                height: "24px",
                width: "35px",
              }}
            >
              <img src={icons.cvv} alt="cvv" className="fill-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardInformation;
