import { BrowserRouter } from "react-router-dom";
import SessionExpiredPopup from "components/layouts/SessionExpiredPopup";
import MobileNumberPopup from "components/layouts/MobileNumberPopup";
import ErrorPopup from "components/layouts/ErrorPopup";
import AuthRoute from "./routes/authRoute";
import "assets/main.scss";
import "assets/animation.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import StickyContactButton from "components/layouts/StickyContactButton/StickyContactButton";

const App = () => {
  return (
    <div className="App">
      <ErrorPopup />
      <SessionExpiredPopup />
      <MobileNumberPopup />

      <BrowserRouter>
        <StickyContactButton />
        <AuthRoute />
      </BrowserRouter>
    </div>
  );
};

export default App;
