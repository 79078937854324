import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonRoute, icons } from "utils/constants";
import { useState } from "react";
import "./Sidebar.scss";

const Sidebar = () => {
  const history = useNavigate();
  const params = useParams();
  const [isImgError, setIsImgError] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.global.userData,
  }));
  const optionArray = [
    {
      id: "1",
      title: "Account Overview",
      url: "account-overview",
    },
    {
      id: "1",
      title: "Orders",
      url: "orders",
    },
    {
      id: "1",
      title: "Profile Info",
      url: "profile-info",
    },
    {
      id: "1",
      title: "Security",
      url: "security",
    },
    {
      id: "1",
      title: "AddOns",
      url: "https://buy.stripe.com/3cs02n4RXd820w0288",
    },
    {
      id: "1",
      title: "Billing",
      url: "billing",
    },
    {
      id: "1",
      title: "Support",
      url: "support",
    },
  ];
  const { profile_Image } = userData || {};
  let displayImage = isImgError
    ? icons.defaultAvatar
    : profile_Image || icons.defaultAvatar;
  return (
    <div id="sidebar-container" className="d-flex flex-column">
      <div className="head-empty-block" />
      <div className="icon-container">
        <img
          src={displayImage}
          alt="iconprofile"
          className="iconProfile fill-image"
          onError={() => {
            setIsImgError(true);
          }}
        />
      </div>

      <div className="content-container">
        <ul className="m-0 p-0 text-18-600 color-outer-space">
          {optionArray.map((elm, index) => {
            return (
              <li
                key={index}
                className={`${
                  elm.url === params?.type ? "active-option" : "pointer"
                }`}
                onClick={() => {
                  if (elm.url === "support") {
                    history(commonRoute.contactUs);
                  } else if (
                    elm.url !== params?.type &&
                    elm.title !== "AddOns"
                  ) {
                    history(`/my-profile/${elm.url}`);
                  } else if (elm.title === "AddOns") {
                    window.open(`${elm.url}`, "__balnk");
                  } else {
                    // notihng
                  }
                }}
              >
                {elm.title}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="side-bottom-parent flex-grow-1">
        <div className="sidebar-bottom-container">
          <span
            className="text-20-600 color-outer-space pointer"
            onClick={() => {
              history("/quiz-solutions");
            }}
          >
            Go to Quiz Solutions
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
