import CommonHelmet from "components/layouts/CommonHelmet";
import Layout from "../Layouts";

const TermsOfServices = () => {
  return (
    <Layout id="terms-of-services" isLogin>
      <CommonHelmet pageTitle="Terms & Conditions" />
      <div className="pt-5">
        <div className="text-23-700 color-black">Terms & Conditions</div>
        <div className="text-16-500">Updated: March 1, 2023 (Version 1.1)</div>
        <div className="mt-3 mb-3 text-14-600">Introduction:</div>
        <div className="text-14-400">
          HelpACourse extend collaborative services of social learning networks
          for students and educators. HelpACourse inspires students to share and
          explore knowledge in a collaborative community and engage in
          peer-to-peer educational assistance is made available on
          www.helpacourse.com (the{" "}
          <span className="text-14-600">“Website”</span>). Please read these
          terms and conditions of use carefully. By using the HelpACourse
          Courses service, you indicate your acceptance of these terms and
          conditions. If you do not accept these terms and conditions, you
          should not use the service. If you have concerns about our Terms of
          Service, we would like to hear about your concerns so we can evaluate
          if we should update our Terms of Service. Please send us an email at
          <span className="pointer ms-2 color-primary">
            helpdesk@HelpACourse.com
          </span>
          , and we'll respond promptly
        </div>
        <div className="mt-3 mb-3 text-14-600">Use of Services</div>
        <div className="text-14-400 mb-2">
          This Agreement sets forth the terms and conditions under which
          HelpACourse makes the Service available to you.
        </div>
        <div className="text-14-400 mb-2">
          Any use or access by anyone under the age of 13 is strictly
          prohibited. By accessing or using the HelpACourse services or
          purchasing HelpACourse subscription, you represent and warrant that
          you are of legal age in your jurisdiction and otherwise have the
          capacity to form a binding legal agreement with HelpACourse entity.
          These Terms give you specific legal rights, and you may also have
          additional legal rights, which vary from jurisdiction to jurisdiction.
        </div>
        <div className="text-14-400 mb-2">
          Subject to your compliance with these Terms, HelpACourse grants you a
          limited, personal, non-exclusive, revocable, non-assignable and
          non-transferable right and license to use the HelpACourse Services and
          any products, services, functionality, content, information, or other
          materials available on or through the HelpACourse Services, subject to
          the limitations described in the remainder of this section and
          elsewhere in these Terms.
        </div>
        <div className="text-14-400 mb-2">
          To become a “Registered User”, you must create User Credentials and be
          validated as a member of the HelpACourse Service. You understand that
          certain functions of HelpACourse Services may only be available to
          Registered Users. Registered Users have the right to use the products,
          services and functionality provided by HelpACourse and the content and
          information published on the HelpACourse Services only within their
          own needs, for non-commercial purposes. Should the products, services,
          functionality, content or information be subject to copyrights or any
          other intellectual property rights, the Registered User may use it
          within the allowed use under any applicable third party licenses and
          subject to such copyrights and related rights
        </div>
        <div className="text-14-400 mb-2">
          In order to ask the community of users for assistance with your
          homework (ask a Question) via the Website, you must be a Registered
          User. Only Registered Users or HelpACourse may provide a response to a
          Question (an Answer) or communicate with other Registered Users. The
          rights granted to you in the paragraph above and elsewhere in these
          Terms are subject to the following limitations:
        </div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You agree to use the HelpACourse Services and any products,
            services, functionality, content, information, or other materials
            available on, through or in connection with the HelpACourse Services
            or your HelpACourse Subscription only for lawful, personal, and
            informational purposes. You agree that you will not use any resource
            made available on the HelpACourse Services in any manner that is
            malicious or that violates any applicable local, state, national, or
            international law, or the intellectual property or proprietary
            rights of any party. You agree not to copy, reproduce, distribute,
            display portions of, or link to the HelpACourse Services or any
            products, services, information, or other materials available on or
            through the HelpACourse Services or your HelpACourse Subscription
            for commercial purposes without the prior express written consent of
            HelpACourse
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You agree not to post on or transmit onto the HelpACourse Services
            any information that you know to be untrue, that is unlawful,
            fraudulent, threatening, malicious, harassing, abusive, libelous,
            defamatory, obscene, or otherwise objectionable or harmful virus,
            malware or disruptive elements;
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You agree not to use any automated tool, such as a web scraping
            tool, a bot or web robot, or web automation, to mine the HelpACourse
            Services or any products, services, information, or other materials
            available on, through or in connection with the HelpACourse Services
            or your HelpACourse Subscription for information or to fill out
            forms on the HelpACourse
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You will not circumvent or attempt to circumvent any security or
            access control technology implemented on the HelpACourse Services or
            any products, services, information, or other materials available
            on, through or in connection with the HelpACourse Services or your
            HelpACourse Subscription or any servers, network or associated
            systems or physical premises operated by or for the benefit of
            HelpACourse.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You must not:
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            use HelpACourse Services other than for its intended purpose as set
            out in these Terms;
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            give any information or permit another person to use HelpACourse
            Services under your name or on your behalf;
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            fake your identity or give the impression they are linked to us or
            to HelpACourse, if this is not the case;
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            use HelpACourse Services if we have suspended your access to it, or
            have otherwise banned you from using it;
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            {" "}
            misuse HelpACourse Services by knowingly introducing viruses or
            malware or other material which would harm the HelpACourse Services
            or the equipment of any user of HelpACourse Services; or
          </li>
          <li
            style={{ listStyle: "circle", listStyleType: "circle" }}
            className="text-14-400"
          >
            use any automated system, including, but not limited to, "robots",
            "spiders" or "offline readers" to access HelpACourse Services via
            the sending of more request messages to HelpACourse Services than a
            human can reasonably produce in the same period of time.
          </li>
        </ul>
        <div className="text-14-600 mt-3">Tutor Services</div>
        <div className="text-14-400">
          HelpACourse may make available to Registered Users a service
          consisting of a live, whatsapp, online connection with an authorized
          tutor (a <span className="text-14-600">“HelpACourse Tutor”</span>).The
          Tutoring Services (and all content provided therewith) are deemed part
          of the HelpACourse Services
        </div>
        <div className="text-14-400 color-black">
          YOU HEREBY ACKNOWLEDGE THAT THE TUTORING SERVICES ARE PROVIDED ON AN
          “AS IS” AND “AS AVAILABLE” BASIS AND MAY BE LIMITED TO PARTICULAR
          QUESTIONS AND BE SUBJECT TO THE AVAILABILITY OF HELPACOURSE TUTORS AND
          OTHER CONSTRAINTS, IN OUR SOLE DISCRETION. WHILE HELPACOURSE MAKES
          EFFORTS TO SELECT HELPACOURSE TUTORS WHO HAVE RELEVANT ACADEMIC
          BACKGROUND AND OTHER PERTINENT QUALIFICATIONS, HELPACOURSE CANNOT
          GUARANTEE THAT ANY PROVIDED ANSWER IS TRUE, COMPLETE OR ACCURATE.
        </div>
        <div className="text-14-400">
          You are obligated to ensure that the Questions asked are presented in
          a form that allows the HelpACourse Tutor to provide an accurate
          Answer. The HelpACourse Tutor may not provide an Answer if such
          HelpACourse Tutor determines, in such HelpACourse Tutor’s sole
          discretion, that the Question or further Questions are presented in a
          form which does not allow for an accurate Answer (e.g. the Question is
          incomplete or ambiguous).
        </div>
        <div className="text-14-400">
          Moreover, you agree to exercise common sense and judgment while using
          the Tutoring Services and not to disclose to HelpACourse Tutors any
          information, including any personal information (yours or a third
          party’s), the disclosure of which is not required by the Tutoring
          Services or may be in conflict with these Terms. You will respect the
          privacy of the HelpACourse Tutors and you will not make any unwelcome,
          rude or abusive communications or interact with a HelpACourse Tutor in
          any unlawful, disrespectful, offensive, harmful or detrimental manner,
          as determined by HelpACourse in its sole discretion. While using the
          Tutoring Services you must not send to the HelpACourse Tutor any
          content which may be considered as illegal, unlawful, harmful,
          threatening, abusive, violent, harassing, tortious, defamatory,
          vulgar, obscene, pornographic, libelous, invasive of privacy or
          publicity rights, hateful or racially, sexually, ethnically or
          otherwise objectionable. The HelpACourse Tutor may terminate the
          session at any time if such HelpACourse Tutor determines, in such
          HelpACourse Tutor’s sole discretion, that the content presented by you
          or your behavior is in violation of the above mentioned rules.
        </div>
        <div className="text-14-400">
          If you ever believe that a HelpACourse Tutor has violated the law or
          is threatening or endangering you in any way, please contact us
          immediately at
          <span className="pointer ms-2 color-primary">
            helpdesk@HelpACourse.com
          </span>
          .
        </div>
        <div className="text-14-400">
          YOU UNDERSTAND THAT WHEN USING THE TUTORING SERVICES YOU WILL BE
          EXPOSED TO CONTENT FROM HELPACOURSE TUTORS WHO ARE NOT UNDER OUR
          DIRECT SUPERVISION OR CONTROL, AND THAT WE ARE NOT RESPONSIBLE FOR THE
          CONTENT OR COMMUNICATIONS PROVIDED BY THE HELPACOURSE TUTOR. YOU
          FURTHER UNDERSTAND AND ACKNOWLEDGE THAT YOU MAY BE EXPOSED TO CONTENT
          THAT COULD BE INACCURATE, OFFENSIVE, INDECENT, OR OBJECTIONABLE, AND
          YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS
          OR REMEDIES YOU HAVE OR MAY HAVE AGAINST US WITH RESPECT THERETO. YOU
          AGREE TO INDEMNIFY AND HOLD HARMLESS HELPACOURSE AND ITS AND ITS
          AFFILIATES’ EMPLOYEES, AGENTS, AND/OR LICENSORS, TO THE FULLEST EXTENT
          ALLOWED BY LAW REGARDING ALL MATTERS RELATED TO YOUR USE OF THE
          TUTORING SERVICES.
        </div>
        <div className="text-14-400">
          We may provide you with a mechanism to provide feedback, suggestions,
          and ideas about the Tutoring Services or individual HelpACourse
          Tutors. The form of such feedback mechanism shall be in our sole
          discretion. By providing your feedback you agree that you are giving
          up any rights you have in your feedback so that we may use and allow
          others to use it without any restriction and without any payment to
          you
        </div>
        <div className="text-14-400">
          You acknowledge and agree that HelpACourse may, but is not obligated
          to, record the course of any session with a HelpACourse Tutor, in
          accordance with applicable law. This recording, as well as the
          Questions and other content included in your Tutoring Services
          communications, shall be deemed Your Content (as defined below) under
          these Terms and may be used by HelpACourse in accordance with these
          Terms, including without limitation in order to provide the
          HelpACourse Services. You shall be allowed to access the recording of
          any session that you have participated in. In addition, your Question
          and the provided Answer (not the recorded live session) will be
          available through the HelpACourse Services, in accordance with these
          Terms.
        </div>
        <div className="text-25-600 color-dove-gray mt-5 mb-5">
          Subscription fee, Cancellation, Refund Policies;
        </div>
        <div className="text-14-600 mt-3 mb-3">Subscription Fees</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Subscriptions are on a fixed period basis i.e. Monthly, Quarterly &
            Annualy basis and provide the User Access to the entire Library.
            Your fixed period subscription renews automatically each period end
            date and your credit card will be charged the rate stated on the
            HelpACourse website.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Credit card holder's account is automatically charged on the same
            date as the original transaction date on each corresponding month.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Discounts, rebates or other special offers only valid for respective
            term of subscription type.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            HelpACourse may terminate the subscription and these terms if it is
            unable to renew the subscription based on inaccurate or outdated
            credit card information.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Right of access granted under these Terms is effective only upon
            payment of the subscription fees.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            We reserve the right to change our Subscription Plans or adjust
            pricing for our Services at our sole discretion. Any price changes
            or changes to your subscription will take effect following notice to
            you, except as otherwise required by applicable law.
          </li>
        </ul>
        <div className="text-14-600 mt-3 mb-3">Cancellation</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            You can cancel your subscription from your dashboard billing page.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            The only valid method for canceling your Plan is via the
            cancellation link provided on your user "dashboard" page, accessible
            after logging in to the HelpACourse Website. Requests to cancel by
            email or phone are not considered, and do not accomplish,
            cancellation.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            If your services are being billed as a monthly, Quarterly or annual
            subscription, your subscription will be terminated upon account
            cancellation and you will not be charged for any renewal terms.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Once you cancel your membership subscription, you will not lose
            access immediately. Your membership will continue through the end of
            your current charge cycle. For ex: If your credit card is charged
            for monthly subscription on the 10th of January month and you cancel
            on January 25th, you will not lose access until the February 9th.
          </li>
        </ul>
        <div className="text-14-600 mt-3 mb-3">Refunds</div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Whether you subscribe to a fixed term, a monthly, Quarterly or
            annual payment option, subscription fees are billed in advance and
            are non-refundable. There will be no refunds or credits for partial
            months of service, or refunds for the remaining term of a paid
            membership.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            HelpACourse subscription is non-refundable and will continue to
            renew automatically until cancelled. In case you want to cancel your
            subscription, kindly do the same at least 2 days prior to the
            renewal date.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Given the digital nature of our products, we offer no refunds. In
            case the HelpACourse website is permanently closed down due to
            closure of the business, then future subscription payments shall not
            be charged.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            In case you would like to discuss alternate products/services,
            please do contact us using mail or write to us part in website.
          </li>
        </ul>
        <div className="text-14-600">Limited License and Account Sharing.</div>

        <div className="text-14-400 mt-3">
          HelpACourse hereby grants each Subscriber a limited, non-exclusive,
          non-sub licensable and nontransferable license to Access the content
          and information available in the Library according to the provisions
          contained herein, and subject to the payment of the applicable
          subscription fees and adherence to these Terms.
        </div>
        <div className="text-14-400 mb-3">
          Each subscription allows one named individual (the Subscriber) access
          the HelpACourse Library. The Subscriber is prohibited from sharing his
          or her account with others. HelpACourse actively monitors for patterns
          of account sharing. If HelpACourse determines, in its sole discretion,
          that a Subscribers account is being shared with more than the named
          Subscriber, HelpACourse may terminate the account and disallow the
          user from signing up to use HelpACourse in the future.
        </div>
        <div className="text-14-600">Limitation of Liability</div>
        <div className="text-14-400 mt-3">
          It may happen that our platform is down, either for planned
          maintenance or because something goes down with the site. It may also
          happen that we encounter security issues. These are just examples. You
          accept that you will not have any recourse against us in any of these
          types of cases where things don’t work out right. In legal, more
          complete language, the Services and their content are provided on an
          “as is” and “as available” basis. We (and our affiliates, suppliers,
          partners, and agents) make no representations or warranties about the
          suitability, reliability, availability, timeliness, security, lack of
          errors, or accuracy of the services or their content, and expressly
          disclaim any warranties or conditions (express or implied), including
          implied warranties of merchantability, fitness for a particular
          purpose, title, and non-infringement. We (and our affiliates,
          suppliers, partners, and agents) make no warranty that you will obtain
          specific results from use of the Services. Your use of the Services
          (including any content) is entirely at your own risk. Some
          jurisdictions don’t allow the exclusion of implied warranties, so some
          of the above exclusions may not apply to you.
        </div>
        <div className="text-14-400">
          We may decide to cease making available certain features of the
          Services at any time and for any reason. Under no circumstances will
          HelpACourse or its affiliates, suppliers, partners or agents be held
          liable for any damages due to such interruptions or lack of
          availability of such features.
        </div>
        <div className="text-14-400 mb-3">
          We are not responsible for delay or failure of our performance of any
          of the Services caused by events beyond our reasonable control, like
          an act of war, hostility, or sabotage; natural disaster; electrical,
          internet, or telecommunication outage; or government restrictions.
        </div>
        <div className="text-14-600">Termination</div>
        <div className="text-14-400 mb-3">
          HelpACourse reserves the right, in its sole discretion, to immediately
          modify, suspend or terminate your account, the HelpACourse Services,
          your HelpACourse Subscription, and/or any products, services,
          functionality, information, content or other materials available on,
          through or in connection with the HelpACourse Services and/or your
          HelpACourse Subscription, and/or your access to some or all of them
          without notice due to your actual or alleged violation of these Terms
          (including without limitation any violation of the Community
          Guidelines) or any applicable law or regulation. In the event that
          HelpACourse suspends or terminates your account, the HelpACourse
          Services or your HelpACourse Subscription, you acknowledge and agree
          that you shall receive no refund or exchange for any unused time on a
          HelpACourse Subscription or any subscription fees or anything else.
        </div>
        <div className="text-14-600">Indemnification</div>
        <div className="text-14-400 mb-3 mt-3">
          Subscriber agrees to defend, indemnify and otherwise hold harmless and
          its officers, directors, agents, employees, shareholders, successors
          and assigns from and against any cause of action or claim, including
          court costs, expenses and attorney fees, related to or arising from
          User’s Prohibited Conduct or other improper or illegal use of the
          Site, or breach of these Terms.
        </div>
        <div className="text-14-600">Assignment</div>
        <div className="text-14-400 mb-3">
          HelpACourse expressly reserves the right to assign and transfer these
          Terms, and its rights and obligations under these Terms, in part or as
          a whole, without restriction, including, but not limited to, to
          another legal entity as part of any sale or merger of all or part of
          HelpACourse with a separate legal entity. You may not transfer these
          Terms, or your rights or obligations under these Terms, to any person
          or entity
        </div>
        <div className="text-14-600">Waiver and Severability</div>
        <div className="text-14-400">
          No waiver by HelpACourse of any term or condition set forth in these
          Terms will be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of HelpACourse to assert a right or provision under these Terms will
          not constitute a waiver of such right or provision
        </div>
        <div className="text-14-400">
          If any provision of these Terms is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal, or unenforceable for
          any reason, such provision will be eliminated or limited to the
          minimum extent such that the remaining provisions of the Terms will
          continue in full force and effect.
        </div>
        <div className="text-14-400 mb-3">
          These Terms are the complete and exclusive statement of the agreement
          between you and HelpACourse concerning the HelpACourse Services and/or
          any products, services, information, or other materials available on,
          through or in connection with the HelpACourse Services or your
          HelpACourse Subscription. These Terms replace and supersede any prior
          or contemporaneous oral or written agreement and any other
          communications between you and HelpACourse.
        </div>
        <div className="text-14-600">Changes to These Terms</div>
        <div className="text-14-400 mb-3">
          HelpACourse regularly reviews these Terms and may revise or update
          them at our sole discretion. Changes to these Terms become effective
          immediately upon their posting on the HelpACourse Services and your
          continued use of the HelpACourse Services following the posting of
          revised Terms represents that you have read, understood, and agreed to
          the changes. The most recent version of these Terms will be maintained
          on the HelpACourse Services.
        </div>
        <div className="text-14-600">Contact Us</div>
        <div className="text-14-400">
          If you have questions about these Terms, you may contact us by email
          at
          <span className="pointer ms-2 me-2 color-primary">
            helpdesk@HelpACourse.com.
          </span>
          Please note that communications made to
          <span className="pointer ms-2 me-2 color-primary">
            helpdesk@HelpACourse.com
          </span>
          will not constitute legal notice to HelpACourse, its officers,
          employees, agents, or representatives. You may also contact us via
          mail @the address given in the website “contact page”.
        </div>
        <div className="text-center mt-3 cmb-130">***</div>
      </div>
    </Layout>
  );
};
export default TermsOfServices;
