export const commonRoute = {
  resetpassword: "/resetpassword/:token",
  myProfile: "/my-profile",
  myProfileType: "/my-profile/:type",
  profileInfo: "/profile-info",
  contactUs: "/contact-us",
  flashCardType: "/flash-card/:type",
  header: "/header",
  quizSolutions: "/quiz-solutions",
  home: "/",
  tutorSupport: "/tutor-support/:type",
  career: "/career/:type",
  book: "/book",
  payment: "/subscription-payment/:orderID/:id",
  pageNotFound: "/page-not-found",
  termsOfServices: "/terms-of-services",
  privacyPolicy: "/privacy-policy",
  cancelRefundPolicy: "/cancel-refund-policy",
  dataDeletionPolicy: "/facebook-data-deletion-instructions-url",
  sitemap: "/sitemap",
};
