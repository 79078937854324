import Button from "components/form/Button";
import PhoneNumber from "components/form/PhoneNumber";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import Layout from "pages/Layouts";
import { icons } from "utils/constants";
import * as Yup from "yup";
import { Formik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { titleCaseString, trimLeftSpace } from "utils/helpers";
import { addContact, throwSuccess } from "store/globalSlice";
import { useRef } from "react";
import "./ContactUs.scss";
import CommonHelmet from "components/layouts/CommonHelmet";

const ContactUs = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(addContact(values));
    if (response?.status === 201) {
      dispatch(throwSuccess("Message sent successfully."));
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    mobile: Yup.string().required("Mobile is required."),
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    message: Yup.string().required("Message is required."),
  });

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    message: "",
    country_code: "IN",
  };
  return (
    <Layout hideContainer isLogin>
      <CommonHelmet pageTitle="Contact Us" />
      <div id="contact-us-container">
        <div className="contact-header">
          <img
            src={icons.contactUsBg}
            alt="conatct-us"
            className="fill-image"
          />
          <div className="text-50-700 color-white contact-text">Contact Us</div>
        </div>
        <div className="container">
          <div className="row w-100 cpt-40 cpb-50">
            <div className="col-md-6 cpe-30">
              <div className="text-18-600 mb-2">Drop Your Requirements</div>
              <div className="text-16-400">
                Tell us about your course, deadline and university information
                through which we will get to know the nature of the work we will
                be dealing with.
              </div>
              <div className="border-top cmt-10 cmb-10" />
              <div className="text-18-600 mb-2">
                Get a call from our executive tutor
              </div>
              <div className="text-16-400">
                According to your requirements, our executive will be calling
                you or would be contacting you by the means of communications
                you have provided to our team.
              </div>
              <div className="border-top cmt-10 cmb-10" />
              <div className="text-18-600 mb-2">
                Get a delivery straight to your email
              </div>
              <div className="text-16-400">
                After the requirements and objective is given to the executive
                tutor, the work will be done by the deadline and will be
                delivered straight to your email.
              </div>
              <div className="border-top cmt-10 cmb-10" />
              <div className="text-18-600 mb-2">Complete payment</div>
              <div className="text-25-400">
                The work will be verified by the customer and the payment should
                be done to the given details by the executive.
              </div>
            </div>

            <Formik
              enableReinitialize
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handelSave}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldError,
                } = props;

                return (
                  <div className="col-md-6 cps-30">
                    <div className="text-22-600 cmb-20">
                      For Query Or Feedback - Write to us:
                    </div>
                    <div className="cmb-25">
                      <TextInput
                        label="NAME"
                        placeholder="Enter your name"
                        id="name"
                        value={values.name}
                        error={errors?.name}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              id: e.target.id,
                              value: trimLeftSpace(
                                titleCaseString(e.target.value)
                              ),
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 cmb-25">
                          <PhoneNumber
                            label="MOBILE"
                            placeholder="Enter your mobile number"
                            id="mobile"
                            value={values.mobile}
                            error={errors?.mobile}
                            country_code={values?.country_code}
                            onCodeChange={(e) => {
                              handleChange(e);
                              setFieldValue("mobile", "");
                            }}
                            onChange={handleChange}
                            onBlur={(isError) => {
                              let newerror = isError
                                ? "Invalid mobile number"
                                : "";
                              setFieldError("mobile", newerror);
                            }}
                          />
                        </div>
                        <div className="col-md-6 cmb-25">
                          <TextInput
                            label="EMAIL"
                            placeholder="Enter a valid email address"
                            id="email"
                            value={values.email}
                            error={errors?.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Message cmb-25">
                      <TextArea
                        rows={4}
                        label="MESSAGE"
                        placeholder="Enter your message"
                        id="message"
                        value={values.message}
                        error={errors?.message}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              id: e.target.id,
                              value: trimLeftSpace(
                                titleCaseString(e.target.value)
                              ),
                            },
                          });
                        }}
                      />
                    </div>
                    <Button
                      hideAnimation
                      btnStyle="btn-primary-yellow"
                      value="Submit"
                      onClick={(e) => {
                        if (!btnLoading) {
                          handleSubmit(e);
                        }
                      }}
                      btnLoading={btnLoading}
                    />
                    {/* <div className="cmt-30 get-in-touch-block">
                      <div className="text-29-700 text-center color-white cmb-18">
                        GET IN TOUCH
                      </div>
                      <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 flex-column">
                        <div className="text-19-500 color-white d-flex align-items-center">
                          <span className="me-2">
                            <img src={icons.emailContact} alt="whatsapp" />
                          </span>
                          <span>{email}</span>
                        </div>
                        <div className="text-19-500 color-white d-flex align-items-center">
                          <span className="me-2 d-flex">
                            <img src={icons.whatsappContact} alt="whatsapp" />
                          </span>
                          <span>{formatPhoneNumber(`+${whatsUp_mobile}`)}</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactUs;
