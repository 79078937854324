import * as Yup from "yup";
import { Formik } from "formik";
import { omit } from "lodash";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import { throwSuccess, updatePassword } from "store/globalSlice";
import { useRef, useState } from "react";
import PasswordInput from "components/form/PasswordInput";
import { passwordError } from "utils/constants";

const CreatePassword = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(
      updatePassword(omit(values, ["confirm_password"]))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess("Password changed successfully."));
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/
      ),
    newPassword: Yup.string()
      .required("Password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        passwordError
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required.")
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .oneOf([Yup.ref("newPassword"), null], "Password not matched."),
  });
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirm_password: "",
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
      innerRef={formRef}
    >
      {(props) => {
        const { values, errors, handleChange, handleSubmit } = props;
        return (
          <form>
            <div className="cmb-25">
              <PasswordInput
                showInfo
                label="Old Password"
                placeholder="Enter Old Password"
                id="oldPassword"
                value={values.oldPassword}
                error={errors.oldPassword}
                onChange={handleChange}
              />
            </div>
            <div className="cmb-25">
              <PasswordInput
                showInfo
                label="New Password"
                placeholder="Enter New Password"
                id="newPassword"
                value={values.newPassword}
                error={errors.newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="cmb-25">
              <PasswordInput
                label="Confirm Password"
                placeholder="Enter Confirm Password"
                id="confirm_password"
                value={values.confirm_password}
                error={errors.confirm_password}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <Button
                hideAnimation
                value="Change Password"
                onClick={handleSubmit}
                btnLoading={btnLoading}
                className="cps-30 cpe-30"
                btnStyle="btn-primary-yellow"
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
export default CreatePassword;
