import { icons } from "utils/constants";

const Answer = ({ answer, quetionType, externalFile, isHide }) => {
  let filetype = "";
  if (externalFile) {
    filetype = externalFile?.split(".").pop();
  }
  return (
    <>
      {quetionType === "askQuestion" && (
        <div className="answer-block-option parent-answer">
          <div className="cpt-68 cpb-67 cps-32 bdr-color">
            <li className="text-size-block text-32-400 color-haiti">
              {answer}
            </li>
          </div>
          {isHide && (
            <img
              src={icons.imageMathsLogic}
              alt="mathsLogicImage"
              className="fill-image child-answer"
            />
          )}
        </div>
      )}
      {quetionType === "expertSolution" && (
        <div className="bdr-radius bdr-color parent-answer">
          <div className="cps-32 cpe-32 cpt-25 cpb-25 text-32-400 color-haiti">
            {answer}
          </div>
          {isHide && (
            <img
              src={icons.imageMathsLogic}
              alt="mathsLogicImage"
              className="fill-image child-answer"
            />
          )}
        </div>
      )}
      {quetionType === "flashCard" && (
        <div className="bdr-radius bdr-color parent-answer">
          <div className="w-100 cps-30 cpe-30 cpt-30 cpb-30">
            {filetype === "pdf" ? (
              <iframe
                className="w-100"
                src={`${externalFile}#toolbar=0`}
                title="description"
                style={{
                  width: "100%",
                  height: "475px",
                }}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "475px",
                }}
              >
                <img
                  src={externalFile}
                  alt="answer-file"
                  className="fill-image"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </div>
          {isHide && (
            <img
              src={icons.imageMathsLogic}
              alt="mathsLogicImage"
              className="fill-image child-answer"
            />
          )}
        </div>
      )}
    </>
  );
};
export default Answer;
