// let StripeSecretKey =
//   "sk_test_51Mo53GSF7jse029jHMjdSJqH60MGgJZTO056vmY690KRkjdA2AtniAV9qJH4zcMaZTuVg8flAjGWVbTsSu7z1qrD00tKIJTDPd";
// let StripePublishKey =
//   "pk_test_51Mo53GSF7jse029jEgWM9ZxB9dCsBccGMzSykWfF2QDVI3mg2mhSMO3eBiYoXUiNFycNxLh0rAODKPQbX46WvpVq00g9xdcNPf";

// Live
// let StripeSecretKey =
//   "sk_test_51Mvdq8SGD3dDxL9pPw6v1XD9pEF8bv5T1jjTdbwPwt5xhRytT9gK9J8YXpCHEFtNc9NRS1XJynSuUkalg5HdLeuZ00yhP9HtsP";
// let StripePublishKey =
//   "pk_test_51Mvdq8SGD3dDxL9pOEeGjcq0YmNl8iJ20E9zsfDZg8HNxV9Fm3I1b64ueyx5cgWDz6WrOoOokpGec0W5ehAcOooV00KecNX9c4";

export const stripePostWithPK = (url, data, StripePublishKey) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + StripePublishKey,
    },
    body: data,
  })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("api post error:", err);
      throw err;
    });
};

export const stripePost = (url, data, StripeSecretKey) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + StripeSecretKey,
    },
    body: data,
  })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("api post error:", err);
      throw err;
    });
};

export const stripeGet = (url, StripeSecretKey) => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + StripeSecretKey,
    },
  })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("api post error:", err);
      throw err;
    });
};

export const stripeDelete = (url, StripeSecretKey) => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + StripeSecretKey,
    },
  })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("api post error:", err);
      throw err;
    });
};
