import React from "react";
import LabelText from "../LabelText";
import "./TextArea.scss";

const TextArea = ({ label, placeholder, id, error, onChange, value, rows }) => {
  return (
    <div id="text-area-container">
      {label && <LabelText label={label} />}
      <div className="d-flex">
        <textarea
          rows={rows || 3}
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          value={value}
          className="text-input-area text-14-500 color-nobel"
        />
      </div>
      {error && <div className="text-12-400 color-carnation">{error}</div>}
    </div>
  );
};

export default TextArea;
