import { useEffect } from "react";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getSitemap } from "store/globalSlice";
import Button from "components/form/Button";
import Loader from "components/layouts/Loader";
import Layout from "pages/Layouts";
import "./Sitemap.scss";

const Sitemap = () => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    loader: true,
    data: [],
  });
  const getPageData = async () => {
    const response = await dispatch(getSitemap());
    setPageData({
      loader: false,
      data: response?.data || [],
    });
  };
  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loader, data } = pageData;
  return (
    <Layout id="sitemap-container">
      <div className="cpt-130 cpb-130">
        <div className="shadow">
          <div className="d-flex justify-content-between align-items-center cps-25 cpe-25 pt-4">
            <div className="text-28-700">Sitemap Preview</div>
            {data.length > 0 && !loader && (
              <div>
                <Button
                  hideAnimation
                  value="VIEW XML SITEMAP"
                  btnStyle="btn-primary"
                  className="cps-20 cpe-20 pt-2 pb-2 h-auto text-16-400"
                  onClick={() => {
                    window.open(
                      "https://backend.helpacourse.com/Images/Site/sitemap.xml"
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div
            className="cmt-50 overflow-auto"
            style={{
              height: "515px",
            }}
          >
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="cps-30">
                    URL
                  </th>
                  <th scope="col">Last Mod</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={2} className="border-0">
                      <div className="cpt-150 text-center">
                        <Loader size="md" />
                      </div>
                    </td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td colSpan={2}>
                      <div className="cpt-150 text-center">No Data Found</div>
                    </td>
                  </tr>
                ) : (
                  data?.map((elem, index) => {
                    const date = new Date().toISOString();
                    const formattedDate = date.replace("T", " T ");

                    return (
                      <tr key={index}>
                        <td className="cps-30">
                          <Nav.Link
                            className="text-16-400-26 text-primary"
                            href={elem?.slug}
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(elem?.slug);
                            }}
                          >
                            {elem?.slug}
                          </Nav.Link>
                        </td>
                        <td className="cpe-30 text-nowrap">{formattedDate}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Sitemap;
