import Layout from "../Layouts";

const DataDeletetionPolicy = () => {
  return (
    <Layout id="data-deletion-policy" isLogin>
      <div className="pt-5">
        <div className="text-23-700 color-black">
          Facebook Data Deletion Instructions URL
        </div>
        <div className="mt-3 mb-3 text-14-600">
          If you want to delete your data from HelpACourse, you can remove your
          information by following these steps:
        </div>
        <ul>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Go to your Facebook Account’s Setting & Privacy. Click “Settings”
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Look for “Apps and Websites” and you will see all of the apps and
            websites you linked with your Facebook.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Search and Click “HelpACourse” in the search bar.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Scroll and click “Remove”.
          </li>
          <li style={{ listStyle: "initial" }} className="text-14-400">
            Congratulations, you have successfully removed your app activities
            and data from the HelpACourse
          </li>
        </ul>
        <div className="text-center mt-3 cmb-130">***</div>
      </div>
    </Layout>
  );
};
export default DataDeletetionPolicy;
