import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, omit } from "lodash";
import Button from "components/form/Button";
import Layout from "pages/Layouts";
import FlashCard from "components/layouts/FlashCard";
import NoResultFound from "./NoResultFound";
import QuestionAnswer from "./QuestionAnswer";
import GetMoreAnswers from "./GetMoreAnswers";
import { getDataFromLocalStorage } from "utils/helpers";
import {
  searchQuestion,
  setAnswerData,
  setQuestionData,
} from "store/globalSlice";
import "./QuizSolutions.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const AskQuestion = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const params = useParams();
  const { slug } = params;
  // eslint-disable-next-line no-unused-vars
  const { userState, answerData, questionData, isNoAnswer, siteData } =
    useSelector((state) => ({
      userState: state.global.userState,
      answerData: state.global.answerData,
      questionData: state.global.questionData,
      isNoAnswer: state.global.isNoAnswer,
      siteData: state.global.siteData,
    }));

  const [btnLoading, setBtnLoading] = useState(false);
  const [pageData, setPageData] = useState({
    search: questionData?.question,
    type: "flashCard",
  });

  const handelSave = async (values) => {
    setBtnLoading(true);
    let oldData = cloneDeep(answerData);
    oldData = {
      ...oldData,
      type: type || values.type,
      search: values.search
        .toLowerCase()
        ?.split(" ")
        .join("-")
        .replace(/_/g, "")
        .replace(/-+/g, "-")
        .replace(/-$/, ""),
      offset: 0,
      total: 0,
    };
    dispatch(setAnswerData(oldData));
    await dispatch(searchQuestion(omit(oldData, ["list", "total"])));
    setBtnLoading(false);
  };
  useEffect(() => {
    if (questionData.question) {
      setPageData({
        search: "",
        type: "askQuestion",
      });
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
  }, [questionData]);

  useEffect(() => {
    if (slug) {
      handelSave({
        type: "flashCard",
        search: slug,
      });
    }
    return () => {
      dispatch(setQuestionData({ question: "", type: "flashCard" }));
      dispatch(
        setAnswerData({
          list: [],
          total: 0,
          type: "flashCard",
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userData = getDataFromLocalStorage();
  const { token, Orders } = userData || {};
  const plan = Orders?.[0] || {};
  const { status, subscriptionPlan_id: planID, id: orderID } = plan;
  const isAuth = token ? true : false;
  const initialValues = {
    search: pageData.search,
    type: pageData.type,
  };
  const access = {
    isQuestion: status === 1 && !slug,
    isNoResult: isNoAnswer,
    isGetMore: !isAuth || status === 0,
  };
  // const queAnsRef = useRef(null);
  // useEffect(() => {
  //   console.log("search", initialValues.search);
  //   const ScrollTo = document.getElementById("test").offsetTop;
  //   console.log(" queAnsRef.current", queAnsRef.current);
  //   queAnsRef.current?.scrollIntoView(500, 0);
  // }, [initialValues]);
  const { fav_icon } = siteData || {};
  let metaData = answerData?.list?.[0] || {};
  const { meta_title, meta_keywords, meta_description } = metaData;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={fav_icon || ""} id="app-favicon" />
        <title>{meta_title || "Quiz Solutions"}</title>
        <meta name="keywords" content={meta_keywords || ""} />
        <meta name="description" content={meta_description || ""} />
      </Helmet>
      <div id="ask-question-container">
        <div className="text-28-700 color-outer-space text-center cpt-40 cmb-50">
          Quiz Solutions
        </div>
        <div className="cmb-30">
          <div className="text-28-700 color-outer-space mb-3">Flash Cards</div>
          <FlashCard />
        </div>
        {access.isQuestion && (
          <div className="row cmb-96 slideInRight">
            <div className="ask-question-block col-md-7">
              <Formik
                enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={handelSave}
              >
                {(props) => {
                  const { values, handleChange, handleSubmit } = props;
                  return (
                    <form
                      className="bdr-stlye cps-30 cpt-30 cpb-30 cpe-30 h-100"
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(e);
                        }
                      }}
                    >
                      <div className="text-28-700 color-outer-space cmb-30">
                        ASK A QUESTION
                      </div>
                      <div className="text-20-500 color-outer-space cmb-7">
                        ABOUT YOUR ASSIGNMENT
                      </div>
                      <div className="cmb-25 w-100">
                        <textarea
                          rows="8"
                          className="text-18-500 color-dove-gray cpt-20 cps-20 cpe-20 bg-black-squeeze border-0 outline-0 bdr resize-none w-100"
                          placeholder="Write your question here (Keep it simple and clear to get the best answer)"
                          id="search"
                          value={values?.search}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="cmb-25 w-50 ">
                        <select
                          className="option-select w-100 cpt-13 cpb-13 cps-25 cpe-20 text-13-400 color-dove-gray bg-black-squeeze border-0 bdr"
                          id="type"
                          value={values?.type}
                          onChange={handleChange}
                        >
                          <option value="flashCard">Flash Cards</option>
                          <option value="askQuestion">Ask A Question</option>
                          <option value="expertSolution">
                            Expert Solutions
                          </option>
                        </select>
                      </div>
                      <div className="d-flex">
                        <Button
                          hideAnimation
                          value="Ask Your Question"
                          className="cpt-13 cpb-13 cps-25 cpe-25 bdr"
                          btnStyle="btn-primary"
                          onClick={handleSubmit}
                          btnLoading={btnLoading}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-5">
              <div className="image-div">
                <div className="text-block-image">
                  <div className="image-text-heading text-29-700 color-white cms-10 cmb-10 d-flex">
                    Success in every step
                  </div>
                  <div className="image-description text-16-600 color-white cms-10">
                    Stay on top of it with homework help, exam prep & writing
                    support—tailored to your courses.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <QuestionAnswer />
        {access.isNoResult && <NoResultFound />}
        {access?.isGetMore && (
          <GetMoreAnswers
            isAuth={isAuth}
            isShowPayment={status === 0}
            handelNavigate={() => {
              navigate(`/subscription-payment/${orderID}/${planID}`);
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default AskQuestion;
