import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BootrsapModal from "react-bootstrap/Modal";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import LeftImage from "pages/Frontend/LeftImage";
import ResetPasswordPage from "./ResetPasswordPage";
import TwoFactor from "./TwoFactor";
import VerifyTwoFactor from "./VerifyTwoFactor";
import { setIsLoginSignup } from "store/globalSlice";
import GoogleButton from "../GoogleButton";
import FacebookButton from "../FacebookButton";
import { useNavigate } from "react-router-dom/dist";
import "./AuthPopup.scss";
import { commonRoute } from "utils/constants";

const AuthPopup = ({ parentPageType, handelSubscription }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageType, setPageType] = useState(0);
  useEffect(() => {
    setPageType(parentPageType);
  }, [parentPageType]);

  return (
    <BootrsapModal
      onHide={() => {
        dispatch(setIsLoginSignup(false));
      }}
      fullscreen
      centered
      show
    >
      <BootrsapModal.Body id="modal-container-2">
        <div
          className="close-block pointer d-flex"
          onClick={() => {
            dispatch(setIsLoginSignup(false));
          }}
        >
          <i className="bi bi-x-lg text-20-600" />
        </div>
        <div className="row h-100 w-100">
          <div className="col-md-6 left-img-block">
            <LeftImage />
          </div>
          <div id="auth-popup-container" className="col-md-6">
            {[0, 1].includes(pageType) && (
              <div className="d-flex gap-4 login-signupHeading cmb-49">
                <span
                  className={`text-22-600 ${
                    pageType === 1
                      ? "active-auth-option color-black"
                      : "pointer color-dove-gray"
                  }`}
                  onClick={() => {
                    setPageType(1);
                  }}
                >
                  Sign up
                </span>

                <span
                  className={`text-22-600 ${
                    pageType === 0
                      ? "active-auth-option color-black"
                      : "pointer color-dove-gray"
                  }`}
                  onClick={() => {
                    setPageType(0);
                  }}
                >
                  Log in
                </span>
              </div>
            )}
            {pageType === 0 && <LoginPage setPageType={setPageType} />}
            {pageType === 1 && (
              <SignupPage handelSubscription={handelSubscription} />
            )}
            {pageType === 2 && <ResetPasswordPage setPageType={setPageType} />}
            {pageType === 3 && <TwoFactor setPageType={setPageType} />}
            {pageType === 4 && (
              <VerifyTwoFactor
                setPageType={setPageType}
                handelSubscription={handelSubscription}
              />
            )}

            {[0, 1].includes(pageType) && (
              <>
                <div className="login-hrtext-div cmb-25">
                  <h6 className="hrText">OR</h6>
                </div>

                <div className="cmb-15">
                  <GoogleButton
                    handelSuccess={() => {
                      dispatch(setIsLoginSignup(false));
                      navigate(commonRoute.myProfile);
                    }}
                  />
                </div>

                <div className="cmb-25">
                  <FacebookButton
                    handelSuccess={() => {
                      dispatch(setIsLoginSignup(false));
                      navigate(commonRoute.myProfile);
                    }}
                  />
                </div>

                <div className="text-center">
                  <p className="text-14-400 color-outer-space">
                    {`${
                      pageType === 0
                        ? "Don't have an account yet?"
                        : "Already have an account?"
                    }`}

                    <span
                      className="text-14-600 color-dodger-blue pointer ms-1"
                      onClick={() => {
                        setPageType(pageType === 0 ? 1 : 0);
                      }}
                    >
                      {`${pageType === 0 ? "Sign up" : "Log in"}`}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </BootrsapModal.Body>
    </BootrsapModal>
  );
};
export default AuthPopup;
