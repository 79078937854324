import Button from "components/form/Button";
import TextInput from "components/form/TextInput";
import * as Yup from "yup";
import { Formik } from "formik";
import { trimLeftSpace } from "utils/helpers";
import { isEqual } from "lodash";
import PasswordInput from "components/form/PasswordInput/PasswordInput";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addBackupCode } from "store/globalSlice";

const TwoFADetails = ({ isEdit, handelCancel, description, handelSuccess }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    let payload = {
      backupCode: values.backupCode || "",
      description: values.description || "",
      status: values.backupCode ? 1 : 0,
    };
    const response = await dispatch(addBackupCode(payload));
    if (response?.status === 200) {
      handelSuccess(values.backupCode ? 1 : 0);
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    backupCode: Yup.string()
      .matches(/^[0-9]+$/, "Code must be only in digits")
      .min(6, "Code must be exact 6 digits")
      .max(6, "Code must be exact 6 digits"),
  });

  const initialValues = {
    backupCode: "",
    description: description || "",
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
    >
      {(props) => {
        const { values, errors, handleChange, handleSubmit } = props;
        return (
          <form>
            <div className="cmb-30">
              <PasswordInput
                label="ENTER A BACKUP CODE"
                placeholder="Enter backup code"
                onChange={handleChange}
                id="backupCode"
                value={values.backupCode}
                error={errors.backupCode}
              />
            </div>
            <div className="cmb-30">
              <TextInput
                label="DESCRIPTION"
                placeholder="Application description"
                onChange={(e) => {
                  handleChange({
                    target: {
                      id: e.target.id,
                      value: trimLeftSpace(e.target.value),
                    },
                  });
                }}
                id="description"
                value={values.description}
                error={errors.description}
              />
            </div>
            {isEdit ? (
              <div className="d-flex justify-content-end gap-3">
                <Button
                  btnStyle="btn-primary-yellow-outline"
                  value="Cancel"
                  className="cps-30 cpe-30"
                  onClick={handelCancel}
                  hideAnimation
                />
                <Button
                  btnStyle="btn-primary-yellow"
                  value="Confirm"
                  className="cps-30 cpe-30"
                  onClick={handleSubmit}
                  disabled={isEqual(values, initialValues)}
                  btnLoading={btnLoading}
                  hideAnimation
                />
              </div>
            ) : (
              <Button
                hideAnimation
                btnStyle="btn-primary-yellow"
                value="Submit"
                className="cps-30 cpe-30"
                onClick={handleSubmit}
                disabled={isEqual(values, initialValues)}
                btnLoading={btnLoading}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};
export default TwoFADetails;
