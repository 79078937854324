import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import TermsOfServices from "pages/Layouts/Policies/TermsOfServices";
import PrivacyPolicy from "pages/Layouts/Policies/PrivacyPolicy";
import CancelRefundPolicy from "pages/Layouts/Policies/CancelRefundPolicy";
import Home from "pages/Frontend/Home";
import QuizSolutions from "pages/Frontend/QuizSolutions";
import Profile from "pages/Frontend/Profile";
import FlashCardDetails from "pages/Frontend/FlashCardDetails";
import ContactUs from "pages/Auth/ContactUs";
import ComingSoon from "pages/Frontend/ComingSoon";
import Payment from "pages/Frontend/Payment";
import ResetPassword from "pages/Frontend/ResetPassword";
import PageNotFound from "components/layouts/PageNotFound";
import Sitemap from "pages/Frontend/Sitemap";
import { getDataFromLocalStorage } from "utils/helpers";
import { commonRoute } from "utils/constants";
import {
  getProfileData,
  getSiteData,
  getSubscriptionPlan,
  setIsMobileNumber,
} from "store/globalSlice";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DataDeletetionPolicy from "pages/Layouts/Policies/DataDeletetionPolicy";

const AuthRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userState, siteData } = useSelector((state) => ({
    userState: state.global.userState,
    siteData: state.global.siteData,
  }));
  const getGlobalAPIs = async () => {
    let response = await dispatch(getProfileData());
    if (response?.status === 200) {
      await dispatch(getSubscriptionPlan());
      if (response?.data?.user_type !== 0 && !response?.data?.mobile_no) {
        dispatch(setIsMobileNumber(true));
      }
    }
  };
  const getUnAuthAPI = async () => {
    await dispatch(getSubscriptionPlan());
  };
  const globalAPI = async () => {
    await dispatch(getSiteData());
  };
  const stripePromise = loadStripe(siteData?.publishable_key || "");
  useEffect(() => {
    setTimeout(() => {
      if (siteData) {
        const { header, footer } = siteData;
        if (header) {
          let val = header
            .replace(/<script type="text\/javascript">/, "")
            .replace(/<script>/, "")
            .replace(/<\/script>/, "");
          var headerScriptTag = document.createElement("script");
          headerScriptTag.type = "text/javascript";
          headerScriptTag.text = val;
          document.head.appendChild(headerScriptTag);
        }
        if (footer) {
          let val = footer
            .replace(/<script type="text\/javascript">/, "")
            .replace(/<script>/, "")
            .replace(/<\/script>/, "");
          var footerScriptTag = document.createElement("script");
          footerScriptTag.type = "text/javascript";
          footerScriptTag.text = val;
          document.body.appendChild(footerScriptTag);
        }
      }
    }, 3000);
  }, [siteData]);

  useEffect(() => {
    // getPageData();
    globalAPI();
    if (getDataFromLocalStorage("token")) {
      getGlobalAPIs();
    } else {
      getUnAuthAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);
  useEffect(() => {
    if (location.pathname.includes("quiz-solutions")) {
      if (location.search === "") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Routes>
        <Route
          exact
          path={commonRoute.termsOfServices}
          element={<TermsOfServices />}
        />
        <Route
          exact
          path={commonRoute.privacyPolicy}
          element={<PrivacyPolicy />}
        />
        <Route
          exact
          path={commonRoute.cancelRefundPolicy}
          element={<CancelRefundPolicy />}
        />
        <Route
          exact
          path={commonRoute.dataDeletionPolicy}
          element={<DataDeletetionPolicy />}
        />

        <Route exact path={commonRoute.sitemap} element={<Sitemap />} />
        <Route exact path={commonRoute.home} element={<Home />} />
        <Route exact path={commonRoute.contactUs} element={<ContactUs />} />

        <Route
          exact
          path={commonRoute.quizSolutions}
          element={<QuizSolutions />}
        />

        <Route
          exact
          path="/qa/:slug"
          element={<QuizSolutions type="askQuestion" />}
        />
        <Route
          exact
          path="/es/:slug"
          element={<QuizSolutions type="expertSolution" />}
        />
        <Route
          exact
          path="/fc/:slug"
          element={<QuizSolutions type="flashCard" />}
        />

        <Route exact path={commonRoute.myProfileType} element={<Profile />} />

        <Route
          exact
          path={commonRoute.flashCardType}
          element={<FlashCardDetails />}
        />

        <Route
          exact
          path={commonRoute.resetpassword}
          element={<ResetPassword />}
        />
        <Route
          exact
          path={commonRoute.tutorSupport}
          element={<ComingSoon pageTitle="Tutor Support" />}
        />
        <Route
          exact
          path={commonRoute.career}
          element={<ComingSoon pageTitle="Career" />}
        />
        <Route
          exact
          path={commonRoute.book}
          element={<ComingSoon pageTitle="Book" />}
        />
        <Route
          exact
          path={commonRoute.payment}
          element={
            <Elements stripe={stripePromise}>
              <Payment />
            </Elements>
          }
        />
        <Route
          exact
          path={commonRoute.pageNotFound}
          element={<PageNotFound />}
        />
        <Route
          path={commonRoute.myProfile}
          element={
            <Navigate
              to={`${commonRoute.myProfile}/account-overview`}
              replace
            />
          }
        />
        <Route path="/" element={<Navigate to={commonRoute.home} replace />} />
        <Route
          path="*"
          element={<Navigate to={commonRoute.pageNotFound} replace />}
        />
      </Routes>
    </>
  );
};
export default AuthRoute;
