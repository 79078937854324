import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/form/TextInput";
import PasswordInput from "components/form/PasswordInput";
import LabelText from "components/form/LabelText";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { login, setIsLoginSignup } from "store/globalSlice";
import { useNavigate } from "react-router-dom/dist";
import { commonRoute } from "utils/constants";

const LoginPage = ({ setPageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(login(values));
    if (response?.status === 200) {
      dispatch(setIsLoginSignup(false));
      navigate(commonRoute.myProfile);
    }
    setBtnLoading(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    password: Yup.string().required("Password is required."),
  });
  const initialValues = {
    email: "",
    password: "",
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSave}
    >
      {(props) => {
        const { values, errors, handleChange, handleSubmit } = props;
        return (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          >
            <div className="row">
              <div className="cmb-25">
                <TextInput
                  label="EMAIL"
                  placeholder="EMAIL ID"
                  id="email"
                  error={errors?.email}
                  value={values?.email}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: e.target.value.toLowerCase(),
                      },
                    });
                  }}
                />
              </div>

              <div className="cmb-10">
                <PasswordInput
                  label="PASSWORD"
                  placeholder="PASSWORD"
                  id="password"
                  error={errors?.password}
                  onChange={handleChange}
                  value={values?.password}
                />
              </div>
              <div className="cmb-44 text-end">
                <span
                  className="text-14-600 color-dodger-blue pointer"
                  onClick={() => {
                    setPageType(2);
                  }}
                >
                  Forgot Password?
                </span>
              </div>
              <div className="cmb-24">
                <LabelText
                  label={
                    <div className="text-13-400 color-outer-space">
                      <span>
                        {"By clicking Log in, you accept Help a Course "}
                      </span>
                      <span
                        className="color-primary pointer text-14-400"
                        onClick={() => {
                          window.open("/terms-of-services");
                        }}
                      >
                        {"Terms of Services"}
                      </span>
                      <span>{" and "}</span>
                      <span
                        className="color-primary pointer text-14-400"
                        onClick={() => {
                          window.open("/privacy-policy");
                        }}
                      >
                        Privacy Policy
                      </span>
                    </div>
                  }
                />
              </div>

              <div className="cmb-25">
                <Button
                  hideAnimation
                  btnStyle="btn-primary-yellow"
                  value="Log In"
                  btnLoading={btnLoading}
                  onClick={(e) => {
                    if (!btnLoading) {
                      handleSubmit(e);
                    }
                  }}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
export default LoginPage;
