import Button from "components/form/Button";
import Layout from "pages/Layouts/Layouts";
import React from "react";
import { useParams } from "react-router-dom";
import { icons } from "utils/constants";
import { titleCaseString } from "utils/helpers";
import "./ComingSoon.scss";
import CommonHelmet from "components/layouts/CommonHelmet";

const ComingSoon = ({ pageTitle }) => {
  const params = useParams();
  const { type } = params;
  const displayTitle = titleCaseString(type?.replace(/-/g, " ")) || "Books";
  return (
    <Layout isLogin>
      <CommonHelmet pageTitle={pageTitle || ""} />
      <div id="coming-soon-container">
        <div className="row cmt-30 cmb-135">
          <div className="heading-title text-38-600 color-outer-space cmb-20 cpt-40">
            {displayTitle}
          </div>

          <div className="img-div">
            <img
              src={icons.fullWidthStudent}
              alt="student-photos"
              className="fill-image"
            />
            <div className="image-over-text-block">
              <div className="img-heading text-50-700 color-white">
                Success in every step
              </div>
              <div className="img-description text-20-500 color-white">
                Stay on top of it with homework help, exam prep & writing
                support—tailored to your courses.
              </div>
              <div className="button-container d-flex">
                <Button
                  value="Coming Soon"
                  className="btn-primary-yellow cpt-15 cpb-15 cps-50 cpe-50 bdr button-animation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
