import "./StickyContactButton.scss";
import { useLocation, useNavigate } from "react-router-dom/dist";

const StickyContactButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/contact-us" && (
        <div id="contact-button-container">
          <button
            type="button"
            className="contact-button text-20-500 text-white  btn btn-primary"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            <i className="bi bi-chat-left-quote-fill me-2"></i>
            Feedback
          </button>
        </div>
      )}
    </>
  );
};

export default StickyContactButton;
