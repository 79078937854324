import React from "react";
import { useDispatch } from "react-redux";
import { icons } from "utils/constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GoogleButton from "components/layouts/GoogleButton";
import FacebookButton from "components/layouts/FacebookButton";
import { setIsLoginSignup, setIsSubscription } from "store/globalSlice";
import { getDataFromLocalStorage } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import "./SocialMediaSection.scss";

const SocialMediaSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, Orders } = getDataFromLocalStorage() || {};
  const plan = Orders?.[0] || {};
  const { status, subscriptionPlan_id: planID, id: orderID } = plan;
  const isPendingSub = !planID || status === 2;
  const isPendingPay = status === 0;
  console.log("token", token);
  return (
    <div
      id="social-media-conatiner"
      className="bg-primary bdr-radius slideInRight"
    >
      <div className="row social-media-row-block cps-56 cpe-56 cpt-55 cpb-55">
        <div className="col-md-6">
          <div className="social-media-heading-block text-50-700 color-white cmb-23">
            {!token
              ? "Signup"
              : isPendingSub
              ? "Subscribe"
              : isPendingPay
              ? "Complete subscription"
              : "Subscribe"}{" "}
            to see the answer
          </div>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <div className="text-14-500 color-black">
                  You have reached your limit for now!
                </div>
              </Tooltip>
            }
          >
            <div className="tooltip-text-block text-18-600 color-white d-flex">
              Why am I seeing this?
              <img className="ms-2" src={icons.iconI} alt="Iicon" />
            </div>
          </OverlayTrigger>
        </div>
        {!token ? (
          <div className="col-md-6">
            <div className="cmb-15">
              <GoogleButton className="border-0" />
            </div>
            <div className="cmb-15">
              <FacebookButton className="border-0" />
            </div>
            <div
              className="cmb-10"
              onClick={() => {
                dispatch(setIsLoginSignup(true));
              }}
            >
              <div className="s-button border-0">
                <span>
                  <img
                    className="img-icon me-2"
                    src={icons.iconEmailWhite}
                    alt="iconEmailWhite"
                  />
                </span>
                <span className="pt-1">Continue with Email</span>
              </div>
            </div>
            <div className="text-center cmb-25 color-white text-11-400">
              By creating an account, you accept Help a Course{" "}
              <u className="text-11-500 pointer">Terms of Service</u> and{" "}
              <u className="text-11-500 pointer">Privacy Policy</u>
            </div>

            <div className="text-center">
              <p className="text-16-400 color-white">
                Already have an account?{" "}
                <span
                  className="text-16-400 color-white pointer"
                  onClick={() => {
                    dispatch(setIsLoginSignup(true));
                  }}
                >
                  <u>Log in</u>
                </span>
              </p>
            </div>
          </div>
        ) : isPendingSub ? (
          <div className="col-md-6 d-flex align-items-end">
            <div
              className="cmb-10 w-100"
              onClick={() => {
                dispatch(setIsSubscription(true));
              }}
            >
              <div className="s-button border-0">
                {/* <span>
              <img
                className="img-icon me-2"
                src={icons.iconEmailWhite}
                alt="iconEmailWhite"
              />
            </span> */}
                <span className="pt-1">Subscribe Now</span>
              </div>
            </div>
          </div>
        ) : isPendingPay ? (
          <div className="col-md-6 d-flex align-items-end">
            <div
              className="cmb-10 w-100"
              onClick={() => {
                navigate(`/subscription-payment/${orderID}/${planID}`);
              }}
            >
              <div className="s-button border-0">
                {/* <span>
              <img
                className="img-icon me-2"
                src={icons.iconEmailWhite}
                alt="iconEmailWhite"
              />
            </span> */}
                <span className="pt-1">Pay Subscription Now</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6 d-flex align-items-end">
            <div
              className="cmb-10 w-100"
              onClick={() => {
                dispatch(setIsSubscription(true));
              }}
            >
              <div className="s-button border-0">
                {/* <span>
              <img
                className="img-icon me-2"
                src={icons.iconEmailWhite}
                alt="iconEmailWhite"
              />
            </span> */}
                <span className="pt-1">Subscribe Now</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialMediaSection;
