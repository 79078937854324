import Button from "components/form/Button";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoginSignup } from "store/globalSlice";
import { icons } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";
// import Zoom from "react-reveal/Zoom";
import "./FlagImage.scss";
import { useNavigate } from "react-router-dom";

const FlagImage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [activeType, setActiveType] = useState(0);
  const first = useRef();
  const second = useRef();
  const third = useRef();

  const handelScroll = () => {
    let sPosition = window.scrollY;
    let sStart = second?.current?.offsetTop;
    let tStart = third?.current?.offsetTop;

    if (sPosition < sStart) {
      setActiveType(0);
    }
    if (sPosition >= sStart - 300 && sPosition < tStart) {
      setActiveType(1);
    }
    if (sPosition >= tStart - 300) {
      setActiveType(2);
    }
  };

  const handelClick = (type) => {
    if (type === 0) {
      first?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === 1) {
      second?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (type === 2) {
      third?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      // Nothing
    }
    setActiveType(type);
  };

  useEffect(() => {
    window.addEventListener("scroll", handelScroll, true);
    return () => window.removeEventListener("scroll", handelScroll);
  });

  const isLogin = getDataFromLocalStorage("token") ? false : true;
  return (
    <div id="flag-image-container" className="row">
      <div className="col-md-6">
        <div className="section-contain">
          <div className="section-1-text">
            <div className="d-flex flex-column">
              <div
                className={`questions-text ${
                  activeType === 0 ? "active" : "pointer"
                }`}
                onClick={() => {
                  handelClick(0);
                }}
              >
                Ask Questions
              </div>
              <div
                className={`questions-text ${
                  activeType === 1 ? "active" : "pointer"
                }`}
                onClick={() => {
                  handelClick(1);
                }}
              >
                Get Help
              </div>
              <div
                className={`questions-text ${
                  activeType === 2 ? "active" : "pointer"
                }`}
                onClick={() => {
                  handelClick(2);
                }}
              >
                Go Beyond
              </div>
            </div>

            {activeType === 2 ? (
              <p className="color-light-gray mt-5 w-75 bio-text">
                The HelpACourse community is constantly buzzing with the
                excitement of endless collaboration, proving that learning is
                more fun — and more effective — when we put our heads together.
                Help the community by sharing what you know. Answering questions
                also helps you learn!
              </p>
            ) : activeType === 1 ? (
              <p className="color-light-gray mt-5 w-75 bio-text">
                Our community of experts consists of students, schoolteachers,
                PhDs, and other geniuses just waiting to tackle your toughest
                questions.
              </p>
            ) : (
              <p className="color-light-gray mt-5 w-75 bio-text">
                Whether you’re stuck on a history question or a blocked by a
                geometry puzzle, there’s no question too tricky for HelpACourse.
              </p>
            )}
          </div>
          <div className="d-flex align-items-center gap-5">
            {isLogin && (
              <Button
                btnStyle="btn-primary"
                value="Join for Free"
                className="h-auto pt-2 pb-2 cps-30 cpe-30"
                onClick={() => {
                  dispatch(setIsLoginSignup(true));
                }}
              />
            )}
            <div
              className="font-weight-bold button-animation arrow-btn-container pointer"
              onClick={() => history("/quiz-solutions")}
            >
              <b>Learn More</b>
              <i className="ms-2 bi bi-arrow-right" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 image-div">
        <img
          ref={first}
          id="list-item-1"
          className="img-fluid section-3-image"
          src={icons.Section1}
          alt=""
        />
        <img
          ref={second}
          id="list-item-2"
          className="img-fluid section-3-image"
          src={icons.Section3}
          alt=""
        />
        <img
          ref={third}
          id="list-item-3"
          className="img-fluid section-3-image"
          src={icons.Section2}
          alt=""
        />
      </div>
    </div>
  );
};
export default FlagImage;
